<h1>
  <span *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]">APRs</span>
  <span *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]">Minhas APRs</span>
</h1>

<div class="acoes">
  <mat-form-field style="width: 200px;">
    <input matInput [matDatepicker]="dp" [max]="atual" placeholder="Mês/Ano" [formControl]="date" min="2022-01-01" />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngIf="false">
    <input #in (input)="datasource.queryBy({ search: in.value })" matInput placeholder="Filtro" />
  </mat-form-field>

  <app-autocomplete-clientes *appHasRole="[1,2]" label="Cliente" [control]="cliente" [inline]="true">
  </app-autocomplete-clientes>

  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select #st (selectionChange)="datasource.queryBy({ status: st.value })">
      <mat-option>Todos</mat-option>
      <mat-option *ngFor="let status of opcoesStatus" [value]="status">{{
        status
        }}</mat-option>
    </mat-select>
  </mat-form-field>


  <span *appHasAcao="['GERAR_APR']">
    <button routerLink="novo" mat-raised-button color="accent">
      <mat-icon>add</mat-icon> Nova APR
    </button>
  </span>
</div>
<table mat-table [dataSource]="datasource">
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">ID:</span>
      <a [routerLink]="['editar', element.id]">{{ element.id }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="fundo">
    <th mat-header-cell *matHeaderCellDef style="width: 25%;">Fundo</th>
    <td mat-cell *matCellDef="let element" style="font-size: 0.8em; padding-right: 1em;">
      <span class="mobile-label">Fundo:</span>
      ({{ element.cnpjFundo }}) - {{ element.nomeFundo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="conta">
    <th mat-header-cell *matHeaderCellDef>Conta</th>
    <td mat-cell *matCellDef="let element" style="font-size: 10px;">
      <span class="mobile-label">Conta:</span>
      Banco: {{ element.banco || "N/I" }}<br />
      Agência: {{ element.agencia || "N/I" }}<br />
      Conta: {{ element.conta || "N/I" }}<br />
    </td>
  </ng-container>
  <ng-container matColumnDef="cliente">
    <th mat-header-cell *matHeaderCellDef style="width: 25%">Cliente</th>
    <td mat-cell *matCellDef="let element" style="font-size: 0.9em;">
      <span class="mobile-label">Cliente:</span>
      ({{ element.cliente.cnpj }}) - {{ element.cliente.nome }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Status:</span>
      {{ element.status }}
    </td>
  </ng-container>
  <ng-container matColumnDef="operacao">
    <th mat-header-cell *matHeaderCellDef>Operação</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Operação:</span>
      {{ element.operacao | movimentoCarteira }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valor">
    <th mat-header-cell *matHeaderCellDef>Valor</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Valor:</span>
      {{ element.valor | currency: "BRL" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef>Data</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Date:</span>
      {{ element.dataOperacao | date: "dd/MM/yyyy" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="geracao">
    <th mat-header-cell *matHeaderCellDef style="text-align: right;">Ações:</th>
    <td mat-cell *matCellDef="let element" style="text-align: right;">
      <span class="mobile-label">Ações:</span>
      <button mat-icon-button color="primary" matTooltip="Abrir o documento" color="primary"
        (click)="geracaoPDF(element.id)">
        <i class="fas fa-file-pdf"></i>
      </button>
      <span *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]">
        <button *ngIf="element.status === 'Pendente'" mat-flat-button color="primary"
          matTooltip="Gerar movimento a partir da APR" color="primary" (click)="lancarApr(element)">
          <i class="fas fa-exchange-alt"></i>Confirmar
        </button>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="descricao">
    <th mat-header-cell *matHeaderCellDef style="text-align: right;"></th>
    <td mat-cell *matCellDef="let element" style="text-align: right;">
      <span class="mobile-label"></span>
      <button mat-icon-button color="warn" matTooltip="Alterar a descrição da APR"
        (click)="alterarDescricaoAPR(element)">
        <i class="fas fa-comment"></i>
      </button>
    </td>
  </ng-container>

</table>
<div class="spinner-container" *ngIf="datasource.loading$ | async">
  <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
</div>
<mat-paginator *ngIf="datasource.page$ | async as page" [length]="page.totalElements" [pageSize]="page.size"
  [pageIndex]="page.number" [hidePageSize]="true" (page)="datasource.fetch($event.pageIndex)">
</mat-paginator>