import { AbstractControl, Validators, ValidatorFn  } from '@angular/forms';

export function ValidateCPFCNPJ(control: AbstractControl) {
    let valor = control.value;
    if (valor) {
        valor = valor.replace(/[^\d\s]/gi, '');
        if (valor.length === 11) {
            return ValidateCPF(control);
        } else if (valor.length === 14) {
            return ValidateCNPJ(control);
        } else {
            return {NotValid: true};
        }
    }
}

export function ValidateCPF(control: AbstractControl) {
    const cpf = control.value.replace(/[^\d\s]/gi, '');
    if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        for (i = 0; i < cpf.length - 1; i++) {
            if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
                equalDigits = 0;
                break;
            }
        }
        if (!equalDigits) {
            numbers = cpf.substring(0, 9);
            digits = cpf.substring(9);
            sum = 0;
            for (i = 10; i > 1; i--) {
                sum += numbers.charAt(10 - i) * i;
            }
            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (result !== +digits.charAt(0)) {
                return { NotValid: true };
            }
            numbers = cpf.substring(0, 10);
            sum = 0;
            for (i = 11; i > 1; i--) {
                sum += numbers.charAt(11 - i) * i;
            }
            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (result !== +digits.charAt(1)) {
                return { NotValid: true };
            }
            return null;
        } else {
            return { NotValid: true };
        }
    }
}

export function ValidateCNPJ(control: AbstractControl) {
    let cnpj = control.value;
    if (cnpj) {
        cnpj = cnpj.replace(/[^\d\s]/gi, '');
        if (cnpj.length !== 14) {
            return { NotValid: true };
        }
        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        const digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== +digitos.charAt(0)) {
            return { NotValid: true };
        }
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== +digitos.charAt(1)) {
            return { NotValid: true };
        }
        return null;
    }
}
