<h1>
  Resoluções
</h1>

<div class="acoes">
  <mat-form-field>
    <input #in (input)="datasource.queryBy({search: in.value})" matInput placeholder="Filtro">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Tipo</mat-label>
    <mat-select #tipo placeholder="Tipo" (selectionChange)="datasource.queryBy({ tipo: tipo.value })" >
      <mat-option>Selecione</mat-option>
      <mat-option value="RPPS">RPPS</mat-option>
      <mat-option value="FP">FP</mat-option>
      <mat-option value="PRO-GESTAO">PRÓ-GESTÃO</mat-option>
      <mat-option value="OUTROS">OUTROS</mat-option>
    </mat-select>
  </mat-form-field>

  <button routerLink="novo" mat-raised-button color="accent">
    <mat-icon>add</mat-icon> Nova Resolução
  </button>
</div>
<table mat-table [dataSource]="datasource" >
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>


  <ng-container matColumnDef="descricao">
    <th mat-header-cell *matHeaderCellDef style="width: 15%;"> Nome </th>
    <td mat-cell *matCellDef="let element" style="padding-right: 25px;">
      <span class="mobile-label">Nome:</span>
      {{element.nome}}
    </td>
  </ng-container>
  <ng-container matColumnDef="legislacao">
    <th mat-header-cell *matHeaderCellDef> Legislação </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Legislação:</span>
      {{element.legislacao}}
    </td>
  </ng-container>
  <ng-container matColumnDef="tipo">
    <th mat-header-cell *matHeaderCellDef style="width: 100px;"> Tipo </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Tipo:</span>
      {{element.tipo}}
    </td>
  </ng-container>
  <ng-container matColumnDef="limiteResolucao">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Limite </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Limite:</span>
      {{element.limite && (element.limite / 100) | percent:'1.2-4' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="limiteParticipacao">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Limite participação </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Limite participação:</span>
      <span *ngIf="element.tipo === 'RPPS'">
        {{element.limiteParticipacao && (element.limiteParticipacao / 100) | percent:'1.2-4' }}
      </span>
      <span *ngIf="element.tipo !== 'RPPS'">
        ---
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="limites">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Limites </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Limites:</span>
      <span *ngIf="element.tipo === 'FP'">
        Inf: {{element.limiteInferior && (element.limiteInferior / 100) | percent:'1.2-4' }}<br/>
        Sup: {{element.limiteSuperior && (element.limiteSuperior / 100) | percent:'1.2-4' }}
      </span>

      <span *ngIf="element.tipo !== 'FP'">
        ---
      </span>

    </td>
  </ng-container>
  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef> Ações </th>
    <td mat-cell *matCellDef="let element" style="text-align: right; width: 10%;">
      <span class="mobile-label">Ações:</span>
      <span style="display: flex; flex-flow: row;">
        <button [routerLink]="['editar', element.id]" mat-icon-button matTooltip="Editar" color="warn" >
          <i class="fas fa-edit"></i>
        </button>
        <button [routerLink]="['/', 'enquadramentos',element.id]" mat-icon-button color="primary" matTooltip="Visualizar enquadros" matTooltipPosition="before">
          <i class="fas fa-search-dollar"></i>
        </button>
      </span>
    </td>
  </ng-container>
</table>
<div class="spinner-container" *ngIf="datasource.loading$ | async">
  <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
</div>
<mat-paginator *ngIf="datasource.page$ | async as page"
  [length]="page.totalElements" [pageSize]="page.size"
  [pageIndex]="page.number" [hidePageSize]="true"
  (page)="datasource.fetch($event.pageIndex)">
</mat-paginator>
