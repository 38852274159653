<div *ngIf="fundo" style="display: flex; flex-direction: column;">
  <div *ngIf="!consolidado" style="display: flex; flex-direction: column;">
    <div>
      <strong>CNPJ:</strong> {{fundo.header.cnpj}}
    </div>
    <div>
      <strong>Nome:</strong> {{fundo.header.nome}}
    </div>
    <div>
      <strong>Patrimonio líquido:</strong> {{fundo.header.patrimonioLiquido | currency:'BRL'}}
    </div>
    <div>
      <strong>Valor limite:</strong> {{valorLimite | currency:'BRL'}}
    </div>
    <div>
      <strong>Data base:</strong> {{fundo.header.data | date:'dd/MM/yyyy'}}
    </div>
  </div>
  <br/>

  <div *ngIf="fundo" style="display: grid; grid-template-columns: 50% 50%; gap: 15px">
    <div>
      <table border="1" *ngIf="fundo.acoes.length">
        <thead>
          <tr>
            <th style="width: 50%; text-align: left;">Nome</th>
            <th style="width: 25%; text-align: left;">Valor</th>
            <th style="width: 25%; text-align: right;">% do ativo</th>
          </tr>
        </thead>
        </table>

      <table border="1" *ngIf="fundo.acoes.length > 0">
        <tbody>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">AÇÕES</td>
          </tr>
          <tr *ngFor="let item of this.fundo.acoes.filter(filtroClasseOperacaoC).filter(filtroDisponivel)">
            <td style="width: 50%;">{{item.codAtivo | Asset:'Nome do Ativo'}} - {{item.codAtivo}}</td>
            <td style="width: 25%;">{{item.valorFinDisp | currency:'BRL'}}</td>
            <td style="text-align: right; width: 25%;">{{item.valorFinDisp / this.fundo.header.patrimonioLiquido | percent:'1.4-4'}}</td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">AÇÕES (em garantia)</td>
          </tr>
          <tr *ngFor="let item of fundo.acoes.filter(filtroClasseOperacaoC).filter(filtroGarantia)">
            <td >{{item.codAtivo | Asset:'Nome do Ativo'}} - {{item.codAtivo}}</td>
            <td >{{item.valorFinDisp | currency:'BRL'}}</td>
            <td style="text-align: right;">{{item.valorFinDisp / this.fundo.header.patrimonioLiquido | percent:'1.4-4'}}</td>
          </tr>
          <tr>
            <td><strong>Total Ações</strong></td>
            <td>{{totalAcoes | currency:'BRL'}}</td>
            <td style="text-align: right;">{{totalAcoes / fundo.header.patrimonioLiquido | percent: '1.4-4'}}</td>
          </tr>
        </tbody>
      </table>



      <table *ngIf="fundo?.tituloPublico?.length > 0" border="1">
        <tbody>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">RENDA FIXA</td>
          </tr>
          <tr *ngFor="let item of fundo.tituloPublicoAgrupado.filter(filtroDisponivel)">
            <td style="width: 50%;">{{item.isin | ISIN:'descricao'}} Venc: {{item.dtVencimentoDate | date:'dd/MM/yyyy'}}</td>
            <td style="width: 25%;">{{item.valorFinDisp | currency:'BRL'}}</td>
            <td style="text-align: right;">{{item.valorFinDisp / this.totalPL | percent:'1.4-4'}}</td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">RENDA FIXA (em garantia)</td>
          </tr>
          <tr *ngFor="let item of fundo.tituloPublicoAgrupado.filter(filtroGarantia)">
            <td>{{item.isin | ISIN:'descricao'}} Venc: {{item.dtVencimentoDate | date:'dd/MM/yyyy'}}</td>
            <td>{{item.valorFinemGar | currency:'BRL'}}</td>
            <td style="text-align: right;">{{item.valorFinemGar / this.totalPL | percent:'1.4-4'}}</td>
          </tr>
          <tr>
            <td><strong>Total Renda Fixa</strong></td>
            <td>{{totalRendaFixa | currency:'BRL'}}</td>
            <td style="text-align: right;">{{totalRendaFixa / fundo.header.patrimonioLiquido | percent: '1.4-4'}}</td>
          </tr>
        </tbody>
      </table>


      <table border="1">
        <tbody>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">Disponibilidades</td>
          </tr>
          <tr>
            <td style="width: 50%;">Outras disponibilidades</td>
            <td style="width: 25%;">{{this.fundo.caixa.saldo | currency: 'BRL'}}</td>
            <td style="text-align: right;">{{this.fundo.caixa.saldo / this.fundo.header.patrimonioLiquido | percent:'1.2-2'}} </td>
          </tr>
          <tr>
            <td><strong>Total disponibilidades</strong></td>
            <td><strong>{{this.fundo.caixa.saldo | currency: 'BRL'}}</strong></td>
            <td style="text-align: right;">{{this.fundo.caixa.saldo / this.fundo.header.patrimonioLiquido | percent:'1.2-2'}} </td>
          </tr>
        </tbody>
      </table>

      <table border="1">
        <tbody>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">Provisões</td>
          </tr>
          <tr *ngFor="let item of resumoProvisoes">
            <td style="width: 50%;">{{item.tipo}}</td>
            <td  style="width: 25%;">{{item.valor | currency:'BRL'}}</td>
            <td style="width: 25%;text-align: right;">{{(item.valor * item.sinal) / fundo.header.patrimonioLiquido | percent:'1.4-4'}}</td>
          </tr>
          <tr>
            <td><strong>Total Provisões</strong></td>
            <td><strong>{{(resumoProvisoes[0].valor * resumoProvisoes[0].sinal) + (resumoProvisoes[1].valor * resumoProvisoes[1].sinal) | currency: 'BRL'}}</strong></td>
            <td style="text-align: right;">{{((resumoProvisoes[0].valor * resumoProvisoes[0].sinal) + (resumoProvisoes[1].valor * resumoProvisoes[1].sinal)) / this.fundo.header.patrimonioLiquido | percent:'1.4-4'}} </td>
          </tr>
        </tbody>
      </table>

      <table border="1">
        <tbody>
          <tr>
            <td style="width: 50%;"></td>
            <td style="width: 25%;"></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>Total Patrimonio Líquido</strong></td>
            <td><strong>{{this.fundo.header.patrimonioLiquido | currency: 'BRL'}}</strong></td>
            <td style="text-align: right;">{{ this.fundo.header.patrimonioLiquido / this.fundo.header.patrimonioLiquido | percent:'1.4-4'}} </td>
          </tr>
        </tbody>
      </table>

      <br/>
      <table *ngIf="fundo?.tituloPrivado?.length > 0" border="1">
        <tbody>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">Título Privado</td>
          </tr>
          <tr *ngFor="let item of fundo.tituloPrivadoAgrupado">
            <td style="width: 50%;">{{item.isin | ISIN:'descricao'}} Venc: {{item.dtvencimentoDate | date:'dd/MM/yyyy'}}</td>
            <td style="width: 25%;">{{item.valorfindisp | currency:'BRL'}}</td>
            <td style="text-align: right;">{{item.valorfindisp / this.totalPL | percent:'1.4-4'}}</td>
          </tr>
        </tbody>
      </table>
      <br/>
      <table border="1" *ngIf="fundo.cotas.length > 0">
        <thead>
          <tr>
            <th style="width: 50%;">CNPJ Fundo</th>
            <th style="width: 25%;">Quantidade</th>
            <th style="text-align: right; width: 25%;">Valor da Cota</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">COTAS</td>
          </tr>

          <tr *ngFor="let item of this.fundo.cotas">
            <td style="width: 50%;">{{item.cnpjFundo | cpfcnpj}}</td>
            <td style="width: 25%;">{{item.qtdisponivel | number: "1.4-10" }}</td>
            <td style="text-align: right; width: 25%;">{{item.puposicao|currency:'BRL'}}</td>
          </tr>
        </tbody>
      </table>

      <br/>
      <table *ngIf="fundo?.debenture?.length > 0" border="1">
        <tbody>
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold;">Debentures</td>
          </tr>
          <tr *ngFor="let item of fundo.debentureAgrupado">
            <td style="width: 50%;">{{item.isin | ISIN:'descricao'}} Venc: {{item.dtvencimentoDate | date:'dd/MM/yyyy'}}</td>
            <td style="width: 25%;">{{item.valorfindisp | currency:'BRL'}}</td>
            <td style="text-align: right;">{{item.valorfindisp / this.totalPL | percent:'1.4-4'}}</td>
          </tr>
        </tbody>
      </table>

      <br/>
    </div>


    <div>
      <div [hidden]="chartAcoes.length === 0" style="width: 100%; height: 400;">
        <ngx-charts-bar-horizontal
        [results]="chartAcoes"
        [xAxis]="true"
        [animations]="false"
        [yAxis]="true"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        xAxisLabel="Valor"
        yAxisLabel="Ativo"
        >
        </ngx-charts-bar-horizontal>
      </div>
      <div [hidden]="chartDistribuicao.length === 0" style="width: 100%; height: 400;">
        <ngx-charts-pie-chart
          [results]="chartDistribuicao"
          [animations]="false"
          [labels]="true"
          [legend]="true"
          >
        </ngx-charts-pie-chart>
      </div>
    </div>
  </div>
