import { Pipe, PipeTransform } from '@angular/core';

const grauRisco = {
  B: 'Baixo',
  M: 'Médio',
  A: 'Alto'
};

@Pipe({
  name: 'grauRisco'
})
export class GrauRisco implements PipeTransform {

  transform(items: string, attr: string): any {
    return grauRisco[items];
  }

}
