<h1 class="title">Comentários</h1>

<button *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]" mat-flat-button color="primary" (click)="abrirDialogNovo()">
    <i class="fas fa-plus-circle"></i> Novo Comentário
</button>
<br/>
<br/>
<table mat-table [dataSource]="datasource" >
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
    <ng-container matColumnDef="titulo">
      <th mat-header-cell *matHeaderCellDef style="width: 40%;"> Título </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Título:</span>
        {{element.titulo}}
      </td>
    </ng-container>
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef> Tipo </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Tipo</span>
        {{element.tipo}}
        </td>
    </ng-container>
    <ng-container matColumnDef="cliente">
      <th mat-header-cell *matHeaderCellDef> Cliente </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Cliente</span>
        {{element.cliente?.nome || 'Todos'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="arquivo">
      <th mat-header-cell *matHeaderCellDef> Abrir documento </th>
        <td mat-cell *matCellDef="let element" style="width: 300px;">
            <button mat-raised-button color="primary" style="width: 100%;" (click)="abrirDocumento(element.id)">
                <i class="fas fa-external-link-alt"></i>
                Abrir arquivo
            </button>
        </td>
    </ng-container>
    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Ações </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Ações:</span>
        <button mat-icon-button matTooltip="Apagar documento" (click)="apagarDocumento(element.id)" color="warn">
            <i class="fas fa-trash"></i>
        </button>
      </td>
    </ng-container>
  </table>
  <div class="spinner-container" *ngIf="datasource.loading$ | async">
    <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
  </div>
  <mat-paginator *ngIf="datasource.page$ | async as page"
    [length]="page.totalElements" [pageSize]="page.size"
    [pageIndex]="page.number" [hidePageSize]="true" 
    (page)="datasource.fetch($event.pageIndex)">
  </mat-paginator>
