import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DialogDeleteComponent } from 'src/app/_helpers/dialog-delete/dialog-delete.component';
import { UIService } from 'src/app/_helpers/ui.service';
import { Cliente } from 'src/app/_models/cliente.model';
import { ClienteQuery, ClienteService } from 'src/app/_services/cliente.service';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { environment } from 'src/environments/environment';
import { DialogImportacaoExtrato } from './dialog-importacao-extrato/dialog-importacao-extrato.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent {

  columns = ['nome', 'email', 'ativo', 'carteira', 'acoes'];
  datasource = new PaginatedDataSource<Cliente, ClienteQuery>(
    (request, query) => this.clienteService.listarClientePaginado(request, query),
    {property: 'idCliente', order: 'asc'},
    {search: ''},
    environment.LIMITE_REGISTROS_LISTA
  )

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  search: string = ''

  constructor(
    private clienteService: ClienteService,
    private dialog: MatDialog,
    private uiService: UIService) { }

  apagarCliente(cliente: Cliente) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '350px',
      data: { name: cliente.nome }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clienteService.apagarCliente(cliente.idCliente).subscribe(v => {
          this.uiService.showSnackBar('Cliente desativado com sucesso!', 'Ok', 4000);
        }, error => {
            this.uiService.showSnackBar('Erro ao tentar desativar o cliente selecionado!', 'Ok', 4000);
        });
      }
    });
  }

  abrirDialogImportacao(cliente: Cliente) {
    const dialogRef = this.dialog.open(DialogImportacaoExtrato, {
      width: '350px',
      data: { idCliente: cliente.idCliente }
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

}
