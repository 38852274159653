import { environment } from './../../environments/environment';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIService } from './../_helpers/ui.service';
import { of, BehaviorSubject, Observable, throwError, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as UsuarioActions from './../actions/usuario.action';
import * as OtimizadorActions from './../actions/otimizador.action';
import * as moment from 'moment';
import { Usuario, Authentication, Token } from '../_models/user.models';

@Injectable()
export class AuthService implements OnDestroy {

  public willPrint$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private jwtHelper = new JwtHelperService();
  private authenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public authenticated$: Observable<boolean> = this.authenticated.asObservable();

  private usuario: Usuario;
  public _usuario = new BehaviorSubject<Usuario>(null);
  public usuario$: Observable<Usuario> = this._usuario.asObservable();
  public proxyUserId: number;

  constructor(
    private router: Router,
    private uiService: UIService,
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.usuario$.subscribe(usuario => this.usuario = usuario);
    store.select('usuario').subscribe(usuario => this._usuario.next(usuario));
  }

  register(usuario: Usuario) {
    const url = `${environment.PATH_API}/user/register`;
    return this.httpClient.post(url, usuario);
  }

  login(usuario: Usuario) {
    this.uiService.loadingChangeState.next(true);
    const url = `${environment.PATH_API}/user/login`;
    const objLogin: Usuario = {
      eMail: usuario.eMail,
      pwd: usuario.pwd
    };
    return this.httpClient.post(url, objLogin).pipe(
        map((value: Authentication) => {
          this.authenticated.next(true);
          const dadosToken = <Token>this.jwtHelper.decodeToken(value.accessToken);


          this.usuario = <Usuario>{
            token: value.accessToken,
            expiration: moment(value.expiration, "YYYY-MM-DD HH:mm:ss").toDate(),
            termosUso: dadosToken.termoUso === '1',
            nome: dadosToken.nome,
            logo: usuario.logo,
            tipoUser: +dadosToken.tipoUser,
            idUser: +dadosToken.idUser,
            ativo: dadosToken.ativo,
            idCliente: dadosToken.idCliente,
            acoes: dadosToken.acoes,
          };
          this._usuario.next(this.usuario);
          this.store.dispatch(new UsuarioActions.Login(this.usuario));
          this.store.dispatch(new OtimizadorActions.LimparOtimizador());
          return true;
        }),
        catchError(_ => {
          console.log(_);
          this.authenticated.next(false);
          return of(false); // retornado com of para transformar em observable, não pode dar throwError pq senão mata o subscriber.
        }),
        finalize(() => {
          this.uiService.loadingChangeState.next(false);
        })
      );
  }

  termos(aceite: boolean){
    return this.httpClient.post(`${environment.PATH_API}/user/termo`, {
      iduser: this.usuario.idUser,
      resp: aceite
    });
  }

  validarTokenRecuperacao(token: string){
    let params = new HttpParams();
    params = params.append('token', token);
    return this.httpClient.get(`${environment.PATH_API}/user/forgot`, {params: params});
  }

  loginAsProxy(usuario: Usuario) {
    this.usuario = { ...this.usuario, ...usuario};
    this.authenticated.next(false);
    this.proxyUserId = usuario.idUser;
    setTimeout(() => {
      this._usuario.next(this.usuario);
      this.authenticated.next(true);
    }, 1000)
    
  }

  recuperacaoSenha(obj){
    return this.httpClient.post(`${environment.PATH_API}/user/forgot`, obj);
  }

  recuperarSenha(obj){
    return this.httpClient.put(`${environment.PATH_API}/user/forgot`, obj);
  }

  logout(reason?: string) {
    this.usuario = null;
    this.store.dispatch(new UsuarioActions.Logout());
    this.authenticated.next(false);
    this.router.navigate(['login']);
    if (reason) {
      this.uiService.showSnackBar(reason, null, 4000);
    }
  }

  alterarSenha(obj) {
    const url = `${environment.PATH_API}/user/alterar-senha`;
    return this.httpClient.patch(url, obj);
  }

  getToken() {
    return this.usuario ? this.usuario.token : null;
  }

  getUsuarioLogado(): Usuario {
    return this.usuario;
  }

  isAuthenticated() {
    if (this.getToken() !== null && !this.isTokenExpired()) {
        this.authenticated.next(true);
        return true;
    }
    this.authenticated.next(false);
    return false;
  }


  private isTokenExpired() {
    if (this.getToken()) {
      return this.jwtHelper.isTokenExpired(this.getToken());
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.authenticated.complete();
    this._usuario.unsubscribe();
    this.willPrint$.complete();
  }

  
  openWindowWithAuth(url) {
    const form = document.createElement("form");
    form.target = "_blank";
    form.method = "POST";
    form.action = url;
    form.style.display = "none";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = 'token';
    input.value = this.getToken();
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);  
  }
}

export enum TipoUsuario {
  ADMIN = 1,
  USUARIO = 2,
  ADMIN_CLIENTE = 3,
  USUARIO_CLIENTE = 4,
}

interface OptionTipoUsuario {
  label: string;
  value: TipoUsuario;
}


export const TiposUsuario: OptionTipoUsuario[] = [
  { label: 'Administrador', value: TipoUsuario.ADMIN },
  { label: 'Usuário', value: TipoUsuario.USUARIO },
  { label: 'Administrador', value: TipoUsuario.ADMIN_CLIENTE },
  { label: 'Usuário', value: TipoUsuario.USUARIO_CLIENTE },
];
