import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ComentarioService } from "src/app/_services/comentario.service";
import { VinculoClientes } from "./vinculo.types";
import { MatTableDataSource } from "@angular/material/table";
import { ClienteService } from "src/app/_services/cliente.service";

@Component({
  selector: "app-vinculo-analise-relatorio",
  templateUrl: "./vinculo-analise-relatorio.component.html",
  styleUrls: ["./vinculo-analise-relatorio.component.scss"],
})
export class VinculoAnaliseRelatorioComponent implements OnInit {
  idAnalise: string;
  loading: boolean = false;

  displayedColumns: string[] = ["nomeCliente", "acoes"];

  datasource = new MatTableDataSource<VinculoClientes>();

  valorSelecionado: any;
  filtroOpcoes: string = "";
  opcoes: any[] = [
    // Adicione suas opções aqui
  ];

  selecionarOpcao(event: any) {
    this.valorSelecionado = event.value;
  }

  filtrarOpcoes() {
    return this.opcoes.filter((opcao) =>
      opcao.nome.toLowerCase().includes(this.filtroOpcoes.toLowerCase())
    );
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private comentarioService: ComentarioService,
    private ClienteService: ClienteService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.idAnalise = params.get("idAnalise");
    });
    this.getListaClientesAnalise();
    this.getClientes();
  }

  voltar() {
    this.router.navigate(["analise-relatorio"]);
  }

  getClientes() {
    this.loading = true;

    this.ClienteService.listarCliente("", 0, 150).subscribe((value: any) => {
      this.opcoes = value.map((item) => ({
        nome: item.nome,
        valor: item.idCliente,
      }));
      this.loading = false;
    });
  }

  getListaClientesAnalise() {
    this.loading = true;
    this.comentarioService
      .buscarListaClientesDaAnalise(this.idAnalise)
      .subscribe((value: any) => {
        this.datasource.data = value;
        this.loading = false;
      });
  }

  excluirCliente(idComentarioBonitao: number, idCliente: number) {
    const del = confirm(
      "O cliente selecionado será excluído da listagem. Confirme a operação"
    );
    if (del) {
      this.comentarioService
        .deleteVinculo(idComentarioBonitao, idCliente)
        .subscribe((value: any) => {
          this.getListaClientesAnalise();
        });
    }
  }

  vincularCliente() {
    this.loading = true;
    const obj = {
      idCliente: this.valorSelecionado,
      idComentarioBonitao: Number(this.idAnalise),
    };
    this.comentarioService.vincularClientes(obj).subscribe((value: any) => {
      this.filtroOpcoes = "";
      this.valorSelecionado = "";
      this.getListaClientesAnalise();
    });
  }
}
