<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    *ngIf="show"
    #drawer
    class="sidenav"
    [(mode)]="mode"
    [(opened)]="opened"
    [class.over]="mode === 'over'"
    fixedInViewport="true"
  >
    <!-- logo -->
    <mat-toolbar class="logo">
      <img
        *ngIf="!usuario.logo"
        style="object-fit: contain"
        src="../../../assets/imagens/logo.png"
      />
      <img
        *ngIf="usuario.logo"
        style="object-fit: contain"
        [src]="'data:image/png;base64,' + usuario.logo"
      />
    </mat-toolbar>

    <!-- menu -->
    <mat-nav-list>
      <a mat-list-item routerLink=""><i class="fas fa-home"></i>Início</a>
      <a
        mat-list-item
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
        routerLink="benchmark"
        ><mat-icon aria-label="timeline">timeline</mat-icon>Benchmark</a
      >
      <a
        mat-list-item
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
        routerLink="metas"
        ><i class="fab fa-cloudscale"></i>Metas</a
      >
      <a mat-list-item routerLink="indices"
        ><i class="fas fa-chart-bar"></i>Índices econômicos</a
      >

      <a mat-list-item routerLink="documentos"
        ><i class="fas fa-file-excel"></i>Documentos</a
      >

      <a mat-list-item routerLink="comentarios"
        ><i class="fas fa-comment-dollar"></i>Comentários</a
      >

      <a mat-list-item routerLink="novos-comentarios"
        ><i class="fas fa-comment-dollar"></i>Comentários Novo Relatório</a
      >

      <a mat-list-item routerLink="analise-relatorio">
        <i class="fas fa-pen"></i> Análise de Relatórios
      </a>

      <a
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
        mat-list-item
        routerLink="clientes"
        ><i class="fas fa-user-tie"></i>Clientes</a
      >

      <a *appHasRole="[tipoUsuario.USUARIO]" mat-list-item routerLink="drei"
        ><i class="fas fa-calculator"></i>DREI</a
      >

      <a mat-list-item routerLink="apr"
        ><i class="fas fa-file-signature"></i>APR</a
      >
      <a
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
        mat-list-item
        routerLink="resolucao"
        ><i class="fas fa-percentage"></i>Enquadramentos</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        href="https://www.liasys.net/files/Extrato Rendimento APR.pdf"
        target="_blank"
      >
        <i class="fas fa-tasks"></i>Rendimento Realizado
      </a>
      <a
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        routerLink="usuarios"
        ><i class="far fa-id-badge"></i>Usuários</a
      >
      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]"
        mat-list-item
        routerLink="indices-carteira"
        ><i class="fas fa-chart-line"></i>Índices da carteira</a
      >
      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]"
        mat-list-item
        routerLink="atribuicao-desempenho"
        ><i class="fas fa-tachometer-alt"></i>Desempenho</a
      >
      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]"
        mat-list-item
        routerLink="simulador-enquadramento"
        ><i class="fas fa-chalkboard"></i>Simulador Enquadramentos</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        routerLink="drei"
        ><i class="fas fa-file-code"></i>DREI</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        routerLink="ciclos-vida"
        ><i class="fas fa-table"></i>Ciclos de vida</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        routerLink="credenciamento"
        ><i class="fas fa-book"></i>Credenciamento</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]"
        mat-list-item
        routerLink="assinadores"
        ><i class="fas fa-signature"></i>Assinadores</a
      >
      <a mat-list-item routerLink="fundo-investimento"
        ><i class="fas fa-tasks"></i>Fundo de Investimento</a
      >

      <a
        *appHasRole="[
          tipoUsuario.ADMIN,
          tipoUsuario.USUARIO,
          tipoUsuario.ADMIN_CLIENTE
        ]"
        mat-list-item
        routerLink="conjuntos"
        ><i class="fab fa-stack-overflow"></i>Conjuntos</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        routerLink="compliance-anbima"
        ><i class="fas fa-money-check-alt"></i>Compliance Anbima</a
      >

      <!-- <a
      *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        target="_blank"
        href="https://www.liasys.com.br/sisweb/audesp.php"
        ><i class="fas fa-database"></i>Audesp</a
      > -->
      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        [routerLink]="['audesp-sp', usuario.idCliente]"
        ><i class="fas fa-database"></i>Audesp</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE]"
        mat-list-item
        target="_blank"
        href="https://www.liasys.com.br/sisweb/comparacao-fundos.php"
      >
        <i class="fas fa-copy"></i>Comparação de fundos
      </a>

      <a
        *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]"
        mat-list-item
        [routerLink]="['clientes', 'carteira', usuario.idCliente, 'novo-risco']"
        ><i class="fas fa-strikethrough"></i>Riscos</a
      >

      <a mat-list-item routerLink="otimizador"
        ><i class="fas fa-chart-line"></i>Otimizador</a
      >

      <a
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
        mat-list-item
        routerLink="blacklist/emissor"
        style="color: darkgray"
      >
        <i class="fas fa-list-ul"></i>LN de Emissor Não Financeiro
      </a>

      <a
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
        mat-list-item
        routerLink="blacklist/alto-risco"
        style="color: darkgray"
      >
        <i class="fas fa-list-ul"></i>LN de Alto Risco
      </a>

      <a mat-list-item routerLink="perfil"
        ><i class="fas fa-key"></i>Alterar senha</a
      >
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content fullscreen>
    <!-- header -->
    <mat-toolbar *ngIf="show" color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="menu">menu</mat-icon>
      </button>

      <h1>LiaSys</h1>

      <span class="fill-space"></span>

      <div id="nav-content">
        <div [matMenuTriggerFor]="perfil" style="cursor: pointer">
          <div style="display: flex; flex-direction: row">
            <span
              style="flex: 1; align-content: center; justify-content: center"
              [class.proxied]="proxied"
              [matTooltipDisabled]="!proxied"
              matTooltip="Você está logado como um usuário de um cliente"
            >
              <small *ngIf="proxied" style="margin-left: 2.5em"
                >Logado como</small
              ><br *ngIf="proxied" />
              <span *ngIf="proxied"><i class="fas fa-user-secret"></i></span>
              {{ usuario?.nome }}</span
            >
            <mat-icon>account_circle</mat-icon>
          </div>
        </div>
        <mat-menu #perfil="matMenu">
          <button class="btn-perfil" mat-menu-item routerLink="/perfil">
            Perfil
          </button>
          <button class="btn-perfil" mat-menu-item (click)="logout()">
            Sair
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>

    <div class="content" [class.allow-print]="!show">
      <router-outlet></router-outlet>
    </div>
    <footer
      *ngIf="show"
      class="fixed-bottom"
      style="background-color: #fff; z-index: 9999"
    >
      <div id="content-copyright">
        <p>Copyright {{ anoAtual }} @ LiaSys. Todos os direitos reservados.</p>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
