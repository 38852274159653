import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import {
  BenchmarkService,
  BenchmarkListDataSource
} from "src/app/_services/benchmark.service";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { tap } from "rxjs/operators";
import {
  MetaService, MetaQuery
} from "src/app/_services/meta.service";
import { UIService } from 'src/app/_helpers/ui.service';
import { DialogDeleteComponent } from 'src/app/_helpers/dialog-delete/dialog-delete.component';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { Meta } from 'src/app/_models/benchmark.models';
import { DialogGraficoMetaComponent } from './dialog-grafico-meta/dialog-grafico-meta.component';

@Component({
  selector: "app-metas",
  templateUrl: "./metas.component.html",
  styleUrls: ["./metas.component.scss"]
})
export class MetasComponent {

  datasource = new PaginatedDataSource<Meta, MetaQuery>(
    (request, query) => this.metaService.listarMetasPaginado(request, query),
    {property: 'idMeta', order: 'asc'},
    {search: ''},
    environment.LIMITE_REGISTROS_LISTA
  )

  search = new FormControl('');
  columnsToDisplay = [
    "nome",
    "data",
    "percentualMes",
    "percentualAno",
    "percentual3",
    "percentual6",
    "percentual12",
    "percentual18",
    "percentual24",
    "apagar"
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private metaService: MetaService, private uiService: UIService, private dialog: MatDialog) {}

  removerMetas(meta: Meta)  {
    const dialog = this.dialog.open(DialogDeleteComponent, { data: { name: meta.nome } });
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.uiService.abrirDialogLoading(
          this.metaService.removerMeta(meta.idMeta),
          'Removendo meta...',
          'Meta removida com sucesso',
          'Erro ao excluir metas, podem haver referências a esta meta.'
        ).subscribe(v => {
          this.datasource.queryBy({ search: '' });
        });
      }
    });
  }

  abrirDialogGrafico(meta: Meta)  {
    const dialog = this.dialog.open(DialogGraficoMetaComponent, { data: { meta: meta }, width: '500px' });
  }
}
