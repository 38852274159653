import { Component, OnInit, Input } from "@angular/core";
import { PaginatedDataSource } from "src/app/_services/generic-datasource";
import { AprQuery, APRService } from "src/app/_services/apr.service";
import { APR, StatusAPR } from "src/app/_models/arp.model";
import { PaginatedEndpoint } from "src/app/_models/PaginatedResult";
import { AuthService } from "src/app/_services/auth.service";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import { CarteiraService } from "src/app/_services/carteira.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogLoadingComponent } from "src/app/_helpers/dialog-loading/dialog-loading.component";
import { DialogConfirmComponent } from "src/app/_helpers/dialog-confirm/dialog-confirm.component";
import { DialogDeleteComponent } from "src/app/_helpers/dialog-delete/dialog-delete.component";
import { DialogConfirmacaoComponent } from "src/app/_helpers/dialog-confirmacao/dialog-confirmacao-component";
import { withLatestFrom, tap } from "rxjs/operators";

@Component({
  selector: "app-apr-pendentes",
  templateUrl: "./apr-pendentes.component.html",
  styleUrls: ["./apr-pendentes.component.scss"],
})
export class AprPendentesComponent implements OnInit {
  @Input() idCliente: number;

  datasource: PaginatedDataSource<APR, AprQuery>;
  columns = [
    "id",
    "fundo",
    "status",
    "operacao",
    "valor",
    "data",
    "conta",
    "geracao",
  ];

  _usuario$: Subscription;

  aprs: APR[];

  constructor(
    private authService: AuthService,
    private aprService: APRService,
    private dialog: MatDialog,
    private carteiraService: CarteiraService
  ) {}

  ngOnInit() {
    this._usuario$ = this.authService.usuario$.subscribe((usuario) => {
      let endpoint: PaginatedEndpoint<APR, AprQuery>;
      endpoint = (req, query) => this.aprService.listaTodosPaginado(req, query);

      this.datasource = new PaginatedDataSource<APR, AprQuery>(
        endpoint,
        { property: "id", order: "asc" },
        { search: "", idCliente: this.idCliente, status: StatusAPR.Pendente },
        environment.LIMITE_REGISTROS_LISTA
      );

      this.datasource.page$.subscribe(pagina => this.aprs = pagina.data);
    });
  }

  ngOnDestroy() {
    this._usuario$.unsubscribe();
  }

  lancarApr(apr: APR) {
    const dialogConfirm = this.dialog.open(DialogConfirmacaoComponent, {
      data: {
        text: `Confirma a execução do movimento para a APR  ${apr.id}`,
      },
    });

    dialogConfirm.afterClosed().subscribe((confirmado) => {
      if (confirmado) {
        const dialogRef = this.dialog.open(DialogLoadingComponent, {
          data: {
            text: "Realizando lançamento apartir da APR...",
            target: this.carteiraService.lancarMovimentoApr(apr),
            closeOnComplete: true,
            successMessage: "Movimento gerado com sucesso",
            errorMessage: ({ error }) => `Erro: ${error.error}`,
          },
        });
        dialogRef.afterClosed().subscribe((c) => {
          if (c) {
            this.datasource.queryBy({ search: "" });
          }
        });
      }
    });
  }

  lancarTodasAPRs() {
    const dialogConfirm = this.dialog.open(DialogConfirmacaoComponent, {
      data: {
        text: `Confirma a execução de TODAS as APRs pendentes para o cliente?`,
      },
    });

    dialogConfirm.afterClosed().subscribe((confirmado) => {
      if (confirmado) {
        const dialogRef = this.dialog.open(DialogLoadingComponent, {
          data: {
            text: "Realizando lançamento apartir da APR...",
            target: this.carteiraService.lancarTodasAprs(this.aprs),
            closeOnComplete: true,
            successMessage: "Movimento gerado com sucesso",
            errorMessage: ({ error }) => `Erro: ${error.error}`,
          },
        });
        dialogRef.afterClosed().subscribe((c) => {
          if (c) {
            this.datasource.queryBy({ search: "" });
          }
        });
      }
    });
  }

  geracaoPDF(id: number) {
    const url = `${environment.PATH_API}/apr/ExportAPRPDF/${id}`;
    this.authService.openWindowWithAuth(url);
  }
}
