import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { InvestinentoOtimizador, InvestimentoOtimizadorAPI } from '../_models/fundo-investimento.models';
import { Ranking, Otimizacao } from '../_models/otimizador.models';


@Injectable({
  providedIn: 'root'
})
export class OtimizadorService {

  private url = `${environment.PATH_API}/otimizador`;
  erro = false;

  constructor(private http: HttpClient) { }

  otimizar(fundos: InvestimentoOtimizadorAPI[], database: string, numMeses: number, passo, inicio?, fim?) {
    let params = new HttpParams();
    params = params.append('passo', passo);
    if (inicio) {
      params = params.append('inicio', inicio);
    }
    if (fim) {
      params = params.append('fim', fim);
    }
    const obj = {
      database: database,
      obs: numMeses,
      fundos: fundos
    };
    return this.http.post<Otimizacao[]>(`${this.url}/otimizar`, obj, {params: params});
  }

  ranking(database: string, numMeses: number, fundos: InvestimentoOtimizadorAPI[]) {
    const obj = {
      database: database,
      obs: numMeses,
      fundos: fundos
    };
    return this.http.post<Ranking[]>(`${this.url}/`, obj);
  }

  rentabilidadesFundo(cnpj: string) {
    let cnpjLimpa = cnpj.replace(/[^\d\s]/gi, "");
    return this.http.get(`${this.url}/rentabilidade-mensal/${cnpjLimpa}`);
  }
}

export interface InformacoesOtimizacao {
  database: string;
  numMeses: number;
  passo: number;
  inicial: number;
  final: number;
  carteira?: InvestinentoOtimizador[];
}
