<h1 class="title">Retorno: {{ complementoTitulo }}</h1>
<form>
  <mat-form-field>
    <input matInput [matDatepicker]="dp" [max]="atual" placeholder="Mês/Ano" [formControl]="date" [min]="minDate"
      [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>


  <label class="hidePrint" style="margin-left: 0.5em;">Zoom:</label>
  <mat-slider class="hidePrint" min="0.5" max="1" step="0.01" value="1" [formControl]="zoom"
    color="primary"></mat-slider>

  <button class="hidePrint" (click)="print()" mat-flat-button color="primary" style="float: right;">
    <i class="fas fa-print"></i>Imprimir
  </button>
</form>
<div [hidden]="notLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>


<table [style.zoom]="zoom.value" mat-table [dataSource]="registros">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="fundo">
    <th mat-header-cell *matHeaderCellDef style="width: 35%;">Fundo</th>
    <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
      <span class="mobile-label">Fundo:</span>
      <span>{{ retorno.cnpj }} - </span>{{ retorno.nome | resumoNomeFundo }}
    </td>

  </ng-container>

  <ng-container matColumnDef="benchmark">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Benchmark</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Benchmark:</span>
      {{ retorno.benchmark }}
    </td>
  </ng-container>

  <ng-container matColumnDef="mes">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;" [style.width]="widthColuna">Mês</th>
    <td mat-cell *matCellDef="let retorno" style="text-align: center;">
      <span class="mobile-label">Mês:</span>
      {{ retorno.mes | percent:'1.4-4' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="ano">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;" [style.width]="widthColuna">Ano</th>
    <td mat-cell *matCellDef="let retorno" style="text-align: center;">
      <span class="mobile-label">Ano:</span>
      {{ retorno.ano | percent:'1.4-4' }}
    </td>

  </ng-container>

  <ng-container matColumnDef="3meses">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;" [style.width]="widthColuna">Últimos 3 Meses</th>
    <td mat-cell *matCellDef="let retorno" style="text-align: center;">
      <span class="mobile-label">Últimos 3 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 3, 'month')">{{ retorno.meses3 | percent:'1.4-4' }}</span>
    </td>

  </ng-container>

  <ng-container matColumnDef="6meses">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;" [style.width]="widthColuna">Últimos 6 Meses</th>
    <td mat-cell *matCellDef="let retorno" style="text-align: center;">
      <span class="mobile-label">Últimos 6 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 6, 'month')">{{ retorno.meses6 | percent:'1.4-4' }}</span>
    </td>

  </ng-container>

  <ng-container matColumnDef="12meses">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;" [style.width]="widthColuna">Últimos 12 Meses</th>
    <td mat-cell *matCellDef="let retorno" style="text-align: center;">
      <span class="mobile-label">Últimos 12 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 12, 'month')">{{ retorno.meses12 | percent:'1.4-4' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="24meses">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;" [style.width]="widthColuna">Últimos 24 Meses</th>
    <td mat-cell *matCellDef="let retorno" style="text-align: center;">
      <span class="mobile-label">Últimos 24 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 24, 'month')">{{ retorno.meses24 | percent:'1.4-4' }}</span>
    </td>
  </ng-container>

</table>
<br /><br />
<table [style.zoom]="zoom.value" mat-table [dataSource]="resumoDados">
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="fundo">
    <td mat-cell *matCellDef="let retorno" style="padding-right: 1em; width: 35%;">
      <span class="mobile-label">:</span>
      {{retorno.label}}
    </td>
  </ng-container>

  <ng-container matColumnDef="benchmark">
    <td mat-cell *matCellDef="let retorno" [style.width]="widthColuna" style="text-align: center;">
    </td>
  </ng-container>

  <ng-container matColumnDef="mes">
    <td mat-cell *matCellDef="let retorno" [style.width]="widthColuna" style="text-align: center;">
      <span class="mobile-label">Mês:</span>
      {{ retorno.mesValue }}
    </td>
  </ng-container>

  <ng-container matColumnDef="ano">
    <td mat-cell *matCellDef="let retorno" [style.width]="widthColuna" style="text-align: center;">
      <span class="mobile-label">Ano:</span>
      {{ retorno.anoValue }}
    </td>

  </ng-container>

  <ng-container matColumnDef="3meses">
    <td mat-cell *matCellDef="let retorno" [style.width]="widthColuna" style="text-align: center;">
      <span class="mobile-label">3 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 3, 'month')">{{ retorno.meses3Value }}</span>
    </td>

  </ng-container>

  <ng-container matColumnDef="6meses">
    <td mat-cell *matCellDef="let retorno" [style.width]="widthColuna" style="text-align: center;">
      <span class="mobile-label">6 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 6, 'month')">{{ retorno.meses6Value }}</span>
    </td>

  </ng-container>

  <ng-container matColumnDef="12meses">
    <td mat-cell *matCellDef="let retorno" [style.width]="widthColuna" style="text-align: center;">
      <span class="mobile-label">12 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 12, 'month')">{{ retorno.meses12Value }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="24meses">
    <td mat-cell *matCellDef="let retorno" [style.width]="widthColuna" style="text-align: center;">
      <span class="mobile-label">24 Meses:</span>
      <span *ngIf="isBefore(dataPrimeiroSaldo, 24, 'month')">{{ retorno.meses24Value }}</span>
    </td>
  </ng-container>

</table>