import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { switchMap, catchError } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FIList } from 'src/app/_models/fundo-investimento.models';

@Component({
  selector: 'app-autocomplete-fundo-investimentos',
  templateUrl: './autocomplete-fundo-investimentos.component.html',
  styleUrls: ['./autocomplete-fundo-investimentos.component.scss']
})

export class AutocompleteFundoInvestimentosComponent implements OnInit, OnDestroy, AfterViewInit {
  filtroFundo: BehaviorSubject<string>;
  fundos$: Observable<FIList[]>;
  fundoSelecionado: Subject<FIList>;

  @Input() control: FormControl;
  
  constructor(private fundoInvestimentoService: FundoInvestimentoService) { 
    this.filtroFundo = new BehaviorSubject('');
    this.fundoSelecionado = new Subject()
  }

  ngOnInit() {
    this.fundos$ = this.filtroFundo.pipe(
      switchMap(value => this.fundoInvestimentoService.filtroFundo(value).pipe(
        catchError(() => of([]))
      )),
    );
  }

  ngAfterViewInit() {
    this.fundoSelecionado.subscribe(fundo => this.control.setValue(fundo));
  }

  handleFocus = () => this.filtroFundo.next('');

  handleFiltro = (valor: string) => {
    this.filtroFundo.next(valor);
  }
  displayFundo = (fundo: FIList) => fundo ? `${fundo.cnpj} - ${fundo.nome}` : '';
  selectFundo = (fundo: FIList) => this.fundoSelecionado.next(fundo);

  ngOnDestroy() {
    this.fundoSelecionado.complete()
  }

}
