<br />
<br />
<mat-form-field>
  <input
    type="month"
    matInput
    [max]="atual"
    placeholder="Mês/Ano"
    [formControl]="date"
    [min]="minDate"
    [max]="maxDate"
     min="2024"
    (input)="onChangeDataFiltro($event.target.value)"
  />
</mat-form-field>

<br />
<br />
<strong>GESTÃO DE RISCO - MERCADO:</strong> O valor dos ativos que compõem a
carteira do fundo pode aumentar ou diminuir de acordo com flutuações de preços e
cotações de mercado, mudanças no cenário político e econômico, alterações nas
taxas de juros e, ainda, com os resultados das empresas emitentes de valores
mobiliários (ações, debêntures, notas promissórias, entre outros).
<br />

<strong>Indice Sharpe:</strong> O índice de Sharpe é uma medida que permite a
classicação do fundo em termos de desempenho global, sendo considerados melhores
os fundos com maior índice. Vale lembrar ainda que o índice varia se o resultado
for calculado com base em rentabilidades semanais, mensais ou anuais.
<br />
<strong>VaR</strong> - Value at Risk: Value-at-risk ou Valor sob Risco pode ser
definido como perda máxima, num determinado período, sob condições normais de
mercado, com determinado grau de confiança.
<br />
<br />
<div
  class="box"
  [ngClass]="{ 'box-with-shadow': viewRelatorioMedicasRisco }"
  (click)="viewRelatorioMedicasRisco = !viewRelatorioMedicasRisco"
>
  <div class="box-filtro">
    <h2>Medidas de Risco</h2>
    <div class="resumo" *ngIf="!isLoading">
      <h5>
        Total Sharpe:
        {{ medidasRiscoResumo ? medidasRiscoResumo.sharpe + " %" : "0 %" }}
      </h5>
      <h5>
        Total Var12:
        {{ medidasRiscoResumo ? medidasRiscoResumo.var12 + " %" : "0 %" }}
      </h5>
      <h5>
        Total Var (R$):
        {{
          medidasRiscoResumo
            ? (medidasRiscoResumo.varReais | currency : "BRL")
            : "R$ 0,00"
        }}
      </h5>
      <h5>
        Total Vol12:
        {{ medidasRiscoResumo ? medidasRiscoResumo.vol12 + " %" : "0 %" }}
      </h5>
    </div>
    <mat-icon>{{
      viewRelatorioMedicasRisco ? "keyboard_arrow_up" : "keyboard_arrow_down"
    }}</mat-icon>
  </div>

  <div *ngIf="viewRelatorioMedicasRisco" class="box-table">
    <div *ngIf="isLoading" class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <br />
    <table
      mat-table
      [dataSource]="dataSourceMedidasRisco"
      matSort
      *ngIf="!isLoading"
    >
      <ng-container matColumnDef="cnpj">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          CNPJ
        </th>
        <td mat-cell *matCellDef="let medidaRisco">
          {{ medidaRisco.cnpjFundo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nome">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Nome
        </th>
        <td mat-cell *matCellDef="let medidaRisco">
          {{ medidaRisco.nomeFundo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="benchmark">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Benchmark
        </th>
        <td mat-cell *matCellDef="let medidaRisco">
          {{ medidaRisco.benchmark }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sharpe">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Sharpe
        </th>
        <td mat-cell *matCellDef="let medidaRisco">
          {{ medidaRisco.sharpe + " %" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="var12">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Var 12
        </th>
        <td mat-cell *matCellDef="let medidaRisco">
          {{ medidaRisco.var12 + " %" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="varrs">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Var R$
        </th>
        <td mat-cell *matCellDef="let medidaRisco">
          {{ medidaRisco.varReais | currency : "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vol12">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Vol 12
        </th>
        <td mat-cell *matCellDef="let medidaRisco">
          {{ medidaRisco.vol12 + " %" }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="ColumnsMedidasRisco"></tr>
      <tr mat-row *matRowDef="let row; columns: ColumnsMedidasRisco"></tr>
    </table>
  </div>
</div>
<div
  class="box"
  [ngClass]="{ 'box-with-shadow': viewRelatorioProvisaoRisco }"
  (click)="viewRelatorioProvisaoRisco = !viewRelatorioProvisaoRisco"
>
  <div class="box-filtro">
    <h2>Provisão de Risco</h2>

    <mat-icon>{{
      viewRelatorioProvisaoRisco ? "keyboard_arrow_up" : "keyboard_arrow_down"
    }}</mat-icon>
  </div>

  <div *ngIf="viewRelatorioProvisaoRisco" class="box-table">
    <div *ngIf="isLoading" class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <br />
    <table
      mat-table
      [dataSource]="dataSourceProvisaoRisco"
      matSort
      *ngIf="!isLoading"
    >
      <ng-container matColumnDef="cnpj">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          CNPJ
        </th>
        <td mat-cell *matCellDef="let provisaoRisco">
          {{ provisaoRisco.cnpjFundo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nome">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Nome
        </th>
        <td mat-cell *matCellDef="let provisaoRisco">
          {{ provisaoRisco.nomeFundo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="benchmark">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Benchmark
        </th>
        <td mat-cell *matCellDef="let provisaoRisco">
          {{ provisaoRisco.benchmark }}
        </td>
      </ng-container>
      <ng-container matColumnDef="saldo">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Saldo
        </th>
        <td mat-cell *matCellDef="let provisaoRisco">
          {{ provisaoRisco.saldo | currency : "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="var12">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Var 12
        </th>
        <td mat-cell *matCellDef="let provisaoRisco">
          {{ provisaoRisco.var + " %" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="varrs">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Var R$
        </th>
        <td mat-cell *matCellDef="let provisaoRisco">
          {{ provisaoRisco.varReais | currency : "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vol12">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Vol 12
        </th>
        <td mat-cell *matCellDef="let provisaoRisco">
          {{ provisaoRisco.vol12 + " %" }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="ColumnsProvisaoRisco"></tr>
      <tr mat-row *matRowDef="let row; columns: ColumnsProvisaoRisco"></tr>
    </table>
  </div>
</div>

<div *ngIf="isLoading" class="spinner">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<table
  mat-table
  [dataSource]="dataSourceProvisaoRiscoResumo"
  matSort
  *ngIf="!isLoading"
>
  <ng-container matColumnDef="campo">
    <th
      class="table table-header"
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Resumo Provisão de Risco
    </th>
    <td mat-cell *matCellDef="let provisaoRisco">
      {{ provisaoRisco.label }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valor">
    <th
      class="table table-header"
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Total
    </th>
    <td mat-cell *matCellDef="let provisaoRisco">
      {{
        provisaoRisco.type == "currency"
          ? (provisaoRisco.value | currency : "BRL")
          : provisaoRisco.value + " %"
      }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="ColumnsProvisaoRiscoresumo"></tr>
  <tr mat-row *matRowDef="let row; columns: ColumnsProvisaoRiscoresumo"></tr>
</table>
<br />
<div
  class="box"
  [ngClass]="{ 'box-with-shadow': viewRelatorioRiscoLiquidez }"
  (click)="viewRelatorioRiscoLiquidez = !viewRelatorioRiscoLiquidez"
>
  <div class="box-filtro">
    <h2>Risco de Liquidez</h2>

    <mat-icon>{{
      viewRelatorioRiscoLiquidez ? "keyboard_arrow_up" : "keyboard_arrow_down"
    }}</mat-icon>
  </div>

  <div *ngIf="viewRelatorioRiscoLiquidez" class="box-table">
    <div *ngIf="isLoading" class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <br />
    <table
      mat-table
      [dataSource]="dataSourceRiscoLiquidez"
      matSort
      *ngIf="!isLoading"
    >
      <ng-container matColumnDef="nome">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Nome
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.nomeFundo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="saldo">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Saldo
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.saldo | currency : "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="participacao">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Participação
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.participacao + " %" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="numCotistas">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          N. Cotistas
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.numCotistas }}
        </td>
      </ng-container>
      <ng-container matColumnDef="percPl">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          % PL
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.percPl + " %" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="plFundo">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          PL do Fundo
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.plFundo | currency : "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="legislacao">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Legislação
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.legislacao }}
        </td>
      </ng-container>
      <ng-container matColumnDef="risco">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Risco
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.risco }}
        </td>
      </ng-container>
      <ng-container matColumnDef="prazoResgate">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Prazo Resgate
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.prazoResgate }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="ColumnsRiscoLiquidez"></tr>
      <tr mat-row *matRowDef="let row; columns: ColumnsRiscoLiquidez"></tr>
    </table>
  </div>
</div>

<div
  class="box"
  [ngClass]="{ 'box-with-shadow': viewRelatorioRiscoLiquidezResumo }"
  (click)="viewRelatorioRiscoLiquidezResumo = !viewRelatorioRiscoLiquidezResumo"
>
  <div class="box-filtro">
    <h2>Resumo do Risco de Liquidez</h2>

    <mat-icon>{{
      viewRelatorioRiscoLiquidezResumo
        ? "keyboard_arrow_up"
        : "keyboard_arrow_down"
    }}</mat-icon>
  </div>
  <div *ngIf="viewRelatorioRiscoLiquidezResumo" class="box-table">
    <div *ngIf="isLoading" class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <table
      mat-table
      [dataSource]="dataSourceRiscoLiquidezResumo"
      matSort
      *ngIf="!isLoading"
    >
      <ng-container matColumnDef="prazoResgate">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Prazo Resgate
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.prazoResgate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="saldo">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Total
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.saldoTotal | currency : "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="NumFundo">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          N. Fundo
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.numFundos }}
        </td>
      </ng-container>
      <ng-container matColumnDef="PercCarteira">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          % Carteira
        </th>
        <td mat-cell *matCellDef="let riscoLiquidez">
          {{ riscoLiquidez.percentualCarteira }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="ColumnsRiscoLiquidezResumo"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ColumnsRiscoLiquidezResumo"
      ></tr>
    </table>
  </div>
</div>

<div *ngIf="isLoading" class="spinner">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div style="display: block; width: 80%" *ngIf="!isLoading">
  <h2>Saldo X Prazo Resgate</h2>

  <br />
  <canvas
    baseChart
    [datasets]="riscoLiquidezDados"
    [labels]="riscoLiquidezLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
  >
  </canvas>
</div>
<br />
<div
  class="box"
  [ngClass]="{ 'box-with-shadow': viewRelatorioRiscoCredito }"
  (click)="viewRelatorioRiscoCredito = !viewRelatorioRiscoCredito"
>
  <div class="box-filtro">
    <h2>Risco de Crédito</h2>

    <mat-icon>{{
      viewRelatorioRiscoCredito ? "keyboard_arrow_up" : "keyboard_arrow_down"
    }}</mat-icon>
  </div>
  <div *ngIf="viewRelatorioRiscoCredito" class="box-table">
    <div *ngIf="isLoading" class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <table
      mat-table
      [dataSource]="dataSourceRiscoCredito"
      matSort
      *ngIf="!isLoading"
    >
      <ng-container matColumnDef="legislacao">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Legislação
        </th>
        <td mat-cell *matCellDef="let riscoCredito">
          {{ riscoCredito.legislacao }}
        </td>
      </ng-container>
      <ng-container matColumnDef="limite">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Limite
        </th>
        <td mat-cell *matCellDef="let riscoCredito">
          {{ riscoCredito.outroLimite + " %" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="limitePolitica">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Limite Política
        </th>
        <td mat-cell *matCellDef="let riscoCredito">
          {{ riscoCredito.limitePolitica + " %" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="saldo">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Saldo
        </th>
        <td mat-cell *matCellDef="let riscoCredito">
          {{ riscoCredito.saldo | currency : "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="percCarteira">
        <th
          class="table table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          % Carteira
        </th>
        <td mat-cell *matCellDef="let riscoCredito">
          {{ riscoCredito.percentualSaldoTotal + " %" }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="ColumnsRiscoCredito"></tr>
      <tr mat-row *matRowDef="let row; columns: ColumnsRiscoCredito"></tr>
    </table>
  </div>
</div>
<div *ngIf="isLoading" class="spinner">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div *ngIf="isLoading" class="spinner">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<h2>Distribuição dos Saldos</h2>
<table
  mat-table
  [dataSource]="dataSourceRiscoCreditoResumo"
  matSort
  *ngIf="!isLoading"
>
  <ng-container matColumnDef="tipo">
    <th
      class="table table-header"
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    ></th>
    <td mat-cell *matCellDef="let riscoCredito">
      {{ riscoCredito.tipo }}
    </td>
  </ng-container>
  <ng-container matColumnDef="saldo">
    <th
      class="table table-header"
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      Saldo
    </th>
    <td mat-cell *matCellDef="let riscoCredito">
      {{ riscoCredito.valorGrupo | currency : "BRL" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="percCarteira">
    <th
      class="table table-header"
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
    >
      % Carteira
    </th>
    <td mat-cell *matCellDef="let riscoCredito">
      {{
        riscoCredito.percentual > 0
          ? riscoCredito.percentual.toFixed(2) + " %"
          : "0.00 % "
      }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="ColumnsRiscoCreditoresumo"></tr>
  <tr mat-row *matRowDef="let row; columns: ColumnsRiscoCreditoresumo"></tr>
</table>
<div style="display: block; width: 80%" *ngIf="!isLoading">
  <h2>Distribuição dos Saldos</h2>

  <br />
  <canvas
    baseChart
    [datasets]="riscoCreditoDados"
    [labels]="riscoCreditoLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
  >
  </canvas>
</div>
<br />
