import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogDeleteComponent } from "src/app/_helpers/dialog-delete/dialog-delete.component";
import { UIService } from 'src/app/_helpers/ui.service';
import { CVMBlackList } from "src/app/_models/blacklist.model";
import { BlackListQuery, BlackListService } from "src/app/_services/blacklist.service";
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { environment } from 'src/environments/environment';
import { DialogCadCVMBlackList } from "./CadEmissorBlackListCVM/dialog-cad-cvm-blacklist.component";
@Component({
  selector: "app-cvm-blacklist",
  templateUrl: "./cvm-blacklist.component.html",
  styleUrls: ["./cvm-blacklist.component.scss"],
})
export class CVMBlackListComponent implements OnInit {

  datasource = new PaginatedDataSource<CVMBlackList, BlackListQuery>(
    (request, query) => this.blackListService.listarCVMPaginado(request, query),
    {property: 'value', order: 'asc'},
    {search: ''},
    environment.LIMITE_REGISTROS_LISTA
  )

  columnsToDisplay = ['value','acoes'];

  constructor(
    private blackListService:BlackListService,
    private uiService: UIService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    // this.idCliente = this.route.snapshot.paramMap.get("idCliente");
  }




  excluirBlacklist(registro:CVMBlackList)  {

    const dialog = this.dialog.open(DialogDeleteComponent, { data: { name: registro.value } });
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.uiService.abrirDialogLoading(
          this.blackListService.removerBlackListCVM(registro.id),
          'Excluindo registro...',
          'Registro excluído com sucesso',
          'Erro ao excluir o registro.'
        ).subscribe(v => {
          this.datasource.queryBy({ search: '' });
        });
      }
    });
  }

  abrirDialogNovo() {
    const dialogRef = this.dialog.open(DialogCadCVMBlackList, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


}
