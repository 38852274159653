<h1 class="title">Novo conjunto</h1>
<form [formGroup]="form" (ngSubmit)="salvarConjunto()">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="70">
      <input matInput type="text" formControlName="nome" placeholder="Nome do conjunto">
    </mat-form-field>
  </div>
  <h4><i class="fas fa-search-dollar"></i>Fundos do conjunto</h4>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="50">
      <input matInput type="text" #fundo placeholder="Fundo" [value]="(filtroFundo | async)" (keyup)="handleFiltro($event.target.value)" (keydown.enter)="adicionarFundo()" [matAutocomplete]="auto">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFundo" (optionSelected)="selectFundo($event.option.value)">
      <mat-option *ngFor="let option of (fundos$ | async)" [value]="option">{{option.cnpj | mask:'00.000.000/0000-00'}} - {{option.nome}}</mat-option>
    </mat-autocomplete>
    <button type="button" fxFlex="20" fxFlexAlign="center" fxFlexAlign.lt-md="stretch" matTooltip="Adicionar fundo ao conjunto" mat-flat-button [disabled]="!fundoSelecionado" (click)="adicionarFundo(); fundo.value = ''" color="primary">
      <i class="fas fa-angle-double-down"></i>Adicionar
    </button>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
    <mat-list class="fundos" fxFlex="70" >
      <mat-list-item *ngFor="let fundo of conjunto; let i = index" class="mat-elevation-z1">
        <h3 matLine> <strong>{{fundo.cnpj | mask:'00.000.000/0000-00'}}</strong> </h3>
        <p matLine> {{fundo.nome}} </p>
        <button type="button" mat-icon-button color="primary" (click)="removerFundo(i)" matTooltip="Remover fundo"><i class="fas fa-times"></i></button>
      </mat-list-item>
    </mat-list>
  </div>
  <div style="margin: 1em 0;">
    <mat-slide-toggle color="primary" formControlName="disponivelUsuarios">
      Disponíbilizar para os usuários
    </mat-slide-toggle>
    <br>
    <small *appHasRole="[TipoUsuario.ADMIN]">(Após disponibilizar você deve atribuir aos usuários ou grupos que poderão ver o conjunto)</small>
  </div>
  <div *appHasRole="[TipoUsuario.ADMIN]">
    <div *ngIf="form.value.disponivelUsuarios" fxLayout="row" fxLayout.lt-md="column"  fxLayoutGap="15px">
      <div fxFlex="35" *ngIf="false">
        <h4><i class="fas fa-user"></i>Usuários</h4>
        <div fxLayout="column">
          <mat-form-field>
            <input matInput type="text" #fundo placeholder="Nome do cliente"  [value]="(filtroFundo | async)"
            (keypress)="handleFiltro($event.target.value)" (keydown.enter)="adicionarFundo()" [matAutocomplete]="auto">
          </mat-form-field>
          <button type="button" fxFlex="10" matTooltip="Adicionar fundo ao conjunto"
          mat-flat-button [disabled]="!fundoSelecionado" (click)="adicionarFundo(); fundo.value = ''" color="primary">
          <i class="fas fa-user-plus"></i>Adicionar
        </button>
      </div>
      <mat-list>
        <mat-list-item *ngFor="let fundo of conjunto; let i = index" class="mat-elevation-z1">
          <h3 matLine> <strong>cpf cliente</strong> </h3>
          <p matLine>nome cliente</p>
          <button type="button" mat-icon-button color="primary" (click)="removerFundo(i)" matTooltip="Remover conjunto do usuário"><i
            class="fas fa-user-slash"></i></button>
          </mat-list-item>
        </mat-list>
      </div>
      <div fxFlex="70">
        <h4><i class="fas fa-users"></i>Clientes</h4>
        <div fxLayout="column">
          <mat-form-field>
            <input matInput type="text" #cliente placeholder="Nome do cliente" [value]="(filtroCliente | async)"
            (keypress)="handleFiltroCliente($event.target.value)" (keydown.enter)="adicionarCliente()" [matAutocomplete]="autocliente">
          </mat-form-field>
          <mat-autocomplete #autocliente="matAutocomplete" [displayWith]="displayCliente"
          (optionSelected)="selectCliente($event.option.value)">
          <mat-option *ngFor="let option of (clientes$ | async)" [value]="option">{{option.nome}}</mat-option>
        </mat-autocomplete>
        <button type="button" fxFlex="10" matTooltip="Adicionar conjunto ao cliente" mat-flat-button [disabled]="!clienteSelecionado"
        (click)="adicionarCliente(); cliente.value = ''" color="primary">
        <i class="fas fa-plus-circle"></i>Adicionar
      </button>
    </div>
    <mat-list>
      <mat-list-item *ngFor="let cliente of clientes; let i = index" class="mat-elevation-z1">
        <p matLine><strong>{{cliente.cnpj}}</strong></p>
        <p matLine>{{cliente.nome}}</p>
        <button type="button" mat-icon-button color="primary" (click)="removerCliente(i)" matTooltip="Remover conjunto do cliente"><i
          class="fas fa-user-slash"></i></button>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayout.lt-md="column">
  <button fxFlex="20" type="submit" [disabled]="form.invalid" mat-raised-button color="warn"><i class="far fa-save"></i>Salvar conjunto</button>
</div>

</form>