<div>
  <h3>Adicionar análise de relatório</h3>
  <br />
  <div class="container">
    <mat-form-field>
      <input
        matInput
        (change)="onChangeDesc($event)"
        formControlName="descricao"
        placeholder="Descricao"
        required
        aria-label="Descrição"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Adicionar análise para todos os clientes?</mat-label>
      <mat-select [(value)]="todos">
        <mat-option *ngFor="let opcao of opcoes" [value]="opcao.valor">
          {{ opcao.texto }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input
        #data
        matInput
        type="month"
        (change)="onChangeData($event)"
        formControlName="data"
        placeholder="Data"
        required
        aria-label="Data do documento"
      />
      <mat-error *ngIf="!data.valid">Informe a data do documento</mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea
        matInput
        rows="10"
        (change)="onChangeTexto($event)"
        formControlName="texto"
        placeholder="Texto"
        [ngModel]="texto"
      ></textarea>
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="enviarAnalise()">
      Enviar
    </button>
  </div>
</div>
