import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIService } from 'src/app/_helpers/ui.service';
import { Carteira, MovimentoCarteira } from 'src/app/_models/carteira.model';
import { CarteiraService } from 'src/app/_services/carteira.service';
import moment from 'moment';

interface DialogData {
  investimento: Carteira;
  data: Date;
  saldo: number;
}

@Component({
  selector: 'app-dialog-ajuste-saldo',
  templateUrl: './dialog-ajuste-saldo.component.html',
  styleUrls: ['./dialog-ajuste-saldo.component.scss']
})

export class DialogAjusteSaldoComponent implements OnInit {

  form: FormGroup;
  movimentos:  MovimentoCarteira[];

  constructor(
    private dialog: MatDialogRef<DialogAjusteSaldoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private fb: FormBuilder,
    private carteiraService: CarteiraService,
    private uiService: UIService
  ) {
    let date = null;
    if(dialogData.data) {
      date = dialogData.data;
    }
    this.form = fb.group({
      dataMovimento: [date, Validators.required],
      saldo: [{ value: dialogData.saldo, disabled: true }, null],
      valor: [null, Validators.required],
      operacao: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.carteiraService.getMovimentosInvestimento(this.dialogData.investimento.idCliente, this.dialogData.investimento.id).subscribe(movimentos => {
      this.movimentos = movimentos;
    })
  }

  submit() {
    const data = {
      ...this.form.value
    };

    const observable = this.carteiraService.lancarAjusteSaldo(this.dialogData.investimento.id, data)

    this.uiService
      .abrirDialogLoading(
        observable,
        "Lançando ajuste...",
        "Ajuste lançado com sucesso!",
        (error) => `Erro: ${error.error}`
      )
      .subscribe(result => {
        if(result) {
          this.dialog.close(result);
        }
      })

  }

  onDateSelect({ value }) {
    
    let data = moment(value);
    data = data.endOf('day');

    const movimentos = this.movimentos.filter(mov => moment(mov.dataMovimento).isSameOrBefore(data));

    if(movimentos.length > 0){
      this.form.patchValue({
        saldo: movimentos[0].saldo.saldoFinal
      })
    }
   
  }

}
