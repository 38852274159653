import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'media'
})
export class MediaPipe implements PipeTransform {

  transform(items: any[], attr: string): any {
    const t = items.reduce((a, b) => a + (b[attr] ? b[attr] : 0), 0);
    
    const size = items.length;

    return t / size;
  }

}
