import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { flatMap, groupBy, map, toArray } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { UIService } from 'src/app/_helpers/ui.service';
import { InvestimentoOtimizadorAPI } from 'src/app/_models/fundo-investimento.models';
import { Fundo, Otimizacao } from 'src/app/_models/otimizador.models';
import { InformacoesOtimizacao, OtimizadorService } from 'src/app/_services/otimizador.service';

@Component({
  selector: 'app-resultado-otimizador',
  templateUrl: './resultado-otimizador.component.html',
  styleUrls: ['./resultado-otimizador.component.scss']
})
export class ResultadoOtimizadorComponent implements OnInit {
  displayedColumns = ['cnpj', 'peso'];

  otimizacoes: Otimizacao[];
  otimizacaoSelecionada: Otimizacao;
  pontosRentabilidade: any[];
  rankingDinamico: Observable<any>;
  series: any[];
  resultado = [];
  colorScheme = {
    domain: ['#01579B', '#fbc02d', '#c62828']
  };
  dataSourceOtimizacao = new MatTableDataSource<Fundo>([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  referenceLines = [];
  informacoesOtimizacao: InformacoesOtimizacao;
  @ViewChild(MatTabGroup, { static: true }) tabs: MatTabGroup;

  loading = true;
  constructor(
    private otimizadorService: OtimizadorService,
    private router: Router,
    private uiService: UIService,
    private store: Store<AppState>
  ) {
    this.referenceLines.push({name: 'Risco baixo', value: 6});
    this.referenceLines.push({name: 'Risco Médio', value: 3});
    this.referenceLines.push({name: 'Risco alto', value: 0, background: '#FF0000'});
  }

  ngOnInit() {
    this.store.select('otimizador').subscribe(
      state => {
        this.informacoesOtimizacao = state;
      }
    );

    if (this.informacoesOtimizacao) {
      this.otimizadorService.erro = false;
      const fundos: InvestimentoOtimizadorAPI[] = this.informacoesOtimizacao.carteira.map(
        v => ({ cnpj: v.fundo.cnpj, min: v.limiteInferior, max: v.limiteSuperior })
      );
      this.otimizadorService.otimizar(
          fundos,
          this.informacoesOtimizacao.database,
          this.informacoesOtimizacao.numMeses,
          this.informacoesOtimizacao.passo / 100,
          this.informacoesOtimizacao.inicial / 100,
          this.informacoesOtimizacao.final / 100).subscribe(
        r => {
          this.otimizadorService.erro = false;
          this.otimizacoes = r;
          const maxSharpe = Math.max(...r.map(v => v.sharpe));
          this.referenceLines = []//this.referenceLines.filter(l => l.value < maxSharpe);
          this.pontosRentabilidade = r.map(v => ({label: this.formatarX(v.objetivo), value: v.objetivo}));
          this.series = r.map(v => ({name: v.sharpe, value: v.objetivo,bvar:v.sharpe*0.85}));
          this.resultado.push({name: 'Otimização', series: this.series});
          this.loading = false;

          this.rankingDinamico = from(r).pipe(
            flatMap(fundo => fundo.fundos),
            groupBy((fundo: any) => fundo.cnpj),
            flatMap(grupo => grupo.pipe(toArray())),
            map((fundo:any) => {
              return {
                cnpj: fundo[0].cnpj,
                nome: fundo[0].nome,
                total: fundo.map(f => f.peso).reduce((acc, val) => acc + val, 0),
                rank: undefined
              };
            }),
            toArray(),
            map(arr => {
              arr = arr.sort((a, b) => b.total - a.total);
              arr.forEach((e, i) => e.rank = i + 1);
              return arr;
            })
          );
        },
        error => {
          this.otimizadorService.erro = true;
          this.router.navigate(['otimizador']);
          this.uiService.showSnackBar('Erro, verifique o período da otimização!', 'OK', 5000);
        }
      );
      this.dataSourceOtimizacao.sort = this.sort;
    } else {
      this.router.navigate(['otimizador']);
    }
  }

  formatarY(valor) {
    return `${(valor * 100).toFixed(3)}%`;
  }

  formatarX(valor) {
    return `${(valor * 100).toFixed(3)}%`;
  }

  selecionadoRentabilidade(valor) {
    this.otimizacaoSelecionada = this.otimizacoes.filter(v => v.objetivo === valor)[0];
    this.dataSourceOtimizacao.data = this.otimizacaoSelecionada.fundos;
    this.tabs.selectedIndex = 1;
  }

  getTotalPercentual() {
    if (this.otimizacaoSelecionada) {
      return this.otimizacaoSelecionada.fundos.map(t => t.peso).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  reverseArray(arr: any[]) {
    return [...arr].reverse()
  }
}
