import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { delay } from "rxjs/operators";
import { ViewEnquadramento } from "src/app/_models/resolucao.model";
import { GroupByPipe } from "src/app/_pipes/groupBy.pipe";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { FundoInvestimentoService } from "src/app/_services/fundo-investimento.service";
import {
  EnquadramentoQuery,
  ResolucaoService,
} from "src/app/_services/resolucao.service";
import { environment } from "src/environments/environment";
import { MY_FORMATS } from "../relatorio-retornos/relatorio-retornos.component";

@Component({
  selector: "app-enquadramentos",
  templateUrl: "./enquadramentos.component.html",
  styleUrls: ["./enquadramentos.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EnquadramentosComponent implements OnInit {
  @Input()
  relatorioDinamico: Boolean = false;

  dataEntrada$ = new BehaviorSubject<string>(null);
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value);
  }

  @Output()
  loadingStatus = new EventEmitter<boolean>();

  zoom = new FormControl(1);
  atual = moment();
  date = new FormControl(moment().year(2024));
  columnsToDisplay = [
    "cnpjFundo",
    "saldo",
    "saldoCotas",
    "participacaoPL",
    "patrimonioLiquido",
    "participacaoCarteira",
    "limites",
    "situacao",
  ];

  columnsToDisplayAdminBench = ["cnpjFundo", "percentualSaldo", "saldo"];

  query: Partial<EnquadramentoQuery>;

  enquadramentos: ViewEnquadramento[];
  saldoTotalCliente: number = 0;
  complementoTitulo = "";
  exibirSaldo = false;

  agrupamentos: any[] = [];
  agrupamentoBenchmarks: any[] = [];
  agrupamentoAdmin: any[] = [];

  minDate: Date;
  maxDate: Date;

  constructor(
    private authService: AuthService,
    private resolucaoService: ResolucaoService,
    private clienteService: ClienteService,
    private FiService: FundoInvestimentoService,
    private route: ActivatedRoute
  ) {}



  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2024, 11, 31, 23, 59, 59);
    if (!this.relatorioDinamico) {
      window.onafterprint = (e) => this.authService.willPrint$.next(false);
    }
    if (!this.relatorioDinamico) {
      this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
        if (willPrint) {
          window.print();
        }
      });
    }

    this.query = {};
    const idCliente = +this.route.snapshot.paramMap.get("idCliente");
    const idEnquadramento = +this.route.snapshot.paramMap.get("id");
    const cnpj = this.route.snapshot.paramMap.get("cnpj");
    if (idCliente) {
      this.query.idCliente = idCliente.toString();
      this.clienteService.buscarClientePorId(idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
        this.exibirSaldo = true;
      });
    } else {
      // Caso não venha id do cliente, e tipo seja de usuário cliente faz o filtro pelo id do cliente logado.
      this.authService.usuario$.subscribe((usuario) => {
        if (
          [TipoUsuario.ADMIN_CLIENTE, TipoUsuario.USUARIO_CLIENTE].includes(
            usuario.tipoUser
          )
        ) {
          this.query.idCliente = usuario.idCliente.toString();
        } else {
          this.columnsToDisplay.splice(1, 0, "cliente"); // Se for admin e sem id do cliente adiciona coluna com nome do cliente
          this.columnsToDisplayAdminBench.splice(1, 0, "cliente"); // Se for admin e sem id do cliente adiciona coluna com nome do cliente
        }
      });
    }
    if (idEnquadramento) {
      this.query.idEnquadramento = idEnquadramento.toString();
    }
    if (cnpj) {
      this.query.cnpj = cnpj.replace(/[^\d\s]/gi, "");
      this.FiService.getFundoByCNPJ(cnpj).subscribe((fundo) => {
        this.complementoTitulo = ` - Fundo: ${cnpj} - ${fundo.fidados.nome}`;
      });
    }

    if (this.relatorioDinamico) {
      this.dataEntrada$.subscribe((value) => {
        this.loadingStatus.emit(false);
        this.date.setValue(moment(value, "DD/MM/YYYY"));
        this.retrieveEnquadramentos(moment(value, "DD/MM/YYYY"));
      });
    }

    this.retrieveEnquadramentos(this.date.value);
  }

  retrieveEnquadramentos(data: moment.Moment) {
    this.resolucaoService
      .viewEnquadramentos({
        ...this.query,
        ano: data.year().toString(),
        mes: (data.month() + 1).toString(),
      })
      .subscribe((enquadramentos) => {
        this.enquadramentos = enquadramentos;

        // const saldoTotal = this.enquadramentos.reduce(
        //   (acc, item) => acc + item.saldoFinal,
        //   0
        // );

        const saldoTotal = this.enquadramentos[0].saldoTotal;

        const agrupados = new GroupByPipe().transform(
          this.enquadramentos.map((item) => ({
            ...item,
            tipo: `(${item.tipoEnquadramento}) - ${item.legislacao}`,
          })),
          "tipo"
        );

        const grupo = agrupados.map(({ key, value }) => ({
          idEnquadramento: value[0].idEnquadramento,
          nomeGrupo: key,
          saldoGrupo:
            Array.isArray(value) &&
            value.reduce((acc, e) => acc + e.saldoFinal, 0),
          fundos: value.filter((item: any) => item.saldoFinal !== 0),
          enquadrado:
            Array.isArray(value) &&
            value.every((e) => e.situacaoEnquadramento === "Enquadrado"),
        }));

        this.agrupamentos = grupo.map((g) => ({
          ...g,
          percentualSaldo: g.saldoGrupo / saldoTotal,
        }));

        const agrupadosBench = new GroupByPipe().transform(
          this.enquadramentos.map((item) => ({
            ...item,
            tipo: `(${item.tipoEnquadramento}) - ${item.legislacao}`,
          })),
          "benchmark"
        );

        const grupoBench = agrupadosBench.map(({ key, value }) => ({
          nomeGrupo: key,
          saldoGrupo:
            Array.isArray(value) &&
            value.reduce((acc, e) => acc + e.saldoFinal, 0),
          fundos: value.map((fundo) => ({
            ...fundo,
            percentualSaldo: fundo.saldoFinal / saldoTotal,
          })).filter((item: any) => item.saldoFinal !== 0),
        }));

        this.agrupamentoBenchmarks = grupoBench.map((g) => ({
          ...g,
          percentualSaldo: g.saldoGrupo / saldoTotal,
        }));

        const agrupadosAdmin = new GroupByPipe().transform(
          this.enquadramentos.map((item) => ({
            ...item,
            tipo: `(${item.tipoEnquadramento}) - ${item.legislacao}`,
          })),
          "cnpj_admin"
        );

        const gruposAdmin = agrupadosAdmin.map(({ key, value }) => ({
          nomeGrupo: `${key} - ${value[0].admin}`,
          saldoGrupo:
            Array.isArray(value) &&
            value.reduce((acc, e) => acc + e.saldoFinal, 0),
          fundos: value.map((fundo) => ({
            ...fundo,
            percentualSaldo: fundo.saldoFinal / saldoTotal,
          })).filter((item: any) => item.saldoFinal !== 0),
        }));

        this.agrupamentoAdmin = gruposAdmin.map((g) => ({
          ...g,
          percentualSaldo: g.saldoGrupo / saldoTotal,
        }));

        if (Array.isArray(enquadramentos)) {
          //this.saldoTotalCliente = enquadramentos[0].saldoTotal;
          this.saldoTotalCliente = saldoTotal;
        }
        this.loadingStatus.emit(true);
      });
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
    //this.retrieveEnquadramentos(this.date.value);
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.startOf("month"));
    datepicker.close();
    this.retrieveEnquadramentos(this.date.value);
  }



  geracaoPDF() {
    const url = `${environment.PATH_API}/resolucao/pdf/${this.query.idCliente}?data=${moment(this.date.value).startOf("month").format('YYYY-MM-DD')}`;
    this.authService.openWindowWithAuth(url);
  }

  print() {
    this.authService.willPrint$.next(true);
  }
}
