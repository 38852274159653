import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import csvtojsonV2 from 'csvtojson';
@Injectable({
  providedIn: 'root'
})
export class AnbimaService {
  private aprURL = `/`;
  registros = [];
  assets = []

  constructor(private http: HttpClient) {}

  async fetchListaCodigos() {

    fetch('https://www.liasys.net/files/NUMERACA.TXT').then(async (response) => {
      const regs = await csvtojsonV2({
        delimiter: [';', ','],
        noheader: true,
        headers: ['data', 'acao', 'isin', 'codigoEmissor', 'codigoCFI', 'descricao', 'anoEmissao', 'dataEmissao', 'anoExpiracao', 'dataExpiracao']
      }).fromString(await response.text())
      this.registros = regs;
    })

    fetch('https://www.liasys.net/files/assets.txt').then(async (response) => {
      const regs = await csvtojsonV2({
        delimiter: [';', ','],
      }).fromString(await response.text())
      this.assets = regs;
    })
  }

  getFieldByIsin(isin, campo) {
    const reg = this.registros.find(c => c.isin === isin)
    if(reg){
      return reg[campo]
    }
    return isin
  }

  getAssetByCodAtivo(codAtivo, campo) {
    const reg = this.assets.find(c => c.Asset === codAtivo)
    if(reg){
      return reg[campo]
    }
    return codAtivo
  }



}
