import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogLoadingComponent } from 'src/app/_helpers/dialog-loading/dialog-loading.component';
import { UIService } from 'src/app/_helpers/ui.service';
import { APRService } from 'src/app/_services/apr.service';

export interface DialogData {
  idCliente: number;
}

@Component({
  selector: 'app-dialog-importacao-extrato',
  templateUrl: './dialog-importacao-extrato.component.html',
  styleUrls: ['./dialog-importacao-extrato.component.scss']
})
export class DialogImportacaoExtrato implements OnInit {

  file: any;

  constructor(
    private aprService: APRService,
    public dialogRef: MatDialogRef<DialogImportacaoExtrato>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private uiService: UIService,
    private _fb: FormBuilder) {
    }

  ngOnInit() {

  }

  changeArquivo(e) {
    this.file = e.target.files[0];
  }

  submitArquivo() {
    if(this.file) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        const loadingDialog = this.dialog.open(DialogLoadingComponent, {
          data: {
            text: 'Lendo arquivo e lançando as APRs',
            target: this.aprService.parseExtrato(this.data.idCliente, this.file.name, fileReader.result as string),
            closeOnComplete: true,
            successMessage: 'APRs geradas com sucesso',
            errorMessage: ({error}) => `Erro: ${error.error}`
          }
        });
        loadingDialog.afterClosed().subscribe(result =>{
          if(result) {
            this.dialogRef.close();
          }
        })
      }
      fileReader.readAsText(this.file, 'ISO-8859-1');
    }
  }

}
