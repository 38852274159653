import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogLoadingComponent } from './dialog-loading/dialog-loading.component';

@Injectable()
export class UIService {

    loadingChangeState = new Subject<boolean>();

    constructor(
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) { }

    showSnackBar(message, action, duration) {
        this.snackbar.open(message, action, {
            duration: duration
        });
    }

    abrirDialogLoading(
        target: Observable<any>,
        mensagem: string = 'Salvando...',
        sucesso: string = 'Salvo com sucesso',
        erro: any = 'Erro ao salvar, tente novamente',
        autoClose = true) {
        const dialogRef = this.dialog.open(DialogLoadingComponent, {
            data: {
                text: mensagem,
                target: target,
                closeOnComplete: autoClose,
                successMessage: sucesso,
                errorMessage: erro
            }
        });
        return dialogRef.afterClosed();
    }
}
