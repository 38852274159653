import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIService } from 'src/app/_helpers/ui.service';
import { Carteira } from 'src/app/_models/carteira.model';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { OperacaoSimulador } from '../simulador-enquadramento.component';

export interface DialogData {
  fundo: Carteira,
  operacao: OperacaoSimulador
}

@Component({
  selector: 'app-dialog-movimento-simulador-enquadramento',
  templateUrl: './dialog-movimento-simulador-enquadramento.component.html',
  styleUrls: ['./dialog-movimento-simulador-enquadramento.component.scss']
})
export class DialogMovimentoSimuladorEnquadramentoComponent implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogMovimentoSimuladorEnquadramentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private uiService: UIService,
    private fb: FormBuilder) {
      this.form = fb.group({
        valor: ['', Validators.required],
      });
    }

  ngOnInit() {
  }

  submit(){
    const obj = this.form.value;
    this.dialogRef.close({
      operacao: this.data.operacao,
      valor: obj.valor
    });
  }

}
