import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UIService } from 'src/app/_helpers/ui.service';
import { BenchmarkList } from 'src/app/_models/benchmark.models';
import { FundoInvestimento, FundoInvestimentoDadosAvulsos } from 'src/app/_models/fundo-investimento.models';
import { BenchmarkService } from 'src/app/_services/benchmark.service';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { ValidateCNPJ } from './../../../_validators/cpf_cnpj.validator';

@Component({
  selector: 'app-cadastro-fundo-investimento',
  templateUrl: './cadastro-fundo-investimento.component.html',
  styleUrls: ['./cadastro-fundo-investimento.component.scss']
})
export class CadastroFundoInvestimentoComponent implements OnInit {

  loading = false;

  grupos = [
    {label: 'Administrador', field: 'administrador'},
    {label: 'Gestor', field: 'gestor'},
    {label: 'Custodiante', field: 'custodiante'},
    {label: 'Corretora', field: 'corretora'},
    {label: 'Consultoria', field: 'consultoria'},
    {label: 'Distribuidor', field: 'distribuidor'},
  ];

  form = new FormGroup({
    nome: new FormControl(null, Validators.required),
    cnpj: new FormControl(null, [Validators.required]),
    origem: new FormControl('S'),
    codigo: new FormControl(null, Validators.required),
    dataInicio: new FormControl(null, Validators.required),
    patrimonioLiquido: new FormControl(null),
    prazoResgate: new FormControl(null),
    segmento: new FormControl(null),
    benchmark: new FormControl(null),
    taxaBenchmark: new FormControl(null),
    taxaAdm: new FormControl(null),
    grauRisco: new FormControl(null, Validators.required),
    tipo1: new FormControl(null, Validators.required),
    tipo2: new FormControl(null, Validators.required),
    administrador: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    gestor: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    custodiante: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    corretora: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    consultoria: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    distribuidor: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    prazo: new FormControl(null, Validators.required),
  });

  cnpjFundo: string;

  benchmarks: BenchmarkList[];
  segmentos: string[];

  legislacaoPre2018: any[] = [];
  legislacao: any[] = [];

  isEditando : boolean = false;

  constructor(
    private benchmarkService: BenchmarkService,
    private fundoInvestimentoService: FundoInvestimentoService,
    private dialog: MatDialog,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute) {
      this.cnpjFundo = route.snapshot.paramMap.get('cnpj');
    }

  ngOnInit() {
    if (this.cnpjFundo) {
      this.isEditando = true;
      this.loading = true;
      this.fundoInvestimentoService.getFundoByCNPJ(this.cnpjFundo).subscribe(
        fundo => {
          this.atualizarForm(fundo);
          this.loading = false;
        }
      );
    } else {
      this.fundoInvestimentoService.sequencialCodigoFundo().subscribe(codigo => {
        this.form.patchValue(codigo);
      });
    }

    this.benchmarkService.listaBenchmark().subscribe(
      (response: BenchmarkList[]) => {
        this.benchmarks = response;
      },
      (error) => {
        this.uiService.showSnackBar('Erro carregando benchmarks', null, 3000);
      }
    );

    this.fundoInvestimentoService.getClassesCVM().subscribe(
      (response: object[]) => {
        this.segmentos = response.filter(c => c['classe']).map((c) => c['classe']);

        if ( !this.segmentos.includes('Fundo de Renda Variável') ) {
          this.segmentos.push('Fundo de Renda Variável');
        }
        if ( !this.segmentos.includes('Fundo Ilíquido') ) {
          this.segmentos.push('Fundo Ilíquido');
        }
        if ( !this.segmentos.includes('Títulos Públicos') ) {
          this.segmentos.push('Títulos Públicos');
        }
        if ( !this.segmentos.includes('Fundo Exterior') ) {
          this.segmentos.push('Fundo Exterior');
        }
        this.segmentos = [...this.segmentos.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)];
      },
      (error) => {
        this.uiService.showSnackBar('Erro carregando segmentos', null, 3000);
      }
    );

    this.fundoInvestimentoService.getLegislacao(true).subscribe(r => {
      this.legislacaoPre2018 = r;
      console.log(r);
    });

    this.fundoInvestimentoService.getLegislacao(false).subscribe(r => {
      this.legislacao = r;
      console.log(r);
    });
  }


  salvar() {
    const dadosGrupos = {};

    this.grupos.forEach(grupo => {
      dadosGrupos[grupo.field] = {
        nome: this.form.get(grupo.field).get('nome').value,
        cnpj: this.form.get(grupo.field).get('cnpj').value,
        patrimonio: this.form.get(grupo.field).get('patrimonio').value,
        dataInicio: this.form.get(grupo.field).get('dataInicio').value
      };
    });


    const dadosFundo = <FundoInvestimento> {
      CNPJ: this.form.get('cnpj').value,
      codigoFundo: this.form.get('codigo').value,
      origem: this.form.get('origem').value,
      prazoResgate: this.form.get('prazoResgate').value,
      taxaADM: this.form.get('taxaAdm').value,
      benchTaxa: this.form.get('taxaBenchmark').value,
      idBench: this.form.get('benchmark').value,
      tipoI: this.form.get('tipo1').value,
      tipoII: this.form.get('tipo2').value,
      grauRisco: this.form.get('grauRisco').value,
      prazo:this.form.get("prazo").value,

      consultoriaNome: dadosGrupos['consultoria'].nome,
      consultoriaCNPJ: dadosGrupos['consultoria'].cnpj,
      consultoriaDataInicio: dadosGrupos['consultoria'].dataInicio,
      consultoriaPatrimonio: dadosGrupos['consultoria'].patrimonio,

      custodianteNome: dadosGrupos['custodiante'].nome,
      custodianteCNPJ: dadosGrupos['custodiante'].cnpj,
      custodianteDataInicio: dadosGrupos['custodiante'].dataInicio,
      custodiantePatrimonio: dadosGrupos['custodiante'].patrimonio,

      corretoraNome: dadosGrupos['corretora'].nome,
      corretoraCNPJ: dadosGrupos['corretora'].cnpj,
      corretoraDataInicio: dadosGrupos['corretora'].dataInicio,
      corretoraPatrimonio: dadosGrupos['corretora'].patrimonio,

      distribuidorNome: dadosGrupos['distribuidor'].nome,
      distribuidorCNPJ: dadosGrupos['distribuidor'].cnpj,
      distribuidorDataInicio: dadosGrupos['distribuidor'].dataInicio,
      distribuidorPatrimonio: dadosGrupos['distribuidor'].patrimonio,
    };

    const dadosAvulsos = <FundoInvestimentoDadosAvulsos>{
      nome: this.form.get('nome').value,
      CNPJ: this.form.get('cnpj').value,
      dataInicio: this.form.get('dataInicio').value,
      patrimonioLiquido: this.form.get('patrimonioLiquido').value,
      classe: this.form.get('segmento').value,
      adminNome: dadosGrupos['administrador'].nome,
      adminCNPJ: dadosGrupos['administrador'].cnpj,
      adminDataInicio: dadosGrupos['administrador'].dataInicio,
      adminPatrimonioLiquido: dadosGrupos['administrador'].patrimonio,
      gestorNome: dadosGrupos['gestor'].nome,
      gestorCNPJ: dadosGrupos['gestor'].cnpj,
      gestorPatrimonioLiquido: dadosGrupos['gestor'].patrimonio,
      gestorDataInicio: dadosGrupos['gestor'].dataInicio
    };
    dadosFundo.fidados = dadosAvulsos;


    let salvar: Observable<any>;
    if (this.cnpjFundo) {
      salvar = this.fundoInvestimentoService.atualizar(dadosFundo);
    } else {
      salvar = this.fundoInvestimentoService.cadastro(dadosFundo);
    }
    this.uiService.abrirDialogLoading(salvar, 'Salvando fundo...').subscribe(
      result => {
        if (result) {
          this.router.navigate(['fundo-investimento']);
        }
      }
    );
  }

  atualizarForm(fundo: FundoInvestimento) {

    const obj = {
      cnpj: fundo.fidados['cnpj'],
      codigo: fundo.codigoFundo,
      origem: fundo.origem,
      prazoResgate: fundo.prazoResgate,
      taxaAdm: fundo.taxaADM,
      taxaBenchmark: fundo.benchTaxa,
      benchmark: fundo.idBench,
      tipo1: fundo.tipoI,
      tipo2: fundo.tipoII,
      grauRisco: fundo.grauRisco,
      prazo:fundo.prazo !=null ? fundo.prazo.toString() : null,
      consultoria: {
        nome: fundo.consultoriaNome,
        cnpj: fundo.consultoriaCNPJ,
        dataInicio: fundo.consultoriaDataInicio,
        patrimonio: fundo.consultoriaPatrimonio,
      },
      custodiante: {
        nome: fundo.custodianteNome,
        cnpj: fundo.custodianteCNPJ,
        dataInicio: fundo.custodianteDataInicio,
        patrimonio: fundo.custodiantePatrimonio,
      },
      corretora: {
        nome: fundo.corretoraNome,
        cnpj: fundo.corretoraCNPJ,
        dataInicio: fundo.corretoraDataInicio,
        patrimonio: fundo.corretoraPatrimonio,
      },
      distribuidor: {
        nome: fundo.distribuidorNome,
        cnpj: fundo.distribuidorCNPJ,
        dataInicio: fundo.distribuidorDataInicio,
        patrimonio: fundo.distribuidorPatrimonio,
      },
      administrador: {
        nome: fundo.fidados.adminNome,
        cnpj: fundo.fidados.adminCNPJ,
        dataInicio: fundo.fidados.adminDataInicio,
        patrimonio: fundo.fidados.adminPatrimonioLiquido,
      },
      gestor: {
        nome: fundo.fidados.gestorNome,
        cnpj: fundo.fidados.gestorCNPJ,
        dataInicio: fundo.fidados.gestorDataInicio,
        patrimonio: fundo.fidados.gestorPatrimonioLiquido,
      },
      nome: fundo.fidados.nome,
      dataInicio: fundo.fidados.dataInicio,
      patrimonioLiquido: fundo.fidados.patrimonioLiquido,
      segmento: fundo.fidados.classe,

    };
    this.form.patchValue(obj);


    console.log('prazo',this.form.get("grauRisco"));
    // this.form.get('cnpj').disable();
    /*if (obj.origem === 'C') {
      // this.form.get('gestor').disable();
      // this.form.get('administrador').disable();
      this.form.get('nome').disable();
      this.form.get('patrimonioLiquido').disable();
      this.form.get('segmento').disable();
      this.form.get('dataInicio').disable();
    }*/
  }
}
