<h1 class="title">Retorno Anual {{ complementoTitulo }}</h1>
<form>
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="dp"
      placeholder="Ano"
      [formControl]="date"
      [min]="minDate"
      [max]="maxDate"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event, dp)"
      panelClass="example-month-picker"

    >
    </mat-datepicker>
  </mat-form-field>

  <label class="hidePrint" style="margin-left: 0.5em;">Zoom:</label>
  <mat-slider
    class="hidePrint"
    min="0.5"
    max="1"
    step="0.01"
    value="1"
    [formControl]="zoom"
    color="primary"
  ></mat-slider>
  <button
    class="hidePrint"
    (click)="print()"
    mat-flat-button
    color="primary"
    style="float: right;"
  >
    <i class="fas fa-print"></i>Imprimir
  </button>
</form>

<table [style.zoom]="zoom.value" mat-table [dataSource]="registros">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

  <ng-container matColumnDef="cnpj">
    <th mat-header-cell *matHeaderCellDef style="width: 14%;">CNPJ fundo</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label" style="width: 9%;">CNPJ fundo:</span>
      {{ retorno.cnpj }}
    </td>
  </ng-container>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef style="width: 30%;">Nome fundo</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label" style="width: 9%;">Nome fundo:</span>
      {{ retorno.nomeFundo }}
    </td>
  </ng-container>
  <ng-container matColumnDef="saldoAnterior">
    <th mat-header-cell *matHeaderCellDef>Saldo inicial</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label" style="width: 12%;">Saldo inicial:</span>
      {{ retorno.saldoInicial || 0 | currency: "BRL":"symbol":"1.2-6" }}
      <button
        *ngIf="retorno.dataSaldoInicial"
        mat-icon-button
        color="primary"
        matTooltip="{{ retorno.dataSaldoInicial }}"
      >
        <mat-icon>info</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="aplicacoes">
    <th mat-header-cell *matHeaderCellDef>Aplicações</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label" style="width: 9%;">Aplicações:</span>
      {{ retorno.aplicacoes || 0 | currency: "BRL":"symbol":"1.2-6" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="resgates">
    <th mat-header-cell *matHeaderCellDef style="width: 9%;">Resgates</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Resgates:</span>
      {{ retorno.resgates || 0 | currency: "BRL":"symbol":"1.2-6" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="saldoAtual">
    <th mat-header-cell *matHeaderCellDef style="width: 12%;">Saldo final</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Saldo finalk:</span>
      {{ retorno.saldoFinal || 0 | currency: "BRL":"symbol":"1.2-6" }}
      <button
        mat-icon-button
        color="primary"
        [matTooltip]="retorno.dataSaldoFinal | date: 'dd/MM/yyyy'"
      >
        <mat-icon>info</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="retorno">
    <th mat-header-cell *matHeaderCellDef style="width: 9%;">Retorno R$</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Retorno R$:</span>
      {{ retorno.rentabilidade || 0 | currency: "BRL":"symbol":"1.2-6" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="percentualRetorno">
    <th mat-header-cell *matHeaderCellDef style="width: 9%;">Retorno %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Retorno %:</span>
      {{ retorno.efetivo | percent: "1.4-4" }}
    </td>
  </ng-container>

</table>

<br />
