import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Credenciamento, FundosAderencia, Papel } from '../_models/credenciamento.model';
import { Page, PageRequest } from '../_models/PaginatedResult';
import { Rating } from '../_models/rating.model';

export interface CredenciamentoQuery{
  search: string
}

@Injectable({
  providedIn: 'root'
})
export class CredenciamentoService {

  constructor(private http: HttpClient) { }

  listarPaginado(request: PageRequest<Credenciamento>, query: CredenciamentoQuery,id?:string):Observable<Page<Credenciamento>> {
    const url = `${environment.PATH_API}/credenciamento`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append('cnpj', query.search);
    }

    if(id!=null){
      params = params.append('idCliente', id);
    }
    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<Credenciamento>>(url, {params: params});
  }


  getPapeis():Observable<Papel[]>{
    const url = `${environment.PATH_API}/credenciamento/papeis`;

    return this.http.get<Papel[]>(url);
  }



  getRatings():Observable<Rating[]>{
    const url = `${environment.PATH_API}/credenciamento/ratings`;

    return this.http.get<Rating[]>(url);
  }


  getRetornoAcumulado(idCliente:number,cnpjAdmin:string,cnpjGestor:string,simulacao:number):Observable<FundosAderencia[]>{
    const url = `${environment.PATH_API}/credenciamento/aderencia`;
    let params = new HttpParams();
    params = params.append('cnpjAdmin', cnpjAdmin);
    params = params.append('cnpjGestor', cnpjGestor);

    console.log(simulacao);
    if(simulacao){
      params = params.append('simulacao', simulacao.toString());
    }

    if(idCliente){
      params = params.append('id', idCliente.toString());
    }

    const retorno =  this.http.get<FundosAderencia[]>(url,{params:params});

    return retorno;

  }


  salvarCredenciamento(obj){
    const url = `${environment.PATH_API}/credenciamento`;
    return this.http.post<Credenciamento>(url, obj);
  }

  getCredenciamentoById(idCredenciamento){
    const url = `${environment.PATH_API}/credenciamento/${idCredenciamento}`;
    return this.http.get<Credenciamento>(url);
  }

  getFundosCredenciamentoById(idCredenciamento){
    const url = `${environment.PATH_API}/credenciamento/fundos/${idCredenciamento}`;
    return this.http.get<FundosAderencia[]>(url);
  }

  removerCredenciamento(id: Number) {
    const url = `${environment.PATH_API}/credenciamento/${id}`;
    return this.http.delete(url);
  }
}

