import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subject } from 'rxjs';
import { AnbimaService } from 'src/app/_services/anbima.service';
import { BlackListService } from 'src/app/_services/blacklist.service';
import { xml2js } from 'xml-js';
import { LoaderComponent } from '../Loader/loader.component';

enum TipoArquivo {
  ACOES = 'A',
  MULTIMERCADO = 'M',
  RENDA_FIXA= 'RF'
}

interface FileWithContent {
  filename: string;
  content: RegistroAnbima;
  tipoArquivo: TipoArquivo;
}


interface Header {
  isin: string;
  cnpj: string;
  nome: string;
  dtposicao: string;
  data: Date;
  nomeAdm: string;
  cnpjAdmin: string;
  nomeGestor: string;
  cnpjGestor: string;
  nomeCustodiante: string;
  cnpjCustodiante: string;
  valorCota: number;
  quantidade: number;
  patrimonioLiquido: number;
  valorAtivos: number;
  valorReceber: number;
  valorPagar: number;
  valorCotasEmitir: number;
  valorCotasResgatar: number;
  tipoFundo: number;
}

export interface TituloPublico{
  isin: string;
  codAtivo: string;
  dtEmissao: string;
  dtOperacao: string;
  dtVencimento: string;
  dtVencimentoDate: Date;
  qtdDisponivel: number;
  qtGarantia: number;
  puCompra: number;
  puVencimento: number;
  puPosicao: number;
  puEmissao: number;
  principal: number;
  tributos: number;
  valorFinDisp: number;
  valorFinemGar: number;
  coupom: number;
  indexador: string;
  percindex: string;
  caracteristica: string;
  percProvCred: number;
  classeOperacao: string;
  idInternoAtivo: number;
  nivelRsc: number;
}

export interface Acao{
  isin: string;
  codAtivo: number;
  qtDisponivel: number;
  lote: string;
  qtGarantia: number;
  valorFinDisp: number;
  valorFinemGar: number;
  tributos: number;
  puPosicao: number;
  percProvCred: number;
  tipoConta: number;
  classeOperacao: string;
}

interface Despesa {
  txAdm: number;
  tributos: number;
  percTaxaAdm: number;
  txPerformance: number;
  valorTxPerformance: number;
  percIndex: number;
  outTax: number;
}

export interface Provisao {
  codProvisao: number;
  tipo: string;
  data: string;
  valor: number;
}

interface Caixa {
  isinInstituicao: string;
  tipoConta: string;
  saldo: number;
  nivelRsc: string;
}

interface OutrasDespesas {
  coddesp: string;
  valor: number;
}
interface Cota {
  isin: string;
  cnpjFundo:string;
  qtdisponivel:number;
  qtgarantia:number;
  puposicao:number;
  tributos:number;
  nivelrsc:string;
}

interface Debenture {
  isin: string;
  coddeb:string;
  debconv:string;
  debpartlucro:string;
  SPE:string;
  cusip:string;
  dtemissao:string;
  dtoperacao:string;
  dtvencimento:string;
  dtvencimentoDate?:Date;
  cnpjemissor:string;
  qtdisponivel:number;
  qtgarantia:number;
  depgar:number;
  pucompra:number;
  puvencimento:number;
  puposicao:number;
  puemissao:number;
  principal:number;
  tributos:number;
  valorfindisp:number;
  valorfinemgar:number;
  coupom:number;
  indexador:string;
  percindex:number;
  caracteristica:string;
  percprovcred:number;
  classeoperacao:string;
  idinternoativo:number;
  nivelrsc:string;
}


interface TituloPrivado {
  isin: string;
  idinternoativo:string,
  cnpjEmissor:string,
  codativo:string,
  cusip:string,
  dtemissao:string,
  dtoperacao:string,
  dtvencimento:string,
  dtvencimentoDate:Date,
  qtdisponivel:number,
  qtgarantia:number,
  depgar:number,
  pucompra:number,
  puvencimento:number,
  puposicao:number,
  puemissao:number,
  principal:number,
  tributos:number,
  valorfindisp:number,
  valorfinemgar:number,
  coupom:number,
  indexador:number,
  percindex:number,
  caracteristica:string,
  percprovcred:number,
  classeoperacao:string,
  nivelrsc:string
}
export interface RegistroAnbima {
  header: Header;
  tituloPublico: TituloPublico[],
  tituloPublicoAgrupado?: TituloPublico[],
  tituloPrivado:TituloPrivado[],
  tituloPrivadoAgrupado?:TituloPrivado[],
  caixa: Caixa;
  despesas: Despesa
  acoes: Acao[];
  outrasDespesas: OutrasDespesas[];
  provisoes: Provisao[];
  cotas:Cota[];
  debenture:Debenture[];
  debentureAgrupado?:Debenture[];
}



interface RegistrosPorTipo {
  rendaFixa: RegistroAnbima[],
  acoes: RegistroAnbima[],
  multimercado: RegistroAnbima[]
}


@Component({
  selector: 'app-compliance-anbima',
  templateUrl: './compliance-anbima.component.html',
  styleUrls: ['./compliance-anbima.component.scss']
})
export class ComplianceAnbimaComponent implements OnInit {

  public files: NgxFileDropEntry[] = [];

  contents: FileWithContent[] = [];
  _contents$: Subject<FileWithContent> = new Subject();

  totalPatrimonioLiquido = 0;
  totalRendaFixa = 0;
  totalAcoes  = 0;
  totalMultimercado = 0;
  totalQtdCotas = 0;

  percentualLimiteRendaFixa = 0.65;
  percentualLimiteAcoes = 0.25;
  percentualLimiteMultimercado = 0.10;

  tipoArquivo = TipoArquivo;

  arquivos: RegistrosPorTipo = {
    rendaFixa: [],
    multimercado: [],
    acoes: []
  }

  consolidada: Partial<RegistroAnbima>;

  cvmBlackList = []
  baixoRiscoBlackList = []

  questao_1="Não";
  items_questao_1=[];
  questao_2="Não";
  questao_3="Não";
  items_questao_3=[];
  questao_4="Não";
  items_questao_4=[];
  questao_5="Não";
  items_questao_5=[];

  codigos_questao_3 = ["CDC","CPA","CRI","CRA","COE","PCT"];

  values_5 = [];
  registros4 = [];
  values_1 = [];
  //não considerar isin do header
  //considerar todos os outros

  is_loaded_questao_5 = false;

  constructor(
    private anbimaService: AnbimaService,
    private blackListService:BlackListService,
    private dialog: MatDialog
    ) {
      this.loadBLBaixoRisco();
      this.loadAnbimaFile();
      this.loadCVM();
    }

    loadBLBaixoRisco(){
      let dialogRef: MatDialogRef<LoaderComponent> = this.dialog.open(LoaderComponent, {
        panelClass: 'transparent',
        disableClose: true
      });
      this.blackListService.listarBaixoRisco().subscribe(dados=> {
        this.values_5 = dados.map(m=>m.value)
        this.is_loaded_questao_5=true;
        dialogRef.close();
      });

    }

    loadCVM(){
      let dialogRef: MatDialogRef<LoaderComponent> = this.dialog.open(LoaderComponent, {
        panelClass: 'transparent',
        disableClose: true
      });
      this.blackListService.listarCVM().subscribe(dados => {
        this.values_1 = dados.map(x=>x.value);
        dialogRef.close();
      });

    }


    loadAnbimaFile () {
      let dialogRef3: MatDialogRef<LoaderComponent> = this.dialog.open(LoaderComponent, {
        panelClass: 'transparent',
        disableClose: true
      });
      this.anbimaService.fetchListaCodigos().then(response => {
            this.registros4 = this.anbimaService.registros.map(x=>{

            if(x.descricao.toString().toLowerCase().includes('subor') || x.descricao.toString().toLowerCase().includes('meza')){
              return x.isin;
            }

        });
        dialogRef3.close();
      })
    }

  ngOnInit() {
    if(this.is_loaded_questao_5){
      this.loadBLBaixoRisco();
    }

    this._contents$
    .subscribe(item => {
      this.contents.push(item);


      /* PATRIMONIO LIQUIDO */
      this.totalPatrimonioLiquido = this.contents.reduce((acc, item) => acc + item.content.header.patrimonioLiquido, 0);

      /* SPLIT ARQUIVOS POR TIPO */
      this.arquivos.acoes = [...this.contents.filter(c => c.tipoArquivo === TipoArquivo.ACOES).map(c => c.content)];
      this.arquivos.multimercado = [...this.contents.filter(c => c.tipoArquivo === TipoArquivo.MULTIMERCADO).map(c => c.content)];
      this.arquivos.rendaFixa = [...this.contents.filter(c => c.tipoArquivo === TipoArquivo.RENDA_FIXA).map(c => c.content)];

      this.consolidada = [...this.contents].reduce((obj: RegistroAnbima, item) => {
        if(!obj) {
          return item.content
        }
        return {
          ...obj,
          acoes: [...obj.acoes, ...item.content.acoes],
          tituloPublico: [...obj.tituloPublico, ...item.content.tituloPublico],
          provisoes: [...obj.provisoes, ...item.content.provisoes],
          outrasDespesas: [...obj.outrasDespesas, ...item.content.outrasDespesas],
          caixa: {
            ...obj.caixa,
            saldo: obj.caixa.saldo + item.content.caixa.saldo
          },
          header: {
            ...obj.header,
            patrimonioLiquido:  obj.header.patrimonioLiquido + item.content.header.patrimonioLiquido
          },
        }
      }, null)

      this.consolidada.tituloPublicoAgrupado = this.consolidada.tituloPublico.reduce(this.fnAgruparTituloPublico, []);

      this.consolidada.tituloPrivadoAgrupado = this.consolidada.tituloPrivado.reduce(this.fnAgruparTituloPrivadoo, []);


      this.arquivos.rendaFixa.forEach(arquivo => {
        arquivo.tituloPublicoAgrupado = arquivo.tituloPublico.reduce(this.fnAgruparTituloPublico, [])
        arquivo.tituloPrivadoAgrupado = arquivo.tituloPrivado.reduce(this.fnAgruparTituloPrivadoo, [])
        arquivo.debentureAgrupado = arquivo.debenture.reduce(this.fnAgruparDebenture, [])

      })

      this.arquivos.acoes.forEach(arquivo => {
        arquivo.tituloPublicoAgrupado = arquivo.tituloPublico.reduce(this.fnAgruparTituloPublico, [])
        arquivo.tituloPrivadoAgrupado = arquivo.tituloPrivado.reduce(this.fnAgruparTituloPrivadoo, [])
        arquivo.debentureAgrupado = arquivo.debenture.reduce(this.fnAgruparDebenture, [])
      })

      this.arquivos.multimercado.forEach(arquivo => {
        arquivo.tituloPublicoAgrupado = arquivo.tituloPublico.reduce(this.fnAgruparTituloPublico, [])
        arquivo.tituloPrivadoAgrupado = arquivo.tituloPrivado.reduce(this.fnAgruparTituloPrivadoo, [])
        arquivo.debentureAgrupado = arquivo.debenture.reduce(this.fnAgruparDebenture, [])
      })

      /* TOTALIIZADORES */
      this.totalMultimercado = this.arquivos.multimercado.reduce((acc, item) => {
          return acc + item.header.patrimonioLiquido
      }, 0);

      this.totalAcoes = this.arquivos.acoes.reduce((acc, item) => {
          return acc + item.header.patrimonioLiquido
      }, 0);

      this.totalRendaFixa = this.arquivos.rendaFixa.reduce((acc, item) => {
          return acc + item.header.patrimonioLiquido
      }, 0);


      this.arquivos.rendaFixa.map(item => {

        item.acoes.map(acao => {
          if(acao.isin){
            if(this.values_1.includes(acao.isin.substr(2,4))){
              this.items_questao_1.push(acao.codAtivo)
            }
            if(this.registros4.includes(acao.isin)){
              console.log(acao.isin)
              this.items_questao_4.push(acao.codAtivo)
            }
            if(this.codigos_questao_3.includes(acao.isin.substr(6,3))){
              this.items_questao_3.push(acao.codAtivo);
            }

            if(this.values_5.includes(acao.isin.substr(2,4))){
              this.items_questao_5.push(acao.codAtivo)
            }
          }
        })

        item.tituloPublico.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.codAtivo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.codAtivo)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.codAtivo);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.codAtivo)
            }
          }
        })

        item.tituloPrivado.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.idinternoativo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.idinternoativo)
            }

            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.idinternoativo);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.idinternoativo)
            }
          }
        })

        item.cotas.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.cnpjFundo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.cnpjFundo)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.cnpjFundo);
            }

            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.cnpjFundo)
            }
          }
        })
        item.debenture.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.cnpjemissor)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.cnpjemissor)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.cnpjemissor);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.cnpjemissor)
            }
          }
        })
      })

      this.arquivos.multimercado.map(item => {

        item.acoes.map(acao => {
          if(acao.isin){
            if(this.values_1.includes(acao.isin.substr(2,4))){
              this.items_questao_1.push(acao.codAtivo)
            }
            if(this.registros4.includes(acao.isin)){
              console.log(acao.isin)
              this.items_questao_4.push(acao.codAtivo)
            }
            if(this.codigos_questao_3.includes(acao.isin.substr(6,3))){
              this.items_questao_3.push(acao.codAtivo);
            }

            if(this.values_5.includes(acao.isin.substr(2,4))){
              this.items_questao_5.push(acao.codAtivo)
            }
          }
        })

        item.tituloPublico.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.codAtivo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.codAtivo)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.codAtivo);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.codAtivo)
            }
          }
        })

        item.tituloPrivado.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.idinternoativo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.idinternoativo)
            }

            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.idinternoativo);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.idinternoativo)
            }
          }
        })

        item.cotas.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.cnpjFundo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.cnpjFundo)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.cnpjFundo);
            }

            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.cnpjFundo)
            }
          }
        })
        item.debenture.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.cnpjemissor)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.cnpjemissor)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.cnpjemissor);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.cnpjemissor)
            }
          }
        })
      })

      this.arquivos.acoes.map(item => {

        item.acoes.map(acao => {
          if(acao.isin){
            if(this.values_1.includes(acao.isin.substr(2,4))){
              this.items_questao_1.push(acao.codAtivo)
            }
            if(this.registros4.includes(acao.isin)){
              console.log(acao.isin)
              this.items_questao_4.push(acao.codAtivo)
            }
            if(this.codigos_questao_3.includes(acao.isin.substr(6,3))){
              this.items_questao_3.push(acao.codAtivo);
            }

            if(this.values_5.includes(acao.isin.substr(2,4))){
              this.items_questao_5.push(acao.codAtivo)
            }
          }
        })

        item.tituloPublico.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.codAtivo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.codAtivo)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.codAtivo);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.codAtivo)
            }
          }
        })

        item.tituloPrivado.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.idinternoativo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.idinternoativo)
            }

            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.idinternoativo);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.idinternoativo)
            }
          }
        })

        item.cotas.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.cnpjFundo)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.cnpjFundo)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.cnpjFundo);
            }

            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.cnpjFundo)
            }
          }
        })
        item.debenture.map(item => {
          if(item.isin){
            if(this.values_1.includes(item.isin.substr(2,4))){
              this.items_questao_1.push(item.cnpjemissor)
            }
            if(this.registros4.includes(item.isin)){
              console.log(item.isin)
              this.items_questao_4.push(item.cnpjemissor)
            }
            if(this.codigos_questao_3.includes(item.isin.substr(6,3))){
              this.items_questao_3.push(item.cnpjemissor);
            }
            if(this.values_5.includes(item.isin.substr(2,4))){
              this.items_questao_5.push(item.cnpjemissor)
            }
          }
        })

      })

      if(this.items_questao_1.length>0){
        this.questao_1="Sim";
      }


      if(this.items_questao_4.length>0){
        console.log(this.items_questao_4);
        this.questao_4="Sim";
      }


      if(this.items_questao_5.length>0){
        this.questao_5="Sim";
      }


      if(this.items_questao_3.length>0){
        this.questao_3="Sim";
      }


    })
  }

  public dropped(files: NgxFileDropEntry[], tipoArquivo: TipoArquivo) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if(file.type === 'text/xml') {
            let reader = new FileReader();
            reader.onload = () => {
              const parsed = xml2js(reader.result.toString(), { compact: true });
              const xmlContent = parsed['arquivoposicao_4_01'];

              const header: Header = {
                isin: xmlContent['fundo']['header']['isin']['_text'],
                cnpj: xmlContent['fundo']['header']['cnpj']['_text'],
                nome: xmlContent['fundo']['header']['nome']['_text'],
                dtposicao: xmlContent['fundo']['header']['dtposicao']['_text'],
                data: moment(xmlContent['fundo']['header']['dtposicao']['_text'], 'YYYYMMDD').toDate(),
                nomeAdm: xmlContent['fundo']['header']['nomeadm']['_text'],
                cnpjAdmin: xmlContent['fundo']['header']['cnpjadm']['_text'],
                nomeGestor: xmlContent['fundo']['header']['nomegestor']['_text'],
                cnpjGestor: xmlContent['fundo']['header']['cnpjgestor']['_text'],
                nomeCustodiante: xmlContent['fundo']['header']['nomecustodiante']['_text'],
                cnpjCustodiante: xmlContent['fundo']['header']['cnpjcustodiante']['_text'],
                valorCota: +xmlContent['fundo']['header']['valorcota']['_text'],
                quantidade: +xmlContent['fundo']['header']['quantidade']['_text'],
                patrimonioLiquido: +xmlContent['fundo']['header']['patliq']['_text'],
                valorAtivos: +xmlContent['fundo']['header']['valorativos']['_text'],
                valorReceber: +xmlContent['fundo']['header']['valorreceber']['_text'],
                valorPagar: +xmlContent['fundo']['header']['valorpagar']['_text'],
                valorCotasEmitir: +xmlContent['fundo']['header']['vlcotasemitir']['_text'],
                valorCotasResgatar: +xmlContent['fundo']['header']['vlcotasresgatar']['_text'],
                tipoFundo: xmlContent['fundo']['header']['tipofundo']['_text'],
              }

              let tituloPublico = []
              if(xmlContent['fundo']['titpublico']) {
                if(!Array.isArray(xmlContent['fundo']['titpublico'])) {
                  xmlContent['fundo']['titpublico'] = [xmlContent['fundo']['titpublico']]
                }

                tituloPublico = xmlContent['fundo']['titpublico'].map((reg: any) => {
                  return {
                    isin: reg['isin']['_text'],
                    codAtivo: reg['codativo']['_text'],
                    dtEmissao: reg['dtemissao']['_text'],
                    dtOperacao: reg['dtoperacao']['_text'],
                    dtVencimento: reg['dtvencimento']['_text'],
                    qtdDisponivel: +reg['qtdisponivel']['_text'],
                    qtGarantia: +reg['qtgarantia']['_text'],
                    puCompra: +reg['pucompra']['_text'],
                    puVencimento: +reg['puvencimento']['_text'],
                    puPosicao: +reg['puposicao']['_text'],
                    puEmissao: +reg['puemissao']['_text'],
                    principal: +reg['principal']['_text'],
                    tributos: +reg['tributos']['_text'],
                    valorFinDisp: +reg['valorfindisp']['_text'],
                    valorFinemGar: +reg['valorfinemgar']['_text'],
                    coupom: +reg['coupom']['_text'],
                    indexador: reg['indexador']['_text'],
                    percindex: reg['percindex']['_text'],
                    percProvCred: reg['percprovcred']['_text'],
                    classeOperacao: reg['classeoperacao']['_text'],
                    idInternoAtivo: reg['idinternoativo']['_text'],
                    nivelRsc: reg['nivelrsc']['_text'],
                    caracteristica: reg['caracteristica']['_text'],
                  }
                })
              }


              let tituloPrivado = []
              if(xmlContent['fundo']['titprivado']) {
                if(!Array.isArray(xmlContent['fundo']['titprivado'])) {
                  xmlContent['fundo']['titprivado'] = [xmlContent['fundo']['titprivado']]
                }

                tituloPrivado = xmlContent['fundo']['titprivado'].map((reg: any) => {
                  return {
                    isin: reg['isin']['_text'],
                    idInternoAtivo: reg['idinternoativo']['_text'],
                    codativo:reg['codativo']['_text'],
                    cusip:reg['cusip']['_text'],
                    dtemissao:reg['dtemissao']['_text'],
                    dtoperacao:reg['dtoperacao']['_text'],
                    dtvencimento:reg['dtvencimento']['_text'],
                    qtdisponivel:+reg['qtdisponivel']['_text'],
                    qtgarantia:+reg['qtgarantia']['_text'],
                    depgar:+reg['depgar']['_text'],
                    pucompra:+reg['pucompra']['_text'],
                    puvencimento:+reg['puvencimento']['_text'],
                    puposicao:+reg['puposicao']['_text'],
                    puemissao:+reg['puemissao']['_text'],
                    principal:+reg['principal']['_text'],
                    tributos:+reg['tributos']['_text'],
                    valorfindisp:+reg['valorfindisp']['_text'],
                    valorfinemgar:+reg['valorfinemgar']['_text'],
                    coupom:+reg['coupom']['_text'],
                    indexador:+reg['indexador']['_text'],
                    percindex:+reg['percindex']['_text'],
                    caracteristica:reg['caracteristica']['_text'],
                    percprovcred:+reg['percprovcred']['_text'],
                    classeoperacao:reg['classeoperacao']['_text'],
                    nivelrsc:+reg['nivelrsc']['_text']
                  }
                })
              }

              let acoes = [];
              if(xmlContent['fundo']['acoes']) {
                if(!Array.isArray(xmlContent['fundo']['acoes'])) {
                  xmlContent['fundo']['acoes'] = [xmlContent['fundo']['acoes']]
                }

                acoes = xmlContent['fundo']['acoes'].map((reg: any) => {
                  const acao: Acao = {
                    isin: reg['isin']["_text"],
                    codAtivo: reg['codativo']["_text"],
                    qtDisponivel: reg['qtdisponivel']["_text"],
                    lote: reg['lote']["_text"],
                    qtGarantia: +reg['qtgarantia']["_text"],
                    valorFinDisp: +reg['valorfindisp']["_text"],
                    valorFinemGar: +reg['valorfinemgar']["_text"],
                    tributos: +reg['tributos']["_text"],
                    puPosicao: +reg['puposicao']["_text"],
                    percProvCred: +reg['percprovcred']["_text"],
                    tipoConta: reg['tpconta']["_text"],
                    classeOperacao: reg['classeoperacao']["_text"],
                  }
                  return acao;
                })
              }

              let provisoes = []
              if(xmlContent['fundo']['provisao']){
                if(!Array.isArray(xmlContent['fundo']['provisao'])) {
                  xmlContent['fundo']['provisao'] = [xmlContent['fundo']['provisao']];
                }
                provisoes = xmlContent['fundo']['provisao'].map((reg: any) => {
                  const provisao: Provisao = {
                    codProvisao: reg['codprov']["_text"],
                    tipo: reg['credeb']["_text"],
                    data: reg['dt']["_text"],
                    valor: +reg['valor']["_text"],
                  }
                  return provisao;
                })
              }


              let outrasDespesas = []
              if(xmlContent['fundo']['outrasdespesas']) {
                if(!Array.isArray(xmlContent['fundo']['outrasdespesas'])) {
                  xmlContent['fundo']['outrasdespesas'] = [xmlContent['fundo']['outrasdespesas']]
                }
                outrasDespesas = xmlContent['fundo']['outrasdespesas'].map((reg: any) => {
                  const outraDespesa: OutrasDespesas = {
                    coddesp: reg['coddesp']["_text"],
                    valor: +reg['valor']["_text"],
                  }
                  return outraDespesa;
                })
              }

              const caixa: Caixa = {
                isinInstituicao: xmlContent['fundo']['caixa']['isininstituicao']['_text'],
                nivelRsc: xmlContent['fundo']['caixa']['nivelrsc']['_text'],
                saldo: +xmlContent['fundo']['caixa']['saldo']['_text'],
                tipoConta: xmlContent['fundo']['caixa']['tpconta']['_text'],
              }


              let despesa:Despesa;
              if(xmlContent['fundo']['despesas']){
                despesa =  {
                  outTax: +xmlContent['fundo']['despesas']['outtax']['_text'],
                  percIndex: +xmlContent['fundo']['despesas']['percindex']['_text'],
                  percTaxaAdm: +xmlContent['fundo']['despesas']['perctaxaadm']['_text'],
                  txPerformance: +xmlContent['fundo']['despesas']['perctxperf']['_text'],
                  tributos: +xmlContent['fundo']['despesas']['tributos']['_text'],
                  txAdm: xmlContent['fundo']['despesas']['txadm']['_text'],
                  valorTxPerformance: xmlContent['fundo']['despesas']['vltxperf']['_text'],
                }
              }

              let cotas = [];
              if(xmlContent['fundo']['cotas']) {
                if(!Array.isArray(xmlContent['fundo']['cotas'])) {
                  xmlContent['fundo']['cotas'] = [xmlContent['fundo']['cotas']]
                }

                cotas = xmlContent['fundo']['cotas'].map((reg: any) => {
                  const cota: Cota = {
                    isin: reg['isin']["_text"],
                    cnpjFundo: reg['cnpjfundo']["_text"],
                    qtdisponivel: reg['qtdisponivel']["_text"],
                    qtgarantia: +reg['qtgarantia']["_text"],
                    tributos: +reg['tributos']["_text"],
                    puposicao: +reg['puposicao']["_text"],
                    nivelrsc:reg['nivelrsc']["_text"]
                  }
                  return cota;
                })
              }


              let debentures = [];
              if(xmlContent['fundo']['debenture']) {
                if(!Array.isArray(xmlContent['fundo']['debenture'])) {
                  xmlContent['fundo']['debenture'] = [xmlContent['fundo']['debenture']]
                }

                debentures = xmlContent['fundo']['debenture'].map((reg: any) => {
                  const debenture: Debenture = {
                    isin: xmlContent['fundo']['debenture']['isin']?xmlContent['fundo']['debenture']['isin']["_text"]:null,
                    coddeb:xmlContent['fundo']['debenture']['codeb']?xmlContent['fundo']['debenture']['codeb']['_text']:null,
                    debconv:xmlContent['fundo']['debenture']['debconv']?xmlContent['fundo']['debenture']['debconv']['_text']:null,
                    debpartlucro:xmlContent['fundo']['debenture']['debpartlucro']?xmlContent['fundo']['debenture']['debpartlucro']['_text']:null,
                    SPE:xmlContent['fundo']['debenture']['SPE']?xmlContent['fundo']['debenture']['SPE']['_text']:null,
                    cusip:xmlContent['fundo']['debenture']['cusip']?xmlContent['fundo']['debenture']['cusip']['_text']:null,
                    dtemissao:xmlContent['fundo']['debenture']['dtemissao']?xmlContent['fundo']['debenture']['dtemissao']['_text']:null,
                    dtoperacao:xmlContent['fundo']['debenture']['dtoperacao']?xmlContent['fundo']['debenture']['dtoperacao']['_text']:null,
                    dtvencimento:xmlContent['fundo']['debenture']['dtvencimento']?xmlContent['fundo']['debenture']['dtvencimento']['_text']:null,
                    cnpjemissor:xmlContent['fundo']['debenture']['cnpjemissor']?xmlContent['fundo']['debenture']['cnpjemissor']['_text']:null,
                    qtdisponivel:+xmlContent['fundo']['debenture']['qtdisponivel']?+xmlContent['fundo']['debenture']['qtdisponivel']['_text']:null,
                    qtgarantia:+xmlContent['fundo']['debenture']['qtgarantia']?+xmlContent['fundo']['debenture']['qtgarantia']['_text']:null,
                    depgar:+xmlContent['fundo']['debenture']['depgar']?+xmlContent['fundo']['debenture']['depgar']['_text']:null,
                    pucompra:+xmlContent['fundo']['debenture']['pucompra']?+xmlContent['fundo']['debenture']['pucompra']['_text']:null,
                    puvencimento:xmlContent['fundo']['debenture']['puvencimento']?xmlContent['fundo']['debenture']['puvencimento']['_text']:null,
                    puposicao:+xmlContent['fundo']['debenture']['puposicao']?+xmlContent['fundo']['debenture']['puposicao']['_text']:null,
                    puemissao:+xmlContent['fundo']['debenture']['puemissao']?+xmlContent['fundo']['debenture']['puemissao']['_text']:null,
                    principal:+xmlContent['fundo']['debenture']['principal']?+xmlContent['fundo']['debenture']['principal']['_text']:null,
                    tributos:+xmlContent['fundo']['debenture']['tributos']?+xmlContent['fundo']['debenture']['tributos']['_text']:null,
                    valorfindisp:+xmlContent['fundo']['debenture']['valorfindisp']?+xmlContent['fundo']['debenture']['valorfindisp']['_text']:null,
                    valorfinemgar:+xmlContent['fundo']['debenture']['valorfinemgar']?+xmlContent['fundo']['debenture']['valorfinemgar']['_text']:null,
                    coupom:+xmlContent['fundo']['debenture']['coupom']?+xmlContent['fundo']['debenture']['coupom']['_text']:null,
                    indexador:xmlContent['fundo']['debenture']['indexador']?xmlContent['fundo']['debenture']['indexador']['_text']:null,
                    percindex:+xmlContent['fundo']['debenture']['percindex']?+xmlContent['fundo']['debenture']['percindex']['_text']:null,
                    caracteristica:xmlContent['fundo']['debenture']['caracteristica']?xmlContent['fundo']['debenture']['caracteristica']['_text']:null,
                    percprovcred:xmlContent['fundo']['debenture']['percprovcred']?xmlContent['fundo']['debenture']['percprovcred']['_text']:null,
                    classeoperacao:xmlContent['fundo']['debenture']['classeoperacao']?xmlContent['fundo']['debenture']['classeoperacao']['_text']:null,
                    idinternoativo:xmlContent['fundo']['debenture']['idinternoativo']?xmlContent['fundo']['debenture']['idinternoativo']['_text']:null,
                    nivelrsc:xmlContent['fundo']['debenture']['nivelrsc']?xmlContent['fundo']['debenture']['nivelrsc']['_text']:null
                  }
                  return debenture;
                })
              }




              const registro: RegistroAnbima = {
                header,
                caixa,
                outrasDespesas,
                acoes,
                provisoes,
                tituloPublico,
                tituloPrivado:tituloPrivado,
                despesas: despesa,
                cotas:cotas,
                debenture:debentures
              }

              this._contents$.next({ filename: file.name, content: registro, tipoArquivo});
            }
            reader.readAsText(file);
          }
        })
      }
    }
  }

  public fileOver(event){
    console.log(event);
  }

  public fileLeave(event){
    console.log(event);
  }

  filtroMultimercados = (item: Acao) => {
    return item.classeOperacao === 'T'
  }

  filtroAcoes = (item: Acao) => {
    return item.classeOperacao === 'C'
  }

  fnAgruparTituloPublico = (arr:TituloPublico[], item: TituloPublico) => {
    const indexItem = arr.findIndex(s => s.codAtivo === item.codAtivo && s.dtVencimento === item.dtVencimento);
    if(indexItem >= 0) {
      let reg = {...arr[indexItem]}
      reg.valorFinDisp += item.valorFinDisp;
      reg.valorFinemGar += item.valorFinemGar;
      arr.splice(indexItem, 1, reg);
      return [...arr]
    } else {
      item.dtVencimentoDate = moment(item.dtVencimento, 'YYYYMMDD').toDate()
      return [...arr, item]
    }
  };

  fnAgruparTituloPrivadoo = (arr:TituloPrivado[], item: TituloPrivado) => {
    const indexItem = arr.findIndex(s => s.cnpjEmissor === item.cnpjEmissor && s.dtvencimento === item.dtvencimento);
    if(indexItem >= 0) {
      let reg = {...arr[indexItem]}
      reg.valorfindisp += item.valorfindisp;
      reg.valorfinemgar += item.valorfinemgar;
      arr.splice(indexItem, 1, reg);
      return [...arr]
    } else {
      item.dtvencimentoDate = moment(item.dtvencimento, 'YYYYMMDD').toDate()
      return [...arr, item]
    }
  };

  fnAgruparDebenture = (arr:Debenture[], item: Debenture) => {
    const indexItem = arr.findIndex(s => s.cnpjemissor === item.cnpjemissor && s.dtvencimento === item.dtvencimento);
    if(indexItem >= 0) {
      let reg = {...arr[indexItem]}
      reg.valorfindisp += item.valorfindisp;
      reg.valorfinemgar += item.valorfinemgar;
      arr.splice(indexItem, 1, reg);
      return [...arr]
    } else {
      item.dtvencimentoDate = moment(item.dtvencimento, 'YYYYMMDD').toDate()
      return [...arr, item]
    }
  };
}
