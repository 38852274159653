<div *ngIf="loadingFundos">
  <mat-spinner style="margin: 0 auto"></mat-spinner>
</div>
<section *ngIf="!loadingFundos">
  <span *ngIf="!relatorioDinamico">
    <h1 class="title" *ngIf="fromRoute">
      Visão geral da carteira do cliente {{ complementoTitulo }}
    </h1>
    <button
      class="hidePrint"
      style="margin-bottom: 0.5em"
      mat-flat-button
      color="primary"
      [routerLink]="['/clientes', 'carteira', idCliente, 'retorno']"
    >
      Relatório mensal
    </button>
    <button
      class="hidePrint"
      style="margin-bottom: 0.5em; margin-left: 0.5em"
      mat-flat-button
      color="primary"
      [routerLink]="['/clientes', 'carteira', idCliente, 'retorno-acumulado']"
    >
      Relatório acumulado
    </button>
    <button
      class="hidePrint"
      mat-flat-button
      color="primary"
      [routerLink]="['/clientes', 'carteira', idCliente, 'retorno-anual']"
      style="margin-left: 0.5em"
    >
      Relatório anual
    </button>
    <button
      class="hidePrint"
      style="margin-bottom: 0.5em; margin-left: 0.5em"
      mat-flat-button
      color="primary"
      [routerLink]="['/clientes', 'carteira', idCliente, 'enquadramentos']"
    >
      Enquadramentos
    </button>
    <button
      class="hidePrint"
      style="margin-bottom: 0.5em; margin-left: 0.5em"
      mat-flat-button
      color="primary"
      [routerLink]="['/clientes', 'carteira', idCliente, 'retornos-fundos']"
    >
      Retorno dos fundos acumulado
    </button>
    <button
      class="hidePrint"
      mat-flat-button
      color="primary"
      target="_blank"
      (click)="openNew(['/', 'relatorio-dinamico', idCliente])"
      style="margin-left: 0.5em; margin-right: 0.5em"
    >
      Relatório dinâmico
    </button>

    <button
      mat-flat-button
      color="primary"
      target="_blank"
      (click)="openNewExport('https://dinamico.liasys.net', idCliente)"
      style="margin-right: 0.5em; margin-bottom: 0.5em"
    >
      Exportação Relatório dinâmico
    </button>
    <button
      class="hidePrint"
      mat-flat-button
      color="primary"
      target="_blank"
      (click)="openNew(['/', 'lamina-resumo', idCliente])"
      style="margin-left: 0.5em"
    >
      Lâmina resumo
    </button>
    <a
      href="https://www.liasys.net/files/Posicao dos Investimentos Periodicos.pdf"
      target="_blank"
    >
      <button
        class="hidePrint"
        mat-flat-button
        color="primary"
        style="margin-left: 0.5em"
      >
        Posição dos investimentos
      </button>
    </a>

    <br />

    <label style="margin-left: 0.5em" class="hidePrint">Zoom:</label>
    <mat-slider
      class="hidePrint"
      min="0.5"
      max="1"
      step="0.01"
      value="1"
      [formControl]="zoom"
      color="primary"
    ></mat-slider>
    <br />

    <form class="hidePrint">
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="dp"
          [max]="atual"
          placeholder="Mês/Ano"
          [formControl]="date"
          [min]="minDate"
          [max]="maxDate"
        />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker
          #dp
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp)"
          panelClass="example-month-picker"
        >
        </mat-datepicker>
      </mat-form-field>
    </form>
  </span>
  <span *ngIf="!relatorioDinamico">
    <mat-slide-toggle class="hidePrint" [(ngModel)]="checked" color="primary">
      Visão agrupada
    </mat-slide-toggle>
    <div
      style="width: 100%; text-align: right"
      *ngIf="checked && !relatorioDinamico"
    >
      <button
        class="hidePrint"
        (click)="print()"
        mat-flat-button
        color="primary"
        style="margin-right: 5px"
      >
        <i class="fas fa-print"></i>Imprimir
      </button>
    </div>
    <br />
  </span>
  <div *ngIf="checked && !relatorioDinamico">
    <br />

    <br />
    <mat-expansion-panel
      *ngFor="let grupo of agrupamentoPorSegmento"
      [expanded]="printing"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ grupo.tipo }}
        </mat-panel-title>
        <span style="flex: 1"></span>
        <span style="width: 155px; text-align: right">
          {{ grupo.saldoGrupo | currency : "BRL" : "symbol" : "1.2" }}
        </span>
        &nbsp;

        <!--| -->
        <span style="width: 155px; text-align: right">
          &nbsp;

          <!-- {{grupo.saldoGrupo | currency: "BRL":"symbol":"1.2" }} -->
        </span>
      </mat-expansion-panel-header>
      <table
        [style.zoom]="zoom.value"
        mat-table
        [dataSource]="grupo.investimentos"
      >
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

        <ng-container matColumnDef="fundo">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 20%; font-size: 11px"
          >
            Fundo
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Fundo:</span>
            {{ element.nomeFundo }}
            <br />
            <small>
              <span style="font-size: 12px">
                <strong>Banco:</strong> {{ element.banco }}
                <strong>Agência:</strong> {{ element.agencia }}
                <strong>Conta:</strong> {{ element.conta }}
              </span>
              <br />
              <strong *ngIf="!relatorioDinamico"
                >Data do saldo:
                {{ element.dataSaldo | date : "dd/MM/yyyy" }}</strong
              >
            </small>
          </td>
        </ng-container>

        <ng-container matColumnDef="resgate">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 7%; text-align: center"
          >
            Prazo Resgate
          </th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <span class="mobile-label">Prazo Resgate:</span>
            D + {{ element.prazoResgate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tipoFundo">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">Tipo</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Tipo:</span>
            {{ element.tipo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="saldo">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">Saldo</th>
          <td mat-cell *matCellDef="let element">
            <div *appHasRole="[1, 2]">
              <span class="mobile-label">Saldo:</span>
              <div
                style="border-bottom: 2px dotted #ccc; cursor: pointer"
                matTooltip="Clique para ajustar saldo"
                (click)="lancamentoAjusteSaldo(element, element.dataSaldo)"
              >
                {{ element.saldo | currency : "BRL" }}
              </div>
            </div>

            <div *appHasRole="[3, 4]">
              <span class="mobile-label">Saldo:</span>
              {{ element.saldo | currency : "BRL" }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="participacao">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">
            Participação
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Participação:</span>
            {{ element.participacao | percent : "1.2-5" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="percentual-patrimonio">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">
            % sobre o PL
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">% sobre o PL:</span>
            {{ element.percentualPatrimonio | percent : "1.2-5" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="numero-cotistas">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">
            Num. Cotistas
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Num. Cotistas</span>
            {{ element.numCotistas }}
          </td>
        </ng-container>

        <ng-container matColumnDef="patrimonio">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">
            Patrimonio
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Patrimonio:</span>
            {{ element.patrimonioLiquido | currency : "BRL" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="resolucao">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">Resolução</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Resolução:</span>
            {{ element.enquadramentoLegislacao }}
          </td>
        </ng-container>

        <ng-container matColumnDef="grauRisco">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 7%; text-align: center"
          >
            Grau Risco
          </th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <span class="mobile-label">Grau Risco:</span>
            {{ element.grauRisco | grauRisco }}
          </td>
        </ng-container>

        <ng-container matColumnDef="taxaAdm">
          <th mat-header-cell *matHeaderCellDef style="width: 7%">Taxa ADM.</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Taxa ADM.:</span>
            {{ element.taxaAdm / 100 | percent : "1.2-5" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="visualizar" class="hidePrint">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 7%"
            class="hidePrint"
          >
            Ações
          </th>
          <td mat-cell *matCellDef="let element" class="hidePrint">
            <span class="mobile-label">Ações:</span>
            <button
              color="primary"
              mat-icon-button
              (click)="abrirInvestimento(element)"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </ng-container>
      </table>

      <br />
      <h4
        style="text-indent: 1em; text-align: right"
        *ngIf="checked && !relatorioDinamico"
      >
        Total:
        <strong>{{
          grupo.saldoGrupo | currency : "BRL" : "symbol" : "1.2"
        }}</strong>
      </h4>
    </mat-expansion-panel>

    <br />

    <h4 style="text-indent: 1em; text-align: left" *ngIf="!relatorioDinamico">
      Saldo Total da Carteira:
      <strong>{{ saldoTotal | currency : "BRL" : "symbol" : "1.2" }}</strong>
    </h4>

    <br />
    <span style="display: block" class="break-page"></span>

    <br />
  </div>
  <div *ngIf="!checked">
    <table [style.zoom]="zoom.value" mat-table [dataSource]="investimentos">
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
      <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
      <ng-container matColumnDef="fundo">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="width: 20%; font-size: 11px"
        >
          Fundo
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Fundo:</span>
          {{ element.nomeFundo }}
          <br />
          <small>
            <span style="font-size: 12px">
              <strong>Banco:</strong> {{ element.banco }}
              <strong>Agência:</strong> {{ element.agencia }}
              <strong>Conta:</strong> {{ element.conta }}
            </span>
            <br />
            <strong *ngIf="!relatorioDinamico"
              >Data do saldo:
              {{ element.dataSaldo | date : "dd/MM/yyyy" }}</strong
            >
          </small>
        </td>
        <td mat-footer-cell *matFooterCellDef>Total</td>
      </ng-container>

      <ng-container matColumnDef="resgate">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="width: 7%; text-align: center"
        >
          Prazo Resgate
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <span class="mobile-label">Prazo Resgate:</span>
          D + {{ element.prazoResgate }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="tipoFundo">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">Tipo</th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Tipo:</span>
          {{ element.tipo }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="saldo">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">Saldo</th>
        <td mat-cell *matCellDef="let element">
          <div *appHasRole="[1, 2]">
            <span class="mobile-label">Saldo:</span>
            <div
              style="border-bottom: 2px dotted #ccc; cursor: pointer"
              matTooltip="Clique para ajustar saldo"
              (click)="lancamentoAjusteSaldo(element, element.dataSaldo)"
            >
              {{ element.saldo | currency : "BRL" }}
            </div>
          </div>

          <div *appHasRole="[3, 4]">
            <span class="mobile-label">Saldo:</span>
            {{ element.saldo | currency : "BRL" }}
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <strong>{{ saldoTotal | currency : "BRL" }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="participacao">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">
          Participação
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Participação:</span>
          {{ element.participacao | percent : "1.2-5" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="percentual-patrimonio">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">
          % sobre o PL
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">% sobre o PL:</span>
          {{ element.percentualPatrimonio | percent : "1.2-5" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="numero-cotistas">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">
          Num. Cotistas
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Num. Cotistas</span>
          {{ element.numCotistas }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="patrimonio">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">Patrimonio</th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Patrimonio:</span>
          {{ element.patrimonioLiquido | currency : "BRL" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="resolucao">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">Resolução</th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Resolução:</span>
          {{ element.enquadramentoLegislacao }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="grauRisco">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="width: 7%; text-align: center"
        >
          Grau Risco
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <span class="mobile-label">Grau Risco:</span>
          {{ element.grauRisco | grauRisco }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="taxaAdm">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">Taxa ADM.</th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Taxa ADM.:</span>
          {{ element.taxaAdm / 100 | percent : "1.2-5" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="visualizar">
        <th mat-header-cell *matHeaderCellDef style="width: 7%">Ações</th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Ações:</span>
          <button
            color="primary"
            mat-icon-button
            (click)="abrirInvestimento(element)"
          >
            <i class="fas fa-eye"></i>
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </table>

    <div *ngIf="relatorioDinamico && graficoLiquidez" style="display: block">
      <br />
      <table border="1">
        <thead>
          <tr>
            <th>Prazo Resgate</th>
            <th>Quantidade Fundos</th>
            <th>% carteira</th>
            <th>Saldo</th>
            <!-- <th>Aceitável</th>
        <th>Limite em utilização</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of disponibilidadeAgrupada | sort : 'disponibilidade'
            "
          >
            <td style="text-align: center">D + {{ item.disponibilidade }}</td>
            <td style="text-align: center">{{ item.totalFundos }}</td>
            <td style="text-align: center">
              {{ item.percentual | percent : "1.4" }}
            </td>
            <td style="text-align: center">
              {{ item.valorGrupo | currency : "BRL" }}
            </td>
            <!-- <td>{{item.aceitavel | percent:'1.2'}}</td>
        <td>
          {{(item.percentual / item.aceitavel) | percent:'1.4'}}
        </td> -->
          </tr>
        </tbody>
      </table>
      <br />

      <div *ngIf="chartData" [class.widthmax]="relatorioDinamico">
        <canvas
          baseChart
          height="150"
          [datasets]="chartData"
          [labels]="chartLabels"
          [colors]="chartColors"
          [plugins]="barChartPlugins"
          [options]="barChartOptions"
          [legend]="false"
          chartType="bar"
        ></canvas>
      </div>
      <!-- <ngx-charts-bar-vertical-2d
    *ngIf="seriesGrafico"
    [scheme]="colorScheme"
    [animations]="false"
    [results]="seriesGrafico || []"
    [view]="[1000, 600]"
    [xAxis]="true"
    [animations]="false"
    [noBarWhenZero]="false"
    [yAxis]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
  >
  </ngx-charts-bar-vertical-2d> -->

      <!-- <table>
    <td style="width: 50px; background-color: #1fd169;"></td>
    <td>D+0</td>
    <td style="width: 50px; background-color: #01579B;"></td>
    <td>D+1</td>
    <td style="width: 50px; background-color: #fbc02d;"></td>
    <td>D+3</td>
    <td style="width: 50px; background-color: #b7c9bc;"></td>
    <td>D+30</td>
    <td style="width: 50px; background-color: #a0d10f;"></td>
    <td>D+31</td>
    <td style="width: 50px; background-color: #c62828;"></td>
    <td>Indefinido</td>
  </table> -->
      <div class="page"></div>

      <span *ngIf="agrupadoPorEnquadramento" class="page-2">
        <br />
        <br />
        <h2>Risco crédito</h2>
        <br />
        <table
          mat-table
          [dataSource]="
            agrupadoPorEnquadramento | sort : 'enquadramentoLegislacao'
          "
        >
          <tr mat-header-row *matHeaderRowDef="collumnsEnquadramento"></tr>
          <tr
            mat-row
            *matRowDef="let myRowData; columns: collumnsEnquadramento"
          ></tr>

          <ng-container matColumnDef="enquadramentoLegislacao">
            <th mat-header-cell *matHeaderCellDef style="width: 20%">
              Artigos
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-label">Artigos</span>
              {{ element.enquadramentoLegislacao }}
            </td>
          </ng-container>

          <ng-container matColumnDef="limite">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 20%; text-align: center"
            >
              Limite
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center">
              <span class="mobile-label">Limite</span>
              {{ element.limite / 100 | percent : "1.4-4" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="limitePolitica">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 20%; text-align: center"
            >
              Limite Política
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center">
              <span class="mobile-label">Limite Política</span>
              {{
                (cliente?.limitePolitica || element.limite) / 100
                  | percent : "1.4-4"
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="saldo">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 20%; text-align: center"
            >
              Saldo
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center">
              <span class="mobile-label">Saldo</span>
              {{ element.saldoGrupo | currency : "BRL" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="percentualCarteira">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 20%; text-align: center"
            >
              % carteira
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center">
              <span class="mobile-label">% carteira</span>
              {{ element.percentualGrupo | percent : "1.4-4" }}
            </td>
          </ng-container>
        </table>
      </span>

      <br />
      <div class="page">
        <table border="1">
          <thead style="font-weight: bold !important">
            <tr style="font-weight: bold !important">
              <th>Risco crédito</th>
              <th>% carteira</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ agrupamentoPublicoPrivado[0].tipo }}</td>
              <td style="text-align: center">
                {{ agrupamentoPublicoPrivado[0].percentual | percent : "1.4" }}
              </td>
              <td style="text-align: center">
                {{
                  agrupamentoPublicoPrivado[0].percentual * saldoTotal
                    | currency : "BRL"
                }}
              </td>
            </tr>
            <tr>
              <td>{{ agrupamentoPublicoPrivado[1].tipo }}</td>
              <td style="text-align: center">
                {{ agrupamentoPublicoPrivado[1].percentual | percent : "1.4" }}
              </td>
              <td style="text-align: center">
                {{
                  agrupamentoPublicoPrivado[1].percentual * saldoTotal
                    | currency : "BRL"
                }}
              </td>
            </tr>
            <tr>
              <td>{{ agrupamentoPublicoPrivado[2].tipo }}</td>
              <td style="text-align: center">
                {{ agrupamentoPublicoPrivado[2].percentual | percent : "1.4" }}
              </td>
              <td style="text-align: center">
                {{
                  agrupamentoPublicoPrivado[2].percentual * saldoTotal
                    | currency : "BRL"
                }}
              </td>
            </tr>

            <tr>
              <td>Aceitavel carteira Titulos Privados</td>
              <td style="text-align: center" colspan="2">
                {{ 0.3 | percent : "1.2" }}
              </td>
            </tr>
            <tr>
              <td>Limite de Utilização</td>
              <td
                style="text-align: center"
                colspan="2"
                [class.green]="percentualUsolimitePublicoPrivado <= 0.5"
                [class.yellow]="
                  percentualUsolimitePublicoPrivado > 0.5 &&
                  percentualUsolimitePublicoPrivado < 0.75
                "
                [class.yellow]="percentualUsolimitePublicoPrivado >= 0.75"
              >
                {{ percentualUsolimitePublicoPrivado | percent : "1.2" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="chartData" [class.widthmax]="relatorioDinamico">
        <canvas
          baseChart
          height="150"
          [datasets]="chartDataPublicoPrivado"
          [labels]="chartLabelsPublicoPrivado"
          [colors]="chartColors"
          [plugins]="barChartPlugins"
          [options]="barChartOptions"
          [legend]="false"
          chartType="bar"
        ></canvas>
      </div>
      <!-- <ngx-charts-bar-vertical
  *ngIf="dadosGraficoPublicoPrivado"
  [scheme]="colorScheme"
  [animations]="false"
  [results]="dadosGraficoPublicoPrivado || []"
  [view]="[1000, 600]"
  [xAxis]="true"
  [animations]="false"
  [noBarWhenZero]="false"
  [yAxis]="true"
  [showXAxisLabel]="true"
  [showYAxisLabel]="true"
  [trimXAxisTicks]="false"
>
</ngx-charts-bar-vertical> -->
      <!-- <table>
    <td style="width: 50px; background-color: #1fd169;"></td>
    <td>100% Títulos publicos</td>
    <td style="width: 50px; background-color: #01579B;"></td>
    <td>50% Títulos públicos 50% titulos privados</td>
    <td style="width: 50px; background-color: #fbc02d;"></td>
    <td>100% Títulos privados</td>
  </table> -->
    </div>
  </div>
</section>
