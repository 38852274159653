<div id="dialog-delete">
  <div class="title-dialog">
      <mat-icon color="warn">report</mat-icon>
      <h2 mat-dialog-title>Deletar</h2>
  </div>
  <mat-dialog-content>Deseja realmente deletar <span> {{data.name}} </span> ?</mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="warn" mat-dialog-close><i class="fas fa-times"></i>Não</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true"><i class="fas fa-check"></i>Sim</button>
  </mat-dialog-actions>
</div>