import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogLoadingComponent } from 'src/app/_helpers/dialog-loading/dialog-loading.component';
import { UIService } from 'src/app/_helpers/ui.service';
import { DocumentoService } from 'src/app/_services/documento.service';

@Component({
  selector: 'app-criar-documento',
  templateUrl: './criar-documento.component.html',
  styleUrls: ['./criar-documento.component.scss']
})
export class CriarDocumentoComponent implements OnInit {

  file: any;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CriarDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private documentoService: DocumentoService,
    private uiService: UIService,
    private _fb: FormBuilder
  ) {
    this.form = _fb.group({
      titulo: _fb.control('', Validators.required),
      tipo: _fb.control('', Validators.required),
    })
  }

  ngOnInit() {
  }

  changeArquivo(e) {
    this.file = e.target.files[0];
  }

  submitArquivo() {
    if(this.file) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        const value = {
          ...this.form.value,
          arquivo: e.target.result
        }
        const loadingDialog = this.dialog.open(DialogLoadingComponent, {
          data: {
            text: 'Realizando upload do arquivo',
            target: this.documentoService.criarDocumento(value),
            closeOnComplete: true,
            successMessage: 'Documento enviado com sucesso',
            errorMessage: ({error}) => `Erro: ${error.error}`
          }
        });
        loadingDialog.afterClosed().subscribe(result =>{
          if(result) {
            this.dialogRef.close();
          }
        })
      }
      fileReader.readAsDataURL(this.file);
    }
  }

}
