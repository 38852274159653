import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Drei } from '../_models/drei.mode';
import { Page, PageRequest } from '../_models/PaginatedResult';

export interface DreiQuery{
  search: string
}

@Injectable({
  providedIn: 'root'
})
export class DreiService {

  constructor(private http: HttpClient) { }

  listarDreiPaginado(request: PageRequest<Drei>, query: DreiQuery,id?:string):Observable<Page<Drei>> {
    const url = `${environment.PATH_API}/drei`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append('mes', query.search);

    }
    console.log(id);
    if(id != null){
      params = params.append('idCliente',id.toString());
    }
    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<Drei>>(url, {params: params});
  }



  criarDrei(drei:Drei){
    const url = `${environment.PATH_API}/drei`;
    return this.http.post(url, drei);
  }


  exportarXml(drei:Drei){
    const url = `${environment.PATH_API}/drei/xml/${drei.id}`;

    const headers = new HttpHeaders();

    headers.append('Accept', 'text/xml');
    this.http.get(url,{headers,responseType: 'text'}).subscribe(response => {

      let link = document.createElement("a");

      link.setAttribute("href", 'data:application/xml;charset=utf-8,' + encodeURIComponent(response.toString()));

      link.setAttribute('download', `drei_${drei.id}.xml`);

      link.style.display = 'none';
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      }
    );

  }


  deleteDrei(drei:Drei){
    const url = `${environment.PATH_API}/drei/${drei.id}`;
    return this.http.delete(url);
  }


}

