<form class="box" [formGroup]="form" fxLayout="column" fxFlex="70" (ngSubmit)="novoSigner(form.value)">
  <h3 style="margin: 0">Assinadores</h3>
  <h4 *ngIf="editando">Editando assinador</h4>
  <div *appHasRole
  ="[1,2,3]" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    <mat-form-field style="width: 100%;">
      <input matInput type="text" formControlName="nome" placeholder="Nome do assinador">
      <mat-error *ngIf="form.controls['nome'].invalid">Informe o nome do assinador</mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <input matInput type="text" formControlName="cpfCnpj" placeholder="CPF do assinador">
      <mat-error *ngIf="form.controls['cpfCnpj'].invalid">Informe um CPF válido</mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <input matInput type="text" formControlName="certificadora" placeholder="Certificadora">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <input matInput [matDatepicker]="picker" readonly formControlName="validade" placeholder="Validade certificação">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <mat-label>Funções</mat-label>
      <mat-select formControlName="funcoes" multiple>
        <mat-option *ngFor="let options of funcoesOptions" [value]="options">{{options}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls['funcoes'].errors">É permitido no máximo 2 funções por assinador</mat-error>
    </mat-form-field>
    <div class="buttons">
      <button *ngIf="!editando" mat-raised-button color="accent" type="submit" [disabled]="!form.valid"><mat-icon>save</mat-icon>Salvar assinador</button>
      <button *ngIf="editando" mat-raised-button color="accent" type="button" (click)="updateSigner(form.value)" [disabled]="!form.valid"><mat-icon>save</mat-icon>Atualizar assinador</button>
      <button *ngIf="editando" (click)="cancelEdicao()" type="button" mat-raised-button color="warn"><mat-icon>cancel</mat-icon>Cancelar edição</button>
    </div>
  </div>
  <mat-list>
    <mat-list-item *ngFor="let assinador of assinadores; let i = index" class="mat-elevation-z1">
      <h3 matLine> <strong>{{assinador.cpfCnpj}}</strong> </h3>
      <p matLine><b>Nome:</b> {{assinador.nome}}</p>
      <p matLine *ngIf="assinador.certificadora"><b>Certificadora:</b> {{assinador.certificadora}}</p>
      <p matLine *ngIf="assinador.validade"><b>Validade:</b> {{assinador.validade | date:'dd/MM/yyyy' }}</p>
      <p matLine><b>Funções: </b>
        <span *ngIf="assinador.isGestor">
          Gestor
          <span *ngIf="assinador.isProponente">, </span>
        </span>
        <span *ngIf="assinador.isProponente">
          Proponente
          <span *ngIf="assinador.isResponsavel">, </span>
        </span>
        <span *ngIf="assinador.isResponsavel">Responsavel</span>
        <span *ngIf="!(assinador.isResponsavel || assinador.isProponente || assinador.isGestor)">Geral</span>
      </p>
      <button *appHasRole="[1,2,3]" type="button" mat-icon-button color="primary" (click)="editSigner(assinador)" matTooltip="Editar assinador"><i
        class="fas fa-edit"></i></button>
      <button *appHasRole="[1,2,3]" type="button" mat-icon-button color="primary" (click)="removerSigner(assinador.id)" matTooltip="Remover assinador"><i
        class="fas fa-trash"></i></button>
      </mat-list-item>
    </mat-list>
</form>