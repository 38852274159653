import { Component, OnInit } from "@angular/core";
import {
  FormBuilder, FormControl, FormGroup, Validators
} from "@angular/forms";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import { DialogConfirmComponent } from 'src/app/_helpers/dialog-confirm/dialog-confirm.component';
import { UIService } from 'src/app/_helpers/ui.service';
import { Usuario } from 'src/app/_models/user.models';
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { DreiService } from 'src/app/_services/drei.service';
import { MetaService } from 'src/app/_services/meta.service';
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-cadastro-drei",
  templateUrl: "./cadastro-drei.component.html",
  styleUrls: ["./cadastro-drei.component.scss"],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})


export class CadastroDreiComponent implements OnInit {
  TipoUsuario = TipoUsuario;

  date = new FormControl('');

  form: FormGroup;

  dados_calculados:boolean;

  usuario : Usuario;

  valorRentabilidadeAcumulada :number;

  idCliente:string;

  constructor(
    private dreiService:DreiService,
    private carteiraService:CarteiraService,
    private metaServcie : MetaService,
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.form = fb.group({
       mes: ['', Validators.required],
       rentabilidadeAcumulada:[0,Validators.required],
       dataAvaliacao:['',Validators.required],
       metaRentabilidade:[0,Validators.required],
       inflacaoAcumulada:[0,Validators.required]
    });
  }

  ngOnInit() {
    this.idCliente = this.route.snapshot.paramMap.get("idCliente");

    this.authService.usuario$.subscribe(usuario => this.usuario = usuario);

    const periodo = moment().add(-1,'month').toDate();

    let meta = 0;

    this.metaServcie.listarMetas().subscribe(dados => {
      //Ultimo registro de inflacao
      //é aqui mesmo?
      dados.map(m => m.quotes.map(q => meta = q.percentual3));

      this.form.get('inflacaoAcumulada').setValue(meta);
    });

  }

  submit(){

    const obj = {
      ...this.form.value,
      clienteId:this.idCliente != null ? this.idCliente : this.usuario.idCliente,
      idCliente:this.idCliente != null ? this.idCliente : this.usuario.idCliente
    }

    this.dreiService.criarDrei(obj).subscribe(
      response => {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          width: '500px',
          data: {text: 'DREI salvo com sucesso!'}
        });
        dialogRef.afterClosed().subscribe(
          (result) => this.router.navigate(['drei'])
        );
      },
      error => {
        this.uiService.showSnackBar('Erro ao salvar os dados do DREI', null, 4000);
      }
    );
  }


  chosenYearHandler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value || moment();
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.form.patchValue({ mes: ctrlValue.format('MM/YYYY') })
    datepicker.close();
  }

  restrainMonths(d: moment.Moment): boolean {
    if(d) {
      const month = d.month() +1;
      // Prevent Saturday and Sunday from being selected.
      return month % 3 == 0;
    }
    return false
  }
}
