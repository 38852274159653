<div
  style="
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
  "
  *ngIf="!loaded"
>
  <mat-spinner diameter="40" style="margin: 0 auto"></mat-spinner>
  <h4>Carregando...</h4>
</div>
<div style="display: flex; flex-flow: row">
  <form class="hidePrint" style="flex: 1">
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="dp"
        [max]="atual"
        placeholder="Mês/Ano"
        [formControl]="date"
        [min]="minDate"
        [max]="maxDate"
      />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      style="
        flex-grow: 0;
        align-self: center;
        justify-self: center;
        margin-right: 10px;
        margin-left: 15px;
      "
      (click)="filtro()"
    >
      Filtrar
    </button>
    <strong>
      Data do último saldo: {{ ultimaDataSaldo | date : "dd/MM/yyyy" }}
    </strong>
  </form>
  <button class="hidePrint" (click)="print()" mat-flat-button color="primary">
    Imprimir
  </button>
</div>
<div id="report" *ngIf="bacalhau">
  <div class="page">
    <div
      style="
        justify-content: center;
        align-self: center;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 10px;
      "
    >
      <div
        id="col-esquerda"
        style="border-right: 1px solid #999; padding: 10px"
      >
        <img
          src="assets/imagens/logo - Copia.png"
          height="70"
          style="filter: brightness(0%)"
        />
      </div>
      <div id="col-direita" style="padding: 10px">
        <div
          style="
            display: flex;
            flex-direction: row;
            padding-bottom: 15px;
            border-bottom: 1px solid #000;
          "
        >
          <div>
            <h4>{{ cliente?.nome?.toUpperCase() }}</h4>
            <h5>
              Informações referentes ao periodo de
              {{ date.value.format("MMMM [de] YYYY  ") }}
            </h5>
          </div>
          <img
            style="
              justify-self: center;
              align-self: center;
              width: auto;
              max-height: 60px;
            "
            [src]="'data:image/png;base64,' + cliente?.foto"
          />
        </div>
      </div>
    </div>

    <app-relatorio-retornos
      (loadingStatus)="updateLoading(0, $event)"
      [dataEntrada]="data.format('DD/MM/YYYY')"
      [laminaResumo]="true"
      [meta]="metaMes"
    ></app-relatorio-retornos>
    <br />
    <br />

    <app-relatorio-retornos-acumulado
      (loadingStatus)="updateLoading(1, $event)"
      [dataEntrada]="data.format('DD/MM/YYYY')"
      [relatorioDinamico]="true"
      [laminaResumo]="true"
    ></app-relatorio-retornos-acumulado>
    <br /><br />
    <h2 style="margin-bottom: 10px">Gerenciamento de Risco</h2>
    <br />
    <h3>VaR - VALUE AT RISK</h3>
    <p style="text-align: center">
      Value-at-Risk ou Valor sob Risco pode se definido como perda máxima num
      determinado Período sob condições normais de mercado com determinado grau
      de confiança
    </p>
    <br />

    <app-var-fundos-carteira
      (loadingStatus)="updateLoading(2, $event)"
      [investimentos]="investimentos"
      [dataEntrada]="data.format('DD/MM/YYYY')"
      [laminaResumo]="true"
    ></app-var-fundos-carteira>
  </div>
</div>
