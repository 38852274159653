import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { Subject } from "rxjs";
import { delay } from "rxjs/operators";
import { CotaMeta } from "src/app/_models/benchmark.models";
import { Retorno } from "src/app/_models/carteira.model";
import { Usuario } from "src/app/_models/user.models";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-atribuicao-desempenho",
  templateUrl: "./atribuicao-desempenho.component.html",
  styleUrls: ["./atribuicao-desempenho.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AtribuicaoDesempenhoComponent implements OnInit {
  @Input()
  laminaResumo: Boolean = false;

  @Input()
  meta: CotaMeta;

  @Input()
  relatorioDinamico: Boolean = this.laminaResumo || false;

  dataEntrada$ = new Subject<string>();
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value);
  }

  atual = moment();
  minDate: Date;
  maxDate: Date;
  date = new FormControl(moment().year(2024));
  columnsToDisplay = [
    "fundo",
    "benchmark",
    "efetivo",
    // "participacao",
    // "desempenho",
    // "saldoAtual",
    "retorno",
    "participacao",
    "desempenho",
  ];

  widthColuna = `${60 / (this.columnsToDisplay.length - 1)}%`;
  registros: Retorno[] = [];
  idCliente?: number;
  complementoTitulo = "";

  totalSaldoAnterior = 0;
  totalSaldoAtual = 0;
  totalAplicacoes = 0;
  totalResgates = 0;
  totalRetorno = 0;
  totalEfetivo = 0;
  totalDesempenho = 0;
  totalRetornoPercentual = 0;

  rentabilidadeMedia = 0;

  zoom = new FormControl(1);

  usuario: Usuario;

  constructor(
    private carteiraService: CarteiraService,
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private authService: AuthService
  ) {
    this.idCliente = route.snapshot.paramMap.get("idCliente")
      ? +route.snapshot.paramMap.get("idCliente")
      : null;
    this.authService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;

      if (this.idCliente == null) {
        this.idCliente = this.usuario.idCliente;
      }
    });
  }

  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2024, 11, 31, 23, 59, 59);
    window.onafterprint = (e) => this.authService.willPrint$.next(false);

    this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
      if (willPrint) {
        window.print();
      }
    });

    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.startOf("month"));
    datepicker.close();
    this.retrieveRetornos(this.date.value);
  }

  retrieveRetornos(date: Moment) {
    this.carteiraService
      .relatorioRetornos(this.idCliente, date, "")
      .subscribe((v) => {
        const total = v.reduce((a, b) => a + b.saldoAtual, 0);
        this.registros = v.map((value) => ({
          ...value,
          participacao: value.saldoAtual / total,
        }));

        this.totalAplicacoes = this.registros.reduce(
          (acc, a) => acc + a.aplicacoes,
          0
        );
        this.totalResgates = this.registros.reduce(
          (acc, a) => acc + a.resgates,
          0
        );
        this.totalRetorno = this.registros.reduce(
          (acc, a) => acc + a.retorno,
          0
        );
        //Refeito o calculo conforme reunião dia 12/03/2021
        // this.totalDesempenho = this.registros.reduce(
        //   (acc, a) => acc + ((a.retorno * (a.participacao/100))),
        //   0
        // );

        this.totalSaldoAnterior = this.registros.reduce(
          (acc, a) => acc + a.saldoAnterior,
          0
        );
        this.totalSaldoAtual = this.registros.reduce(
          (acc, a) => acc + a.saldoAtual,
          0
        );
        //Refeito o calculo conforme reunião dia 12/03/2021
        // this.totalEfetivo = this.registros.reduce(
        //   (acc, a) => acc + a.efetivo * a.participacao,
        //   0
        // );

        const calculo_1 =
          (this.totalSaldoAtual + this.totalResgates - this.totalAplicacoes) /
            this.totalSaldoAnterior -
          1;

        const calculo_2 =
          this.totalSaldoAtual /
            (this.totalSaldoAnterior +
              this.totalAplicacoes -
              this.totalResgates) -
          1;

        this.totalEfetivo = (calculo_1 + calculo_2) / 2;

        this.totalRetornoPercentual = this.registros.reduce(
          (acc, a) => acc + a.retornoPercentual * a.participacao,
          0
        );

        this.totalDesempenho = this.registros.reduce(
          (acc, a) => acc + this.totalEfetivo * (a.retorno / this.totalRetorno),
          0
        );
      });
  }

  print() {
    this.authService.willPrint$.next(true);
  }
}
