import { Action } from '@ngrx/store';
import * as OtimizadorActions from './../actions/otimizador.action';
import { InformacoesOtimizacao } from '../_services/otimizador.service';
// Section 1
const initialState: Partial<InformacoesOtimizacao>  = {
};

// Section 2
export function reducer(state: Partial<InformacoesOtimizacao> = initialState, action: OtimizadorActions.Actions) {

    // Section 3
    switch (action.type) {
        case OtimizadorActions.OTIMIZAR:
            return action.payload;
        case OtimizadorActions.LIMPAR_OTIMIZADOR:
            return undefined;
        default:
            return state;
    }
}