<h1>Fundos de investimento</h1>

<div class="acoes">

    <mat-form-field>
        <input #in (input)="datasource.queryBy({search: in.value})" matInput placeholder="Filtro">
      </mat-form-field>

    <button  *appHasRole="[tipoUsuario.ADMIN]" routerLink="novo" mat-raised-button color="accent">
        <mat-icon>playlist_add</mat-icon> Novo Fundo de investimento
    </button>
    
    <table mat-table [dataSource]="datasource">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef style="width: 40%;"> Nome </th>
            <td mat-cell *matCellDef="let fundo" style="width: 40%; padding-right: 1em;">
                <span class="mobile-label">Nome:</span>
                <a *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]" [routerLink]="['editar', fundo.cnpj]">{{fundo.nome}}</a>
                <p *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]">{{fundo.nome}}</p>

            </td>
        </ng-container>
        <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef> CNPJ </th>
            <td mat-cell *matCellDef="let fundo">
                <span class="mobile-label">CNPJ:</span>
                {{fundo.cnpj | mask: '00.000.000/0000-00'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="patrimonioLiquido">
            <th mat-header-cell *matHeaderCellDef> Patrimônio Líquido </th>
            <td mat-cell *matCellDef="let fundo">
                <span class="mobile-label"> Patrimônio Líquido:</span>
                {{fundo.patrimonioLiquido | currency:"BRL"}}
            </td>
        </ng-container>
        <ng-container matColumnDef="classe">
            <th mat-header-cell *matHeaderCellDef> Classe </th>
            <td mat-cell *matCellDef="let fundo">
                <span class="mobile-label"> Classe:</span>
                {{fundo.classe}}
            </td>
        </ng-container>
        <ng-container matColumnDef="benchmark">
            <th mat-header-cell *matHeaderCellDef> Benchmark </th>
            <td mat-cell *matCellDef="let fundo">
                <span class="mobile-label"> Benchmark:</span>
                {{fundo.benchmark}} / {{fundo.benchTaxa | mask: 'percent'}}%
            </td>
        </ng-container>
        <ng-container matColumnDef="informes">
            <th mat-header-cell *matHeaderCellDef> Informes </th>
            <td mat-cell *matCellDef="let fundo">
                <span class="mobile-label"> Informes:</span>
                <span style="display: flex; flex-flow: row;">
                    <button [routerLink]="['informes', fundo.cnpj]" mat-icon-button color="primary" matTooltip="Visualizar os informes do fundo" matTooltipPosition="before">
                        <i class="fas fa-table"></i>
                    </button>
                    <button [routerLink]="['rentabilidade', fundo.cnpj]" mat-icon-button color="warn" matTooltip="Visualizar a rentabilidade do fundo" matTooltipPosition="before">
                        <i class="fas fa-percentage"></i>
                    </button>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="enquadramentos">
            <th mat-header-cell *matHeaderCellDef style="text-align: right;"> Enquadramentos </th>
            <td mat-cell *matCellDef="let fundo" style="text-align: right;">
                <span class="mobile-label"> Enquadramentos:</span>
                <span style="display: flex; flex-flow: row; float: right;">
                    <button (click)="adicionarEnquadramento(fundo)" mat-icon-button color="primary" matTooltip="Visualizar e adicionar resoluções ao fundo" matTooltipPosition="before">
                        <i class="fas fa-tasks"></i>
                    </button>
                    <button *appHasRole="[tipoUsuario.USUARIO, tipoUsuario.ADMIN]" (click)="verEnquadros(fundo)" mat-icon-button color="primary" matTooltip="Visualizar enquadros" matTooltipPosition="before">
                        <i class="fas fa-search-dollar"></i>
                    </button>
                </span>
            </td>
        </ng-container>
        
    </table>
    <div class="spinner-container" *ngIf="datasource.loading$ | async">
        <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
      </div>
    <mat-paginator *ngIf="datasource.page$ | async as page"
  [length]="page.totalElements" [pageSize]="page.size"
  [pageIndex]="page.number" [hidePageSize]="true" 
  (page)="datasource.fetch($event.pageIndex)">
</mat-paginator>

</div>
