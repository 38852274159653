import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { MY_FORMATS } from "src/app/_components/APR/APR.component";
import { UIService } from "src/app/_helpers/ui.service";
import { Meta } from "src/app/_models/benchmark.models";
import { Carteira } from "src/app/_models/carteira.model";
import { Cliente, ExportacaoCliente } from "src/app/_models/cliente.model";
import { SortReversePipe } from "src/app/_pipes/sortReverse.pipe";
import { AuthService } from "src/app/_services/auth.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { environment } from "src/environments/environment";

const NUMBER_OF_COMPONENTS = 10;

@Component({
  selector: "app-exportacao-relatorio-dinamico",
  templateUrl: "./exportacao-relatorio-dinamico.component.html",
  styleUrls: ["./exportacao-relatorio-dinamico.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ExportacaoRelatorioDinamicoComponent implements OnInit {
  atual = moment();
  // date = new FormControl(moment().add(-1, "month").startOf("month"));
  date = new FormControl(moment().add(1, "year").startOf("year"));
  minDate: Date;
  idCliente: number;
  cliente: Cliente;
  loading: boolean = false;
  novaData: moment.Moment;
  novoDate: moment.Moment;
  bacalhau: boolean = false;

  data: moment.Moment;

  selic: Meta = { idMeta: 13, nome: "SELIC" };
  ipca: Meta = { idMeta: 11, nome: "IPCA" };
  inpc: Meta = { idMeta: 12, nome: "INPC" };

  investimentos: Carteira[] = [];
  loadings: boolean[] = [];

  ultimaDataSaldo: Date;

  constructor(
    private uiService: UIService,
    private authService: AuthService,
    private clienteService: ClienteService,
    private route: ActivatedRoute
  ) {
    this.data = moment();
    for (let i = 0; i < NUMBER_OF_COMPONENTS; i++) {
      this.loadings.push(false);
    }
  }

  exportacaoList: Array<ExportacaoCliente>;

  colunas = ["id", "criadoEm", "status", "download"];

  downloadArquivo(item: any) {
    const idExportacao = item.id;
    // console.log(item)
    this.clienteService.downloadArquivo(idExportacao).subscribe((url) => {
      const a = document.createElement("a");
      a.href = url;
      a.download = `dinamico-${item.data}-${item.idCliente}`;
      a.click();
    });
  }

  getListagemExportacoes(data) {
    this.clienteService
      .buscarExportacoesCliente(this.idCliente, data)
      .subscribe((response) => (this.exportacaoList = response));
    // console.log("listagem de exportações: ", this.exportacaoList);
  }

  exportarRelatorio(data) {
    this.clienteService
      .ExportarRelatorioCliente(this.idCliente, data)
      .subscribe(
        (response) => {
          this.getListagemExportacoes(data);
          console.log("resposta da exportação: ", response);
        },
        (error) => {
          console.log("error: ", error);
        }
      );
    // console.log("chamei a função");
  }

  ngOnInit() {
    this.minDate = new Date(2021, 12, 31, 0, 0, 0);
    this.idCliente = this.route.snapshot.params["idCliente"];

    this.clienteService
      .buscarClientePorId(this.idCliente)
      .subscribe((cliente) => (this.cliente = cliente));

    this.authService.willPrint$.next(true);
    this.getListagemExportacoes(this.data);
  }

  setCnpjFundos(cnpjs) {
    this.investimentos = cnpjs;
    this.ultimaDataSaldo = new SortReversePipe().transform(
      cnpjs,
      "dataSaldo"
    )[0].dataSaldo;
  }

  get loaded() {
    return this.loadings.every((c) => c);
  }

  filtro() {
    this.bacalhau = true;
    this.data = this.novaData;
    this.loading = true;
    for (let i = 0; i < NUMBER_OF_COMPONENTS; i++) {
      this.loadings[i] = false;
    }
    this.exportarRelatorio(this.novaData);
    this.getListagemExportacoes(this.novaData);
  }

  updateLoading(i: number, status: boolean) {
    console.log(`${i} new status: ${status ? "loaded" : "loading"}`);
    this.loadings[i] = status;
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.startOf("month"));
    this.novaData = ctrlValue.startOf("month");

    datepicker.close();
    // this.loading = true;
    // for (let i = 0; i < NUMBER_OF_COMPONENTS; i++) {
    //   this.loadings[i] = false;
    // }
  }

  imprimir() {
    const url = `${environment.PATH_API}/facilitadores/relatorio-dinamico/${
      this.idCliente
    }?data=${this.date.value.format("YYYY-MM-DD")}`;
    this.authService.openWindowWithAuth(url);
  }
}
