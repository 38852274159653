import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Moment } from "moment";
import { environment } from "src/environments/environment";
import { AudespRipp } from "../_models/audesp.model";
import { XmlResponse } from "../_models/xml_response";
@Injectable({
  providedIn: "root",
})
export class AUDESPService {
  constructor(private http: HttpClient) {}

  consultarDadosAudesp = (
    periodo: Moment,
    idFundo: number,
    idCliente: number
  ) => {
    const url = `${environment.PATH_API}/ripp`;
    let params = new HttpParams();
    params = params.append("periodo", periodo.format("YYYY-MM-DD"));
    params = params.append("idFundo", idFundo.toString());
    params = params.append("idCliente", idCliente.toString());
    return this.http.get<AudespRipp>(url, { params });
  };

  salvarAudesp = (obj) => {
    const url = `${environment.PATH_API}/ripp`;
    return this.http.post(url, obj);
  };

  updateAudesp = (obj) => {
    const url = `${environment.PATH_API}/ripp/atualizar/${obj.id}`;
    return this.http.put(url, obj);
  };

  cosultarRIPP = (id) => {
    const url = `${environment.PATH_API}/ripp/${id}`;
    return this.http.get<AudespRipp>(url);
  };

  consultarDadosCadastroAudesp = (
    periodo: Moment,
    idFundo: number,
    idCliente: number
  ) => {
    const url = `${environment.PATH_API}/ripp/cadastro`;
    let params = new HttpParams();
    params = params.append("periodo", periodo.format("YYYY-MM-DD"));
    params = params.append("idFundo", idFundo.toString());
    params = params.append("idCliente", idCliente.toString());
    return this.http.get<AudespRipp>(url, { params });
  };

  exportarXMLCadastro = (periodo: Moment, idCliente: number) => {
    const url = `${environment.PATH_API}/ripp/xml/cadastro`;
    let params = new HttpParams();
    params = params.append("periodo", periodo.format("YYYY-MM-DD"));
    params = params.append("idCliente", idCliente.toString());
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.http.get<XmlResponse>(url, {
      headers: { ...headers, responseType: "text" },
      params,
    });
  };

  exportarXMLFundo = (periodo: Moment, idCliente: number) => {
    const url = `${environment.PATH_API}/ripp/xml/fundo`;
    let params = new HttpParams();
    params = params.append("periodo", periodo.format("YYYY-MM-DD"));
    params = params.append("idCliente", idCliente.toString());
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.http.get<XmlResponse>(url, {
      headers: { ...headers, responseType: "text" },
      params,
    });
  };
}
