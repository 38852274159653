import { Component, OnInit } from '@angular/core';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { ResolucaoQuery, ResolucaoService } from 'src/app/_services/resolucao.service';
import { PaginatedEndpoint } from 'src/app/_models/PaginatedResult';
import { AuthService } from 'src/app/_services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Enquadramento } from 'src/app/_models/resolucao.model';

@Component({
  selector: 'app-resolucao',
  templateUrl: './resolucao.component.html',
  styleUrls: ['./resolucao.component.scss']
})
export class ResolucaoComponent implements OnInit {
  datasource = new PaginatedDataSource<Enquadramento, ResolucaoQuery>(
    (page, query) => this.resolucaoService.lista(page, query),
    {property: 'id', order: 'asc'},
    { search: '' },
    environment.LIMITE_REGISTROS_LISTA
  );
  columns = ['descricao', 'legislacao', 'tipo', 'limiteResolucao', 'limiteParticipacao', 'limites', 'acoes'];

  constructor(
    private authService: AuthService,
    private resolucaoService:ResolucaoService,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

}
