import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService } from 'src/app/_helpers/ui.service';
import { Usuario } from 'src/app/_models/user.models';
import { AuthService } from 'src/app/_services/auth.service';
import { UsuarioService } from 'src/app/_services/usuario.service';

@Component({
  selector: 'app-logar-cliente',
  templateUrl: './logar-cliente.component.html',
  styleUrls: ['./logar-cliente.component.scss']
})
export class LogarClienteComponent implements OnInit {

  datasource: MatTableDataSource<Usuario>;
  columns = ['nome', 'email', 'tipo', 'acoes'];

  idCliente: number;
  constructor(
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private userService:UsuarioService,
    private uiService : UIService,
    private route: ActivatedRoute,
    private router: Router) {
      this.idCliente = +route.snapshot.paramMap.get('idCliente')
    }

  ngOnInit() {
    this.usuarioService.getUsuarioPorCliente(this.idCliente).subscribe(
      usuarios => this.datasource = new MatTableDataSource(usuarios)
    );
  }

  logarComoProxy(usuario) {
    const user = {
      nome: usuario.nome,
      tipoUser: +usuario.tipoUser,
      idUser: +usuario.idUser,
      ativo: true,
      idCliente: usuario.idCliente,
    };

    this.authService.loginAsProxy(user);
    this.router.navigate(['/login'])
  }


  changePassword(usuario){

    this.uiService.abrirDialogLoading(
      this.userService.resetSenha(+usuario.idUser),
      'Resetando senha...',
      'Senha resetada com sucesso',
      'Erro ao resetar a senha'
    )
  }

}
