<h1 class="title">Perfil do usuário</h1>

<h3><i class="fas fa-key"></i>Alterar senha</h3>
<form [formGroup]="alterarSenhaForm" (ngSubmit)="alterarSenha()">
  <div fxLayout="row" fxLayout.lt-md="column">
    <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
      <input type="hidden" aria-hidden="true" name="username" autocomplete="username" [value]="usuario.eMail" />
      <mat-form-field fxFlex="auto">
        <input #senha matInput formControlName="senhaAtual" type="password" autocomplete="current-password" placeholder="Senha atual" required aria-label="senha atual">
      </mat-form-field>
      <mat-form-field fxFlex="auto">
        <input #senhaNova matInput formControlName="novaSenha" type="password" autocomplete="new-password"
        placeholder="Nova senha" required aria-label="nova senha">
        <mat-error *ngIf="alterarSenhaForm.controls['novaSenha'].errors?.passwordStrength">{{alterarSenhaForm.controls['novaSenha'].errors?.passwordStrength}}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="auto">
        <input #senhaNovaRepetir matInput formControlName="novaSenhaConfirma" type="password" autocomplete="new-password"
        placeholder="Repita a nova senha" required aria-label="repetir a senha">
        <mat-error *ngIf="alterarSenhaForm.value.novaSenha != alterarSenhaForm.value.novaSenhaConfirma">As senhas não conferem</mat-error>
      </mat-form-field>
      <button color="primary" [disabled]="alterarSenhaForm.invalid" mat-stroked-button><i class="fas fa-check"></i>Alterar senha</button>
    </div>
  </div>
</form>