import { Component, OnInit, Inject } from "@angular/core";
import { CarteiraService } from "src/app/_services/carteira.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UIService } from "src/app/_helpers/ui.service";
import { APR } from "src/app/_models/arp.model";
import { MovimentoCarteira, Carteira } from "src/app/_models/carteira.model";

export interface DialogData {
  fundos: Carteira[];
}

@Component({
  selector: "app-dialog-selecao-conta",
  templateUrl: "./dialog-selecao-conta.component.html",
  styleUrls: ["./dialog-selecao-conta.component.scss"],
})
export class DialogSelecaoConta implements OnInit {
  constructor(
    private carteiraService: CarteiraService,
    public dialogRef: MatDialogRef<DialogSelecaoConta>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private uiService: UIService
  ) {}

  ngOnInit() {}

  selectConta(result: Carteira) {
    if (result) {
      this.dialogRef.close(result);
    }
  }
}
