import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { DialogLoadingComponent } from "src/app/_helpers/dialog-loading/dialog-loading.component";
import { ComentarioService } from "src/app/_services/comentario.service";

@Component({
  selector: "app-add-analise-relatorio",
  templateUrl: "./add-analise-relatorio.component.html",
  styleUrls: ["./add-analise-relatorio.component.scss"],
})
export class AddAnaliseRelatorioComponent implements OnInit {
  file: any;
  form: FormGroup;
  date: Date = new Date();
  texto: string;
  descricao: string;
  todos: string = "N";

  opcoes: any[] = [
    { valor: "S", texto: "Sim" },
    { valor: "N", texto: "Não" },
  ];

  // cliente = new FormControl("");

  constructor(
    public dialogRef: MatDialogRef<AddAnaliseRelatorioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private comentarioService: ComentarioService,
    private _fb: FormBuilder
  ) {
    // this.cliente = _fb.control("");
    this.form = _fb.group({
      data: _fb.control(this.date, Validators.required),
      texto: _fb.control("", Validators.required),
      descricao: _fb.control("", Validators.required),
      todos: _fb.control("N", Validators.required),
    });
  }

  ngOnInit() {}

  onChangeData(e) {
    this.date = e.target.value;
    this.form.patchValue({ data: this.date });
  }

  onChangeTexto(e) {
    this.texto = e.target.value;
    this.form.patchValue({ texto: this.texto });
  }

  onChangeDesc(e) {
    this.descricao = e.target.value;
    this.form.patchValue({ descricao: this.descricao });
  }

  enviarAnalise() {
    const obj = {
      data: this.form.value.data,
      texto: this.form.value.texto,
      descricao: this.form.value.descricao,
      todos: this.form.value.todos,
    };
    const loadingDialog = this.dialog.open(DialogLoadingComponent, {
      data: {
        text: "Enviando análise de relatório",
        target: this.comentarioService.addAnalise(obj),
        closeOnComplete: true,
        successMessage: "Analise enviada com sucesso",
        errorMessage: ({ error }) => `Erro: ${error.error}`,
      },
    });
    loadingDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close();
      }
    });
    // console.log("objeto enviado:", obj);
    this.dialogRef.close();
  }
}
