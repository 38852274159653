import { Pipe, PipeTransform } from '@angular/core';
import { Carteira } from '../_models/carteira.model';

@Pipe({
  name: 'situacaoEnquadramento'
})
export class SituacaoEnquadramentoPipe implements PipeTransform {

  transform(value: Carteira[], args?: any): any {
    return value.every(c => c.enquadramento?.enquadrado);
  }

}
