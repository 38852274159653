<h1 class="title">
    Início
</h1>
<app-resumo-carteira *appHasRole="[3,4]"></app-resumo-carteira>
<app-logs *appHasRole="[1,2]"></app-logs>

<form *ngIf="false" [formGroup]="form">
    <mat-form-field>
        <input #fundo matInput formControlName="fundo" placeholder="CNPJ do fundo de investimentos" aria-label="CNPJ do fundo de investimentos">
        <mat-error *ngIf="!fundo.valid">Informe o fundo de investimentos</mat-error>
    </mat-form-field>
    <div>
        <section class="mat-typography">
            <h4>Periodicidade</h4>
        </section>
        <mat-radio-group #periodicidade formControlName="periodicidade">
            <mat-radio-button *ngFor="let periodo of periodicidades" color="primary" value="{{periodo.valor}}">{{periodo.label}}</mat-radio-button>
        </mat-radio-group>
    </div>
    <mat-form-field class="mesBase" *ngIf="form.get('periodicidade').value > 0">
        <input #mesBase matInput formControlName="mesbase" placeholder="Mês Base" mask="00/0000" [showMaskTyped]="true" [dropSpecialCharacters]="false" aria-label="Mês Base">
        <mat-error *ngIf="!mesBase.valid">Informe o mês base</mat-error>
    </mat-form-field>
    <div class="periodoDiario" *ngIf="form.get('periodicidade').value == 0">
        <mat-form-field>
            <input matInput [matDatepicker]="pickerInicio" readonly formControlName="database" placeholder="Selecione a data Inicial">
            <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
            <mat-datepicker #pickerInicio></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <input matInput [matDatepicker]="pickerFinal" readonly formControlName="datafinal" placeholder="Selecione a data Final">
            <mat-datepicker-toggle matSuffix [for]="pickerFinal"></mat-datepicker-toggle>
            <mat-datepicker #pickerFinal></mat-datepicker>
        </mat-form-field>
    </div>
    <button mat-raised-button color="accent" (click)="buscarDadosIndice()">
        <mat-icon>insert_chart_outlined</mat-icon> Calcular índices
    </button>
    
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="isLoading"></mat-spinner>
    
    <table *ngIf="indice && !isLoading">
        <tr>
            <td>Fundo</td><td>{{indice.fundo}}</td>
        </tr>
        <tr>
            <td>Nome do fundo</td><td>{{indice.nomeFundo}}</td>
        </tr>
        <tr>
            <td>Data inicial</td><td>{{indice.dataInicial | date:'dd/MM/yyyy'}}</td>
        </tr>
        <tr>
            <td>Data final</td><td>{{indice.dataFinal | date:'dd/MM/yyyy'}}</td>
        </tr>
        <tr>
            <td>Dias úteis</td><td>{{indice.diasUteis}} <small><strong>* Número de observações</strong></small></td>
        </tr>
        <tr>
            <td>Rentabilidade</td><td>{{indice.rentabilidade | number:'1.1-25'}}</td>
        </tr>
        <tr>
            <td>Média</td><td>{{indice.media | number:'1.1-25':'pt'}} <small><strong>*Média geométrica fator 1</strong></small></td>
        </tr>
        <tr>
            <td>Média CDI</td><td>{{indice.mediaCDI | number:'1.1-25'}} <small><strong>*Média geométrica fator 1</strong></small></td>
        </tr>
        <tr>
            <td>Desvio padrão</td><td>{{indice.desvioPadrao | number:'1.1-25'}} <small><strong>*DESVPAD.A</strong></small></td>
        </tr>
        <tr>
            <td>Probabilidade</td><td>{{indice.probabilidade | number:'1.1-25'}}</td>
        </tr>
        <tr>
            <td>Distribuição cumulativa inversa</td><td>{{indice.distribuicaoCumulativaInversa | number:'1.1-25'}}</td>
        </tr>
        <tr>
            <td>VAR</td><td>{{indice.var | number:'1.1-25':'pt'}} <small><strong>* -Média + DCI x DP</strong></small></td>
        </tr>
        <tr>
            <td>Volatilidade</td><td>{{indice.volatilidade | number:'1.1-25'}} <small><strong>* DP x <span style="font-size: 1.3em; font-weight: bolder">&radic;</span>Dias úteis</strong></small></td>
        </tr>
        <tr>
            <td>Sharpe</td><td>{{indice.sharpe}} <small><strong>* ((Média x Dias úteis) - (Média CDI * Dias úteis)) / Volatilidade</strong></small></td>
        </tr>
    </table>
    
    <div *ngIf="indice && !isLoading" class="lado-lado">
        <div class="table">
            <h3>Séries</h3>
            <table style="text-align: center;margin: 0;">
                <tr>
                    <th>Data</th>
                    <th>Rentabilidade</th>
                    <th>Cota</th>
                </tr>
                <tr *ngFor="let serie of indice.series">
                    <td>{{serie.data | date:'dd/MM/yyyy'}}</td>
                    <td>{{serie.rentabilidade | number:'1.1-25'}}</td>
                    <td>{{serie.cota | number:'1.1-25'}}</td>
                </tr>
            </table>
        </div>
        <div class="table">
            <h3>Séries CDI</h3>
            <table>
                <tr>
                    <th>Data</th>
                    <th>Rentabilidade</th>
                    <th>Cota</th>
                </tr>
                <tr *ngFor="let serie of indice.seriesCDI">
                    <td>{{serie.data | date:'dd/MM/yyyy'}}</td>
                    <td>{{serie.rentabilidade | number:'1.1-25'}}</td>
                    <td>{{serie.cota | number:'1.1-25'}}</td>
                </tr>
            </table>
        </div>
    </div>
</form>