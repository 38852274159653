import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { UIService } from 'src/app/_helpers/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss'],
})
export class RecuperarSenhaComponent implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RecuperarSenhaComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UIService) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
  }

  enviarRecuperacao() {
    const obj = this.form.value;
    this.authService.recuperacaoSenha(obj).subscribe(success => {
      this.uiService.showSnackBar('Verifique seu email para instruções de recuperação', 'OK', 500);
      this.dialogRef.close();
    }, error => {
      this.uiService.showSnackBar('Erro ao enviar email de recuperação de senha, verifique se o email está correto!', 'OK', 500);
    });
  }
}
