import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IndiceService } from './../../_services/indices.service';
import { UIService } from './../../_helpers/ui.service';
import { formatDate } from '@angular/common';
import { Indice } from 'src/app/_models/indices.models';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  periodicidades = [
    {label: 'Diário', valor: 0},
    {label: 'Mês', valor: 1},
    {label: '3 Meses', valor: 2},
    {label: '6 Meses', valor: 3},
    {label: 'Ano', valor: 4},
    {label: '12 Meses', valor: 5},
    {label: '18 Meses', valor: 6},
    {label: '24 Meses', valor: 7},
    {label: '36 Meses', valor: 8},
  ];

  form = new FormGroup({
    fundo: new FormControl(null, Validators.required),
    database: new FormControl(null, Validators.required),
    mesbase: new FormControl(null, Validators.required),
    datafinal: new FormControl(null, Validators.required),
    periodicidade: new FormControl(null, Validators.required)
  });

  isLoading: boolean;
  indice: Indice;

  constructor(
    private indiceService: IndiceService,
    private uiService: UIService
    ) { }

  ngOnInit() {
    this.uiService.loadingChangeState.subscribe(isLoading => this.isLoading = isLoading);
  }

  buscarDadosIndice() {
    const fundo = this.form.get('fundo').value;
    const periodicidade = this.form.get('periodicidade').value;

    let database = this.form.get('database').value;
    let datafinal = this.form.get('datafinal').value;

    if (+periodicidade === 0) {
      database = formatDate(database, 'yyyy-MM-dd', 'pt-BR');
      datafinal = formatDate(datafinal, 'yyyy-MM-dd', 'pt-BR');
    } else {
      const dtbase = this.form.get('mesbase').value.split('/');
      console.log(dtbase);
      const data = new Date();
      data.setDate(1);
      data.setMonth(dtbase[0] - 1);
      data.setFullYear(dtbase[1]);
      database = formatDate(data, 'yyyy-MM-dd', 'pt-BR');
      console.log(database);
      datafinal = null;
    }

    this.uiService.loadingChangeState.next(true);
    this.indiceService.buscarIndices(fundo, periodicidade, database, datafinal).subscribe(
      (response: Indice) => {
        this.indice = response;
        this.uiService.loadingChangeState.next(false);
      },
      (error) => {
        this.uiService.showSnackBar('Erro consultando dados do fundo', null, 3600);
        this.uiService.loadingChangeState.next(false);
        this.indice = null;
      }
    );
  }

}
