<h1 class="title">Novo Termo de Análise de Credenciamento</h1>

<mat-horizontal-stepper [linear]="true" #stepper>

  <mat-step [stepControl]="form1">
    <form [formGroup]="form1"  (ngSubmit)="avancar_primeiro_passo()">
      <ng-template matStepLabel>Selecione o Administrador/ Gestor para o Credenciamento</ng-template>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="100" >
          <mat-label>Administrador / Gestor</mat-label>
          <mat-select  formControlName="fundo" required style="font-size: 12px;" >
            <mat-option value>Selecione</mat-option>
            <mat-option *ngFor="let papel of papeis" [value]="papel.seq">Administrador : {{papel.nomeAdmin}} - {{papel.cnpjAdmin}} | Gestor : {{papel.nomeGestor}} - {{papel.cnpjGestor}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column">
        <button matStepperNext color="primary" mat-raised-button >Próxima Etapa - Dados para Credenciamento</button>
      </div>
    </form>

  </mat-step>


  <mat-step [stepControl]="form2" >
    <form [formGroup]="form2" (ngSubmit)="avancar_segundo_passo()">
      <ng-template matStepLabel>Dados para Credenciamento</ng-template>

      <h5>
        INSTITUIÇÃO FINANCEIRA: <span style="font-weight: normal;">{{papel ? papel.nomeAdmin : ""}}</span>&nbsp;
        CNPJ: <span style="font-weight: normal;">{{papel ? papel.cnpjAdmin : ""}}</span>&nbsp;
      </h5>

      <h5>
        GESTOR: <span style="font-weight: normal;">{{papel ? papel.nomeGestor : ""}}</span>&nbsp;
        CNPJ: <span style="font-weight: normal;">{{papel ? papel.cnpjGestor : ""}}</span>
      </h5>



      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <h4>Dados para contato</h4>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="30">

          <input
          #nomeContato1
          matInput
          formControlName="nomeContato1"
          placeholder="Nome para contato 1"
          required
          aria-label="Nome para contato 1"
        />
        <mat-error *ngIf="!nomeContato1.valid">Informe o nome para contato</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30">

          <input
          #emailContato1
          matInput
          formControlName="emailContato1"
          placeholder="Email"
          required
          aria-label="Email"
        />
        <mat-error *ngIf="!emailContato1.valid">Informe o email para contato</mat-error>
        </mat-form-field>


        <mat-form-field fxFlex="30">

          <input
          #telefoneContato1
          matInput
          formControlName="telefoneContato1"
          placeholder="Telefone"
          required
          aria-label="Telefone"
        />
        <mat-error *ngIf="!telefoneContato1.valid">Informe o telefone para contato</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="30">

          <input
          #nomeContato2
          matInput
          formControlName="nomeContato2"
          placeholder="Nome para contato 2"

          aria-label="Nome para contato 2"
        />
        </mat-form-field>

        <mat-form-field fxFlex="30">

          <input
          #emailContato2
          matInput
          formControlName="emailContato2"
          placeholder="Email"
          aria-label="Email"
        />

        </mat-form-field>


        <mat-form-field fxFlex="30">

          <input
          #telefoneContato2
          matInput
          formControlName="telefoneContato2"
          placeholder="Telefone"

          aria-label="Telefone"
        />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <h4>DECLARAÇÃO</h4>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-label>Instituição financeira constituída conforme</mat-label>
          <mat-form-field  fxFlex="20" >
          <input
          #constituinteIF
          matInput
          formControlName="constituinteIF"
          placeholder="Constituinte"
          required
        />
        </mat-form-field>
        <mat-label>,</mat-label>
        <mat-label>e alterações posteriores, emitido em</mat-label>
        <mat-form-field fxFlex="20">
          <input matInput [matDatepicker]="picker" readonly required formControlName="dataEmissao" placeholder="Data de emissão">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-label>.</mat-label>
      </div>


      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-label>Entidade autorizada a exercer atividade de</mat-label>
          <mat-form-field  fxFlex="20" >
          <input
          #atividade
          matInput
          formControlName="atividade"
          placeholder="Atividade exercida"
          required
        />
        </mat-form-field>
        <mat-label>,</mat-label>
        <mat-label>conforme </mat-label>
        <mat-form-field  fxFlex="20" >
          <input
          #atoDeclaratorio
          matInput
          formControlName="atoDeclaratorio"
          placeholder="Ato Declaratório"
          required
        />
        </mat-form-field>
        <mat-label>número</mat-label>
          <mat-form-field  fxFlex="20" >
          <input
          #numero
          matInput
          formControlName="numero"
          placeholder="Número"
          required
        />
        </mat-form-field>
        <mat-label>,</mat-label>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-label>expedido em</mat-label>
        <mat-form-field fxFlex="20">
          <input matInput [matDatepicker]="picker2" readonly required formControlName="dataExpedicao" placeholder="Data de expedição">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-label>pelo(a)</mat-label>
          <mat-form-field  fxFlex="20" >
          <input
          #expedidoPor
          matInput
          formControlName="expedidoPor"
          placeholder="Expedido Por"
          required
        />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <h4>DOCUMENTAÇÃO COMPROBATÓRIA - ATESTADOS DE REGULARIDADE</h4>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <input matInput [matDatepicker]="picker3" readonly required formControlName="vencimentoFiscalMunicipal" placeholder="Fiscal Municipal (Vencimento)">
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <input matInput [matDatepicker]="picker4" readonly required formControlName="vencimentoFiscalEstadual" placeholder="Fiscal Estadual (Vencimento)">
          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
          <mat-datepicker #picker4></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <input matInput [matDatepicker]="picker5" readonly required formControlName="vencimentoFiscalFederal" placeholder="Fiscal Federal (Vencimento)">
          <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
          <mat-datepicker #picker5></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <input matInput [matDatepicker]="picker6" readonly required formControlName="vencimentoPrevidenciario" placeholder="Previdenciária (Vencimento)">
          <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
          <mat-datepicker #picker6></mat-datepicker>
        </mat-form-field>
      </div>


      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <h4>CLASSIFICAÇÃO DA INSTITUIÇÃO FINANCEIRA</h4>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">

        <mat-radio-group aria-label="" formControlName="classificacaoIF">
          <mat-radio-button value="gestor">Gestor &nbsp;</mat-radio-button>
          <mat-radio-button value="administrador">Administrador &nbsp;</mat-radio-button>
          <mat-radio-button value="administrador_gestor">Administrador/Gestor &nbsp;</mat-radio-button>
        </mat-radio-group>
      </div>



      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <h4>RATING</h4>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <mat-radio-group aria-label="" formControlName="rating">
            <mat-radio-button [value]="true">Sim &nbsp;</mat-radio-button>
            <mat-radio-button [value]="false">Não &nbsp;</mat-radio-button>
          </mat-radio-group>
      </div>


      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="30" *ngIf="form2.value.rating">
          <mat-label>Rating</mat-label>
          <mat-select  formControlName="idrating" >
            <mat-option value>Selecione</mat-option>
            <mat-option *ngFor="let r of ratings" [value]="r.id">{{r.agencia}} | {{r.rating}} | {{r.pontuacao}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <h4>PATRIMONIO SOB GESTÃO</h4>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <input
            #patrimonioNacional
            matInput
            currencyMask
            formControlName="patrimonioNacional"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            placeholder="Nacional (R$)"
            required
            aria-label="Nacional (R$)"
          />
          <mat-error *ngIf="!patrimonioNacional.valid">Informe o patrimonio nacional</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <input
            #patrimonioGlobal
            matInput
            currencyMask
            formControlName="patrimonioGlobal"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            placeholder="Global (R$)"
            required
            aria-label="Global (R$)"
          />
          <mat-error *ngIf="!patrimonioGlobal.valid">Informe o patrimonio global</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <input
            #patrimonioRPPS
            matInput
            currencyMask
            formControlName="patrimonioRPPS"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            placeholder="RPPS (R$)"
            required
            aria-label="RPPS (R$)"
          />
          <mat-error *ngIf="!patrimonioRPPS.valid">Informe o patrimonio RPPS</mat-error>
        </mat-form-field>
      </div>





      <div fxLayout="row" fxLayout.lt-md="column">
        <button matStepperPrevious color="primary" mat-raised-button >Voltar</button>&nbsp;
        <button matStepperNext color="primary" mat-raised-button >Próxima Etapa - Calcular Aderência</button>
      </div>
    </form>

  </mat-step>





  <mat-step [stepControl]="form3"  >
    <form [formGroup]="form3" (ngSubmit)="avancar_ultimo_passo()">
      <ng-template matStepLabel>Cálcular Aderência</ng-template>
      <h5>
        INSTITUIÇÃO FINANCEIRA: <span style="font-weight: normal;">{{papel ? papel.nomeAdmin : ""}}</span>&nbsp;
        CNPJ: <span style="font-weight: normal;">{{papel ? papel.cnpjAdmin : ""}}</span>&nbsp;
      </h5>

      <h5>
        GESTOR: <span style="font-weight: normal;">{{papel ? papel.nomeGestor : ""}}</span>&nbsp;
        CNPJ: <span style="font-weight: normal;">{{papel ? papel.cnpjGestor : ""}}</span>
      </h5>


      <table mat-table [dataSource]="datasource" class="tabela">

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

        <ng-container matColumnDef="cnpjFundo">
          <th mat-header-cell *matHeaderCellDef>CNPJ</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">CNPJ:</span>
            {{element.cnpjFundo}}
          </td>
        </ng-container>

        <ng-container matColumnDef="nomeFundo">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Nome:</span>
            {{element.nomeFundo}}
          </td>
        </ng-container>


        <ng-container matColumnDef="benchmark">
          <th mat-header-cell *matHeaderCellDef>Benchmark</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Benchmark:</span>
            {{element.benchmark}}
          </td>
        </ng-container>

        <ng-container matColumnDef="grauRisco">
          <th mat-header-cell *matHeaderCellDef>Risco</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Risco:</span>
            {{element.grauRisco}}
          </td>
        </ng-container>

        <ng-container matColumnDef="prazoAderencia">
          <th mat-header-cell *matHeaderCellDef>Prazo</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Prazo:</span>
            {{element.prazoAderencia}}
          </td>
        </ng-container>


        <ng-container matColumnDef="percentualBenchmark">
          <th mat-header-cell *matHeaderCellDef>Benchmark (%)</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Benchmark (%):</span>
            {{element.percentualBenchmark  |  number: "1.4-10"}}
          </td>
        </ng-container>


        <ng-container matColumnDef="percentualRentabilidade">
          <th mat-header-cell *matHeaderCellDef>Rentabilidade (%)</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">Rentabilidade (%):</span>
            {{element.percentualRentabilidade  |  number: "1.4-10"}}
          </td>
        </ng-container>


        <ng-container matColumnDef="aderencia">
          <th mat-header-cell *matHeaderCellDef>AD</th>
          <td mat-cell *matCellDef="let element">
            <span class="mobile-label">AD:</span>
            {{element.aderencia}}
          </td>
        </ng-container>


      </table>


      <h4>Total de fundos: {{resumoFundos.total_fundos}}</h4>
      <h4>Total aderentes:{{resumoFundos.total_aderencia}}</h4>
      <h4>Percentual: {{resumoFundos.percentual}}%</h4>
      <h4>Cálculo de aderência: {{resumoFundos.aderencia}}%</h4>


      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
        <mat-form-field fxFlex="20">
          <mat-label>Penalidade (0 a 10%)</mat-label>
          <input
          #penalidade
          matInput
          currencyMask
          formControlName="penalidade"
          [options]="{ prefix: '', thousands: '.', decimal: ',', sufix:'%'}"
          placeholder=""
          required
        />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column">
        <button matStepperPrevious color="primary" mat-raised-button >Voltar</button>
        &nbsp;
        <button matStepperNext color="primary" mat-raised-button >Próxima Etapa - Concluir</button>
      </div>
    </form>
    </mat-step>




    <mat-step [stepControl]="form4" >
      <form [formGroup]="form4"  (ngSubmit)="salvar()">
        <ng-template matStepLabel>Concluir</ng-template>

        <h5>
          INSTITUIÇÃO FINANCEIRA: <span style="font-weight: normal;">{{papel ? papel.nomeAdmin : ""}}</span>&nbsp;
          CNPJ: <span style="font-weight: normal;">{{papel ? papel.cnpjAdmin : ""}}</span>&nbsp;
        </h5>

        <h5>
          GESTOR: <span style="font-weight: normal;">{{papel ? papel.nomeGestor : ""}}</span>&nbsp;
          CNPJ: <span style="font-weight: normal;">{{papel ? papel.cnpjGestor : ""}}</span>
        </h5>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <mat-form-field fxFlex="20">
            <mat-label>Rating de gestão de qualidade (0 a 20%)</mat-label>
            <input
            #rating
            matInput
            currencyMask
            formControlName="rating"
            [options]="{ prefix: '', thousands: '.', decimal: ',', sufix:'%'}"
            placeholder=""
            required
            readonly
          />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <mat-form-field fxFlex="20">
            <mat-label>Volume de recursos administrados (2 a 10%)</mat-label>
            <input
            #volume
            matInput
            currencyMask
            formControlName="volume"
            [options]="{ prefix: '', thousands: '.', decimal: ',', sufix:'%'}"
            placeholder=""
            required
            readonly
          />
          </mat-form-field>
        </div>



        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <mat-form-field fxFlex="20">
            <mat-label>Tempo de atuação de mercado (2 a 10%)</mat-label>
            <input
            #tempo
            matInput
            currencyMask
            formControlName="tempo"
            [options]="{ prefix: '', thousands: '.', decimal: ',', sufix:'%'}"
            placeholder=""
            required
            readonly
          />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <mat-form-field fxFlex="20">
            <mat-label>Avaliação de aderência dos fundos (0 a 60%)</mat-label>
            <input
            #aderencia
            matInput
            currencyMask
            formControlName="aderencia"
            [options]="{ prefix: '', thousands: '.', decimal: ',', sufix:'%'}"
            placeholder=""
            required
            readonly
          />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <mat-form-field fxFlex="20">
            <mat-label>Menos critério de penalidade (0 a 10%)</mat-label>
            <input
            #penalidade
            matInput
            currencyMask
            formControlName="penalidade"
            [options]="{ prefix: '', thousands: '.', decimal: ',', sufix:'%'}"
            placeholder=""
            required
            readonly
          />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
          <mat-form-field fxFlex="20">
            <mat-label>Pontuação - Quesitos Técnicos</mat-label>
            <input
            #pontuacao
            matInput
            currencyMask
            formControlName="pontuacao"
            [options]="{ prefix: '', thousands: '.', decimal: ',', sufix:'%'}"
            placeholder=""
            required
            readonly
          />
          </mat-form-field>
        </div>


        <div fxLayout="row" fxLayout.lt-md="column">
          <button matStepperPrevious color="primary" mat-raised-button >Voltar</button>
          &nbsp;
          <button matStepperNext color="primary" mat-raised-button >Salvar</button>
        </div>
      </form>
    </mat-step>

</mat-horizontal-stepper>
