import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

export interface DialogData {
  limiteInferior: number;
  limiteSuperior: number;
}



@Component({
  selector: 'app-dialog-adicionar-otimizador',
  templateUrl: './dialog-adicionar-otimizador.component.html',
  styleUrls: ['./dialog-adicionar-otimizador.component.scss']
})
export class DialogAdicionarOtimizadorComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogAdicionarOtimizadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _fb: FormBuilder
  ) {
    this.form = _fb.group({
      limiteInferior: [this.data.limiteInferior, Validators.required],
      limiteSuperior: [this.data.limiteSuperior, Validators.required]
    });
  }

  ngOnInit() {
  }

  confirmar() {
    this.dialogRef.close(this.form.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
