import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PageRequest, Page } from "../_models/PaginatedResult";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  FIList,
  FundoInvestimento,
  InformeFundo,
  Log,
  RentabilidadeFundo,
  RentabilidadeFundoQuery,
} from "../_models/fundo-investimento.models";
import * as moment from "moment";

export interface FIQuery {
  search?: string;
  idConjunto?: number;
  otimizador?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class FundoInvestimentoService {
  private cvmUrl = `${environment.PATH_API}/cvm`;
  private fiUrl = `${environment.PATH_API}/fi`;

  constructor(private http: HttpClient) {}

  getClassesCVM() {
    const url = `${this.cvmUrl}/classe`;
    return this.http.get(url);
  }

  getLogsCvm() {
    const url = `${this.cvmUrl}/logs`;
    return this.http.get<Log[]>(url);
  }

  cadastro(dados: FundoInvestimento) {
    const url = `${this.fiUrl}/cadastro`;
    return this.http.post(url, dados);
  }

  atualizar(dados: FundoInvestimento) {
    const url = `${this.fiUrl}/`;
    return this.http.put(url, dados);
  }

  editarPatrimonioLiquido(date: any, patrimonioLiquido: number, cnpj: any) {
    const url = `${this.fiUrl}/${cnpj}/informes?data=${date}&valorPatrimonioLiquido=${patrimonioLiquido}`;
    console.log("url: ", url);
    return this.http.put(url, {});
  }

  getOrigem(cnpj: string) {
    const url = `${this.fiUrl}/${cnpj.replace(/\D/g, "")}/origem`;
    return this.http.get(url);
  }

  getRentabilidadeFundo(cnpj: string, query: RentabilidadeFundoQuery) {
    const url = `${this.fiUrl}/rentabilidade/${cnpj}`;
    let params = new HttpParams();
    params = params.append(
      "dataInicio",
      moment(query.dataInicial).format("YYYY-MM-DD")
    );
    params = params.append(
      "dataFinal",
      moment(query.dataFinal).format("YYYY-MM-DD")
    );
    if (query.mensal === "S") {
      params = params.append("mensal", "S");
    }
    return this.http.get<RentabilidadeFundo[]>(url, { params });
  }

  listarInformes(cnpj: string, inicio?: string, fim?: string) {
    let cnpjLimpa = cnpj.replace(/[^\d\s]/gi, "");
    const url = `${this.fiUrl}/${cnpjLimpa}/informes`;
    let params = new HttpParams();
    if (inicio && fim) {
      params = params.append("inicio", inicio);
      params = params.append("fim", fim);
    }
    return this.http.get<InformeFundo[]>(url, { params: params });
  }

  salvarInforme(informe: InformeFundo) {
    const url = `${this.fiUrl}/informe`;
    return this.http.post(url, informe);
  }

  sequencialCodigoFundo() {
    const url = `${this.fiUrl}/seq-codigo-fundo`;
    return this.http.get(url);
  }

  removerInforme(id: number) {
    const url = `${this.fiUrl}/informe/${id}`;
    return this.http.delete(url);
  }

  getFundoByCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/[^\d\s]/gi, "");
    const url = `${this.fiUrl}/${cnpj}`;
    return this.http.get<FundoInvestimento>(url);
  }

  lista(
    tipoFiltro?: string,
    filtro?: string,
    idConjunto?: number,
    otimizador: boolean = false
  ) {
    let url = `${this.fiUrl}/`;
    let params = new HttpParams();
    if (filtro) {
      if (tipoFiltro === "cnpj") {
        filtro = filtro.replace(/[^\d\s]/gi, "");
      }
      url += `${tipoFiltro}/${filtro}/`;
    }
    if (idConjunto) {
      params = params.append("conjunto", idConjunto.toString());
    }
    if (otimizador) {
      params = params.append("otimizador", "S");
    }
    params = params.append("page", "0");
    params = params.append("limit", "50");
    const t = this.http.get<Page<FIList>>(url, { params: params });
    return t.pipe(map((c) => c.data));
  }

  listaPaginada(
    request: PageRequest<FIList>,
    query: FIQuery
  ): Observable<Page<FIList>> {
    let url = `${this.fiUrl}/`;
    let params = new HttpParams();

    let tipoFiltro = null;
    tipoFiltro = query.search.match(/[^\d\.\/\-\s]/gi) ? "nome" : "cnpj";
    if (tipoFiltro === "cnpj") {
      query.search = query.search.replace(/[^\d\s]/gi, "");
    }
    if (query.search) {
      url += `${tipoFiltro}/${query.search}/`;
    }
    if (query.idConjunto) {
      params = params.append("conjunto", query.idConjunto.toString());
    }
    if (query.otimizador) {
      params = params.append("otimizador", "S");
    }

    params = params.append("page", request.page.toString());
    params = params.append("limit", request.size.toString());

    return this.http.get<Page<FIList>>(url, { params: params });
  }

  filtroFundo = (value) => {
    let tipoFiltro = null;
    value = value || "";
    tipoFiltro = value.match(/[^\d\.\/\-\s]/gi) ? "nome" : "cnpj";
    if (tipoFiltro === "cnpj") {
      value = value.replace(/[^\d\s]/g, "");
    }
    return this.lista(tipoFiltro, value);
  };

  getLegislacao = (pre2018 = false) => {
    let url = `${this.fiUrl}/legislacao`;
    let params = new HttpParams();
    params = params.append("pre2018", pre2018 ? "S" : "N");
    return this.http.get<any[]>(url, { params: params });
  };
}
