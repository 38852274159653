import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { Carteira } from "src/app/_models/carteira.model";
import { Ranking } from "src/app/_models/otimizador.models";
import { IndiceService } from "src/app/_services/indices.service";
import { OtimizadorService } from "src/app/_services/otimizador.service";

@Component({
  selector: "app-var-fundos-carteira",
  templateUrl: "./var-fundos-carteira.component.html",
  styleUrls: ["./var-fundos-carteira.component.scss"],
})
export class VarFundosCarteiraComponent implements OnInit {
  _investimentos = new Subject<Carteira[]>();
  cnpjFundos$ = new Subject<string[]>();

  columns = ["nome", "benchmark", "sharpe", "var", "vol3", "vol6", "vol12"];
  columnsSharpe = ["nome", "sharpe"];

  columnsProvisao = [
    "nome",
    "cnpj",
    "benchmark",
    "saldo",
    "var",
    "vol12",
    "valorDU",
  ];

  percentualAceitavelPerda: number;

  dataEntrada$ = new BehaviorSubject<string>(null);
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value);
  }

  @Output()
  loadingStatus = new EventEmitter<boolean>();

  database: moment.Moment = moment();

  @Input() set investimentos(value: Carteira[]) {
    this._investimentos.next(value);
    this.cnpjFundos$.next(value.map((c) => c.cnpjFundo));
  }

  maiorSharpe: any;
  menorVar: any;

  @Input()
  laminaResumo: boolean = false;

  indices: any[] = [];
  provisao: any[] = [];

  saldoTotal: number;
  provisaoPerda: number;
  percentualPerda: number;

  sharpeGeral: number = 0;
  varGeral: number = 0;

  constructor(
    private otimizadorService: OtimizadorService,
    private indiceService: IndiceService
  ) {}

  ngOnInit() {
    this.dataEntrada$.subscribe((value) => {
      this.database = moment(value, "DD/MM/YYYY").endOf("month");
    });

    this.indiceService
      .buscarParametro("PERCENTUAL_PERDA")
      .subscribe((parametro: any) => {
        if (parametro) {
          const { value } = parametro;
          try {
            this.percentualAceitavelPerda = +value / 100;
          } catch (err) {
            this.percentualAceitavelPerda = 0;
          }
        }
      });

    if (this.laminaResumo) {
      this.columns = ["nome", "saldo", "var"];
    }

    // this._investimentos.subscribe((value) => {
    //   console.log(value);
    // });
    this.cnpjFundos$
      .pipe(
        switchMap((cnpjs: string[]) => {
          this.loadingStatus.emit(false);
          const req = {
            database: this.database.format("MM/YYYY"),
            obs: 12,
            fundos: cnpjs.map((cnpj) => ({ cnpj, min: 0, max: 22 })),
          };
          return this.otimizadorService.ranking(
            req.database,
            req.obs,
            req.fundos
          );
        }),
        withLatestFrom(this._investimentos)
      )
      .subscribe(([ret, carteira]) => {
        this.indices = ret.map((rank: Ranking) => {
          return {
            ...rank,
            vol12: rank.desvpad * Math.sqrt(rank.diasUteis),
            vol6: rank.desvpad6m * Math.sqrt(rank.diasUteis6m),
            vol3: rank.desvpad3m * Math.sqrt(rank.diasUteis3m),
          };
        });

        this.sharpeGeral =
          this.indices.reduce((acc, item) => acc + item.sharpe, 0) /
          this.indices.length;
        this.varGeral =
          this.indices.reduce((acc, item) => acc + item.var, 0) /
          this.indices.length;

        if (this.laminaResumo) {
          if (Array.isArray(this.indices) && this.indices.length > 0) {
            this.menorVar = this.indices.sort((a, b) => a.var - b.var)[0];
            this.maiorSharpe = this.indices.sort(
              (a, b) => b.sharpe - a.sharpe
            )[0];
          }

          this.indices = carteira
            .map((cart) => {
              const indice = this.indices.find(
                (ind) => ind.cnpj == cart.cnpjFundo
              );
              if (!indice) {
                return null;
              }
              return { ...indice, saldo: cart.saldo };
            })
            .filter((c) => c);
        }

        this.provisao = carteira
          .map((cart) => {
            const indice = this.indices.find(
              (ind) => ind.cnpj == cart.cnpjFundo
            );
            if (!indice) {
              return null;
            }
            return {
              ...indice,
              saldo: cart.saldo,
              saldoDU: cart.saldo * indice.var,
            };
          })
          .filter((c) => c && c.saldo !== 0);

        this.saldoTotal = carteira.reduce((acc, c) => acc + c.saldo, 0);
        this.provisaoPerda = this.provisao.reduce(
          (acc, c) => acc + c.saldoDU,
          0
        );
        this.percentualPerda = this.provisaoPerda / this.saldoTotal;
        this.loadingStatus.emit(true);
      });
  }
}
