import { Component, OnInit, Input, AfterViewInit, AfterContentInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AcoesCliente } from 'src/app/_models/cliente.model';

@Component({
  selector: 'app-acoes-cliente',
  templateUrl: './acoes-cliente.component.html',
  styleUrls: ['./acoes-cliente.component.css']
})
export class AcoesClienteComponent implements OnInit, AfterContentInit {

  @Input("acoes") control: FormControl;

  acoes = [
    'GERAR_APR'
  ]

  acoesCliente: AcoesCliente[] = [];

  constructor() { }

  ngOnInit() {
    if(Array.isArray(this.control.value)) {
      this.acoesCliente = this.control.value;
    }
    this.control.valueChanges.subscribe((acoes:AcoesCliente[]) => {
      console.log(acoes);
      this.acoesCliente = acoes || []
    });
  }

  ngAfterContentInit() {

  }

  addItem(codigo: string) {
    if(!this.acoesCliente.find(acoes => acoes.codigoAcao === codigo)){
      this.control.setValue([...this.acoesCliente, { codigoAcao: codigo }])
    }
  }

  removeItem(codigo: string) {
    this.control.setValue(this.acoesCliente.filter(acoes => acoes.codigoAcao !== codigo))
  }

}
