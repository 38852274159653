import { Component, OnInit, ViewChild } from '@angular/core';
import { OtimizadorService, InformacoesOtimizacao } from 'src/app/_services/otimizador.service';
import { scaleLinear } from 'd3-scale';
import { Router } from '@angular/router';
import { UIService } from 'src/app/_helpers/ui.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { Ranking } from 'src/app/_models/otimizador.models';
import { InvestimentoOtimizadorAPI } from 'src/app/_models/fundo-investimento.models';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {

  ranking: Ranking[];
  scale: any;
  informacoes: InformacoesOtimizacao;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Ranking>;
  loading = true;
  columns = ['cnpj', 'nome', 'retorno', 'rankingRetorno', 'desvpad', 'rankingDesvpad', 'sharpe', 'rankingSharpe', 'var', 'rankingVar'];
  constructor(
    private otimizadorService: OtimizadorService,
    private router: Router,
    private uiService: UIService,
    private store: Store<AppState>
    ) { }

  ngOnInit() {
    this.store.select('otimizador').subscribe(
      state => {
        this.informacoes = state;
      }
    );
      if (this.informacoes) {
        const fundos: InvestimentoOtimizadorAPI[] = this.informacoes.carteira.map(
          v => ({ cnpj: v.fundo.cnpj, min: v.limiteInferior, max: v.limiteSuperior })
        );
        this.otimizadorService.ranking(
          this.informacoes.database,
          this.informacoes.numMeses,
          fundos
        ).subscribe(
          rankings => {
            this.loading = false;
            this.otimizadorService.erro = false;
            this.ranking = rankings;
            this.dataSource = new MatTableDataSource<Ranking>(this.ranking);
            this.dataSource.sort = this.sort;
            this.scale = scaleLinear<string, string>()
              .domain([1, Math.ceil(this.ranking.length / 2), this.ranking.length])
              .range(['#80deea', '#e6ee9c', '#ffab91']);
          },
          error => {
            this.otimizadorService.erro = true;
            this.router.navigate(['otimizador']);
            this.uiService.showSnackBar('Erro, verifique o período do ranking!', 'OK', 5000);
          }
        );
      } else {
        this.otimizadorService.erro = false;
        this.router.navigate(['otimizador']);
      }
  }

  color(rank){
    return this.scale(rank);
  }

}
