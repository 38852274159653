<h1 class="title">
  Informes do fundo {{ cnpj | mask : "00.000.000/0000-00" }}
</h1>

<form
  [formGroup]="form"
  *ngIf="origem == 'S'"
  (ngSubmit)="salvar()"
  style="margin-bottom: 1em"
>
  <h3><i class="far fa-calendar-plus"></i> Novo informe</h3>
  <div fxLayout="column">
    <div fxFlex="10" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
      <mat-form-field fxFlex="22">
        <input
          matInput
          [matDatepicker]="picker"
          required
          readonly
          formControlName="dataCompetencia"
          placeholder="Selecione a data da competência"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="22">
        <input
          autocomplete="none"
          matInput
          #valorQuota
          required
          currencyMask
          [options]="{
            prefix: 'R$ ',
            thousands: '.',
            decimal: ',',
            precision: 8
          }"
          formControlName="valorQuota"
          placeholder="Valor quota"
          aria-label="Valor quota"
        />
        <mat-error *ngIf="!valorQuota.valid"
          >Informe o valor da quota do fundo</mat-error
        >
      </mat-form-field>
      <mat-form-field fxFlex="22">
        <input
          autocomplete="none"
          matInput
          #valorTotal
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          formControlName="valorTotal"
          placeholder="Valor Total"
          aria-label="Valor total"
        />
        <mat-error *ngIf="!valorTotal.valid"
          >Informe o valor total do fundo</mat-error
        >
      </mat-form-field>
      <mat-form-field fxFlex="22">
        <input
          autocomplete="none"
          #patrimonio
          matInput
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          formControlName="valorPatrimonioLiquido"
          placeholder="Valor do patrimonio líquido"
          aria-label="Patrimonio líquido"
        />
        <mat-error *ngIf="!patrimonio.valid"
          >Informe o patrimonio líquido do fundo</mat-error
        >
      </mat-form-field>
    </div>
    <div fxFlex="10" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
      <mat-form-field fxFlex="22">
        <input
          autocomplete="none"
          matInput
          #captacao
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          formControlName="captacaoDia"
          placeholder="Captação"
          aria-label="Captação"
        />
        <mat-error *ngIf="!captacao.valid"
          >Informe o valor da captação do dia</mat-error
        >
      </mat-form-field>
      <mat-form-field fxFlex="22">
        <input
          autocomplete="none"
          matInput
          #resgate
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          formControlName="resgateDia"
          placeholder="Resgate"
          aria-label="Resgate"
        />
        <mat-error *ngIf="!resgate.valid">Informe o valor de resgate</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="22">
        <input
          autocomplete="none"
          type="number"
          #ncotas
          matInput
          formControlName="numCotasTotal"
          placeholder="Num. Cotas Total"
          aria-label="Num. Cotas Total"
        />
        <mat-error *ngIf="!ncotas.valid"
          >Informe o número de cotas total</mat-error
        >
      </mat-form-field>
      <button
        fxFlex="22"
        fxFlexAlign="center"
        fxFlexAlign.lt-md="stretch"
        matTooltip="Inserir informe ao fundo"
        mat-raised-button
        color="primary"
      >
        <i class="fas fa-plus-circle"></i> Adicionar
      </button>
    </div>
  </div>
</form>
<h3><i class="fas fa-database"></i> Últimos informes do fundo</h3>
<form
  [formGroup]="formFiltro"
  (ngSubmit)="atualizarLista(true)"
  style="margin-bottom: 1em"
>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
    <div fxFlex="36" fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="50">
        <input
          matInput
          [matDatepicker]="pickerInicio"
          readonly
          formControlName="inicio"
          placeholder="Início"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerInicio"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerInicio></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input
          matInput
          [matDatepicker]="pickerFim"
          readonly
          formControlName="fim"
          placeholder="Fim"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFim"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerFim></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="24" fxFlex.lt-lg="32" fxLayout="row" fxLayoutGap="10px">
      <button
        fxFlex="60"
        fxFlexAlign="center"
        fxFlexAlign.lt-md="stretch"
        matTooltip="Filtrar informes pela data"
        mat-stroked-button
        color="primary"
      >
        <i class="fas fa-filter"></i> Filtrar
      </button>
      <button
        fxFlex="40"
        type="button"
        (click)="formFiltro.reset(); atualizarLista()"
        fxFlexAlign="center"
        fxFlexAlign.lt-md="stretch"
        matTooltip="Limpar filtros"
        mat-stroked-button
        color="warn"
      >
        <i class="fas fa-times"></i> Limpar
      </button>
    </div>
  </div>
</form>
<mat-spinner [diameter]="45" *ngIf="loading | async"></mat-spinner>
<table mat-table [dataSource]="informes" [hidden]="loading | async">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Data</th>
    <td mat-cell *matCellDef="let informe">
      <span class="mobile-label">Data:</span>
      {{ informe.dataCompetencia | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valor">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">Valor total</th>
    <td mat-cell *matCellDef="let informe">
      <span class="mobile-label">Valor total:</span>
      {{ informe.valorTotal | currency : "BRL" : "symbol" : "0.2-8" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valorQuota">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Valor quota</th>
    <td mat-cell *matCellDef="let informe">
      <span class="mobile-label">Valor quota:</span>
      <strong>{{
        informe.valorQuota | currency : "BRL" : "symbol" : "0.2-9"
      }}</strong>
    </td>
  </ng-container>
  <ng-container matColumnDef="patrimonioLiquido">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">
      Patrimônio Liquido
    </th>
    <td mat-cell *matCellDef="let informe">
      <span class="mobile-label">Patrimônio Liquido:</span>
      <strong>{{
        informe.valorPatrimonioLiquido | currency : "BRL" : "symbol" : "0.2-9"
      }}</strong>

      <!-- Ícone de edição para abrir o modal -->
      <button mat-icon-button (click)="abrirModal(informe)">
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="numCotistas">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Num. Cotistas</th>
    <td mat-cell *matCellDef="let informe">
      <span class="mobile-label">Num. Cotistas:</span>
      {{ informe.numCotasTotal }}
    </td>
  </ng-container>
  <ng-container matColumnDef="periodo">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Periodo</th>
    <td mat-cell *matCellDef="let informe">
      <span class="mobile-label">Periodo:</span>
      {{ informe.periodo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="remover">
    <th mat-header-cell *matHeaderCellDef style="width: 5%">Remover</th>
    <td mat-cell *matCellDef="let informe; let i = index">
      <span class="mobile-label">Remover:</span>
      <button
        type="button"
        matTooltip="Remover informe"
        (click)="removerInforme(informe, i)"
        color="warn"
        mat-icon-button
      >
        <i class="fas fa-times"></i>
      </button>
    </td>
  </ng-container>
</table>
