import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FundoInvestimentoService } from "src/app/_services/fundo-investimento.service";
import { switchMap } from "rxjs/operators";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { ConjuntoService } from "src/app/_services/conjunto.service";
import { UIService } from "src/app/_helpers/ui.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ClienteService } from "src/app/_services/cliente.service";
import { Cliente } from "src/app/_models/cliente.model";
import { FIList } from "src/app/_models/fundo-investimento.models";
import { Conjunto } from "src/app/_models/conjuntos.model";

@Component({
  selector: "app-novo-conjunto",
  templateUrl: "./novo-conjunto.component.html",
  styleUrls: ["./novo-conjunto.component.scss"],
})
export class NovoConjuntoComponent implements OnInit {
  TipoUsuario = TipoUsuario;

  filtroFundo: BehaviorSubject<string>;
  fundos$: Observable<FIList[]>;
  fundoSelecionado: FIList;

  filtroCliente: BehaviorSubject<string>;
  clientes$: Observable<Cliente[]>;
  clienteSelecionado: Cliente;

  conjunto: FIList[];
  clientes: Cliente[];

  idConjunto: number;

  form: FormGroup;

  constructor(
    private fundoInvestimentoService: FundoInvestimentoService,
    private fb: FormBuilder,
    private authService: AuthService,
    private conjuntoService: ConjuntoService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private clienteService: ClienteService
  ) {
    this.filtroFundo = new BehaviorSubject("");
    this.filtroCliente = new BehaviorSubject("");
    this.form = fb.group({
      nome: ["", Validators.required],
      disponivelUsuarios: false,
    });
  }

  ngOnInit() {
    this.idConjunto = +this.route.snapshot.paramMap.get("id");

    if (this.idConjunto) {
      this.conjuntoService
        .getConjunto(this.idConjunto)
        .subscribe((conjunto) => this.form.patchValue(conjunto));
      this.conjuntoService
        .getFundosConjunto(this.idConjunto)
        .subscribe((fundos) => (this.conjunto = fundos));

      this.conjuntoService
        .getClientesConjunto(this.idConjunto)
        .subscribe((clientes) => (this.clientes = clientes));
    }

    this.fundos$ = this.filtroFundo.pipe(
      switchMap((value) => this.fundoInvestimentoService.filtroFundo(value))
    );

    this.clientes$ = this.filtroCliente.pipe(
      switchMap((value) => {
        return this.clienteService.listarCliente(value);
      })
    );
    this.conjunto = [];
    this.clientes = [];
  }

  salvarConjunto() {
    const obj = <Conjunto>{
      ...this.form.value,
      fundos: this.conjunto.map((c) => c.cnpj), // remove formatação do cnpj
      idUsuario: this.authService.getUsuarioLogado().idUser,
    };
    if (obj.disponivelUsuarios) {
      obj.clientes = this.clientes.map((c) => c.idCliente);
    }

    let observable: Observable<any>;
    if (this.idConjunto) {
      observable = this.conjuntoService.atualizarConjunto(this.idConjunto, obj);
    } else {
      observable = this.conjuntoService.salvarConjunto(obj);
    }

    this.uiService
      .abrirDialogLoading(
        observable,
        "Salvando conjunto...",
        "Conjunto salvo com sucesso",
        "Erro ao salvar, tente novamente"
      )
      .subscribe((result) => {
        if (result) {
          this.router.navigate(["conjuntos"]);
        }
      });
  }

  handleFiltro = (valor: string) => this.filtroFundo.next(valor);
  displayFundo = (fundo: FIList) =>
    fundo ? `${fundo.cnpj} - ${fundo.nome}` : "";
  selectFundo = (fundo: FIList) => (this.fundoSelecionado = fundo);
  removerFundo = (index: number) => this.conjunto.splice(index, 1);
  adicionarFundo = () => {
    this.filtroFundo.next("");
    if (
      this.fundoSelecionado &&
      !this.conjunto.map((f) => f.cnpj).includes(this.fundoSelecionado.cnpj)
    ) {
      this.conjunto.push(this.fundoSelecionado);
    }
  };

  handleFiltroCliente = (valor: string) => this.filtroFundo.next(valor);
  displayCliente = (cliente: Cliente) => (cliente ? `${cliente.nome}` : "");
  selectCliente = (cliente: Cliente) => (this.clienteSelecionado = cliente);
  removerCliente = (index: number) => this.clientes.splice(index, 1);
  adicionarCliente = () => {
    this.filtroCliente.next("");
    if (
      this.clienteSelecionado &&
      !this.clientes
        .map((f) => f.idCliente)
        .includes(this.clienteSelecionado.idCliente)
    ) {
      this.clientes.push(this.clienteSelecionado);
    }
  };
}
