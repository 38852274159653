<h1 class="title">Metas</h1>
  <div>
    <div class="acoes">

      <mat-form-field>
        <input #in (input)="datasource.queryBy({search: in.value})" matInput placeholder="Filtro">
      </mat-form-field>

      <button routerLink="novo" mat-raised-button color="accent">
        <mat-icon>playlist_add</mat-icon> Nova meta
      </button>

    </div>

    <table mat-table [dataSource]="datasource">
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let benchmark"> 
          <span class="mobile-label">Nome:</span>
          <a [routerLink]="['editar', benchmark.idMeta]">{{benchmark.nome}}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef> Data </th>
        <td mat-cell *matCellDef="let benchmark"> {{benchmark.quotes[0]?.data | date:'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="percentualMes">
        <th mat-header-cell *matHeaderCellDef> Percentual mês </th>
        <td mat-cell *matCellDef="let benchmark"> {{(benchmark.quotes[0]?.percentualMes / 100) | percent:'1.4-4'}}</td>
    </ng-container>

    <ng-container matColumnDef="percentualAno">
        <th mat-header-cell *matHeaderCellDef> Percentual ano </th>
        <td mat-cell *matCellDef="let benchmark"> {{(benchmark.quotes[0]?.percentualAno / 100) | percent:'1.4-4'}}</td>
    </ng-container>

    <ng-container matColumnDef="percentual3">
        <th mat-header-cell *matHeaderCellDef> Percentual 3M </th>
        <td mat-cell *matCellDef="let benchmark"> {{(benchmark.quotes[0]?.percentual3 / 100) | percent:'1.4-4'}} </td>
    </ng-container>
    
    <ng-container matColumnDef="percentual6">
        <th mat-header-cell *matHeaderCellDef> Percentual 6M </th>
        <td mat-cell *matCellDef="let benchmark"> {{(benchmark.quotes[0]?.percentual6 / 100) | percent:'1.4-4'}}</td>
    </ng-container>

    <ng-container matColumnDef="percentual12">
        <th mat-header-cell *matHeaderCellDef> Percentual 12M </th>
        <td mat-cell *matCellDef="let benchmark"> {{(benchmark.quotes[0]?.percentual12 / 100) | percent:'1.4-4'}}</td>
    </ng-container>

    <ng-container matColumnDef="percentual18">
        <th mat-header-cell *matHeaderCellDef> Percentual 18M </th>
        <td mat-cell *matCellDef="let benchmark"> {{(benchmark.quotes[0]?.percentual18 / 100) | percent:'1.4-4' }}</td>
    </ng-container>

    <ng-container matColumnDef="percentual24">
        <th mat-header-cell *matHeaderCellDef> Percentual 24M </th>
        <td mat-cell *matCellDef="let benchmark"> {{(benchmark.quotes[0]?.percentual24 / 100) | percent:'1.4-4'}}</td>
    </ng-container>
    <ng-container matColumnDef="apagar">
      <th mat-header-cell *matHeaderCellDef style="text-align: right;">Apagar</th>
      <td mat-cell *matCellDef="let benchmark" style="text-align: right;">
        <span class="mobile-label">Apagar:</span>
        <button mat-icon-button matTooltip="Abrir configurações para gráficos" (click)="abrirDialogGrafico(benchmark)" color="primary" style="text-align: right;">
          <i class="fas fa-chart-line"></i>
        </button>
        <button mat-raised-button matTooltip="Remover metas" (click)="removerMetas(benchmark)" color="warn" style="text-align: right;">
          <i class="fas fa-times"></i>
        </button>
      </td>
    </ng-container>
    </table>
    <div class="spinner-container" *ngIf="datasource.loading$ | async">
      <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
    </div>
  
    <mat-paginator *ngIf="datasource.page$ | async as page"
    [length]="page.totalElements" [pageSize]="page.size"
    [pageIndex]="page.number" [hidePageSize]="true" 
    (page)="datasource.fetch($event.pageIndex)">
  </mat-paginator>
  </div>