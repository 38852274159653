import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UIService } from 'src/app/_helpers/ui.service';
import { BlackListService } from "src/app/_services/blacklist.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {


  constructor(
    private blackListService:BlackListService,
    private uiService: UIService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    // this.idCliente = this.route.snapshot.paramMap.get("idCliente");
  }



}
