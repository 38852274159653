<div style="display: flex; flex-grow: 0; align-items: center;">
  <h1 style="flex: 1;">APRs Pendentes</h1>
  <button mat-flat-button (click)="lancarTodasAPRs()" matTooltip="Confirmar todas as APRs da página atual" color="warn"><i class="fas fa-check-circle"></i>Confirmar todas as APRs</button>
</div>
<table mat-table [dataSource]="datasource" style="clear: both; margin-top: 5px;">
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">ID:</span>
      <a [routerLink]="['editar', element.id]">{{ element.id }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="fundo">
    <th mat-header-cell *matHeaderCellDef style="width: 30%;">Fundo</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Fundo:</span>
      ({{ element.cnpjFundo }}) - {{ element.nomeFundo }}
    </td>
  </ng-container>
  <ng-container matColumnDef="conta">
    <th mat-header-cell *matHeaderCellDef>Conta</th>
    <td mat-cell *matCellDef="let element" style="font-size: 10px;">
      <span class="mobile-label">Conta:</span>
      Banco: {{ element.banco || "N/I" }}<br />
      Agência: {{ element.agencia || "N/I" }}<br />
      Conta: {{ element.conta || "N/I" }}<br />
    </td>
  </ng-container>
  <ng-container matColumnDef="cliente">
    <th mat-header-cell *matHeaderCellDef>Cliente</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Cliente:</span>
      ({{ element.cliente.cnpj }}) - {{ element.cliente.nome }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Status:</span>
      {{ element.status }}
    </td>
  </ng-container>
  <ng-container matColumnDef="operacao">
    <th mat-header-cell *matHeaderCellDef>Operação</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Operação:</span>
      {{ element.operacao | movimentoCarteira }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valor">
    <th mat-header-cell *matHeaderCellDef>Valor</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Valor:</span>
      {{ element.valor | currency: "BRL" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef>Data</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Date:</span>
      {{ element.dataOperacao | date: "dd/MM/yyyy" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="geracao">
    <th mat-header-cell *matHeaderCellDef style="text-align: right;">Ações:</th>
    <td mat-cell *matCellDef="let element" style="text-align: right;">
      <span class="mobile-label">Ações:</span>
      <button
        mat-icon-button
        color="primary"
        matTooltip="Abrir o documento"
        color="primary"
        (click)="geracaoPDF(element.id)"
      >
        <i class="fas fa-file-pdf"></i>
      </button>
      <button
        mat-flat-button
        color="primary"
        matTooltip="Movimentar"
        color="primary"
        (click)="lancarApr(element)"
      >
        <i class="fas fa-exchange-alt"></i>Confirmar movimento
      </button>
    </td>
  </ng-container>
</table>
<div class="spinner-container" *ngIf="datasource.loading$ | async">
  <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
</div>
<mat-paginator
  *ngIf="datasource.page$ | async as page"
  [length]="page.totalElements"
  [pageSize]="page.size"
  [pageIndex]="page.number"
  [hidePageSize]="true"
  (page)="datasource.fetch($event.pageIndex)"
>
</mat-paginator>
