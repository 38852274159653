<h1 class="title">Simulador de Ciclo de vida</h1>

<form [formGroup]="form" (ngSubmit)="simularCicloVida()">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input
        #capitalInvestido
        matInput
        currencyMask
        formControlName="capitalInvestido"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        placeholder="Capital Investido"
        required
        aria-label="Capital Investido"
      />
      <mat-error *ngIf="!capitalInvestido.valid">Informe o valor do capital investido</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input
        #retiradaAnualPrevista
        matInput
        currencyMask
        formControlName="retiradaAnualPrevista"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        placeholder="Retirada Anual Prevista"
        required
        aria-label="Retirada Anual Prevista"
      />
      <mat-error *ngIf="!retiradaAnualPrevista.valid">Informe o valor da Retirada Anual Prevista</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input
        #jurosReaisAnual
        matInput
        currencyMask
        formControlName="jurosReaisAnual"
        [options]="{ suffix: '% ', thousands: '.', decimal: ',',prefix:'' }"
        placeholder="Juros Reais Anual"
        required
        aria-label="Juros Reais Anual"
      />
      <mat-error *ngIf="!jurosReaisAnual.valid">Informe o percentual de Juros Reais</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column">
    <button fxFlex="20" type="submit" [disabled]="form.invalid" mat-raised-button color="primary"><i class="far fa-certificate"></i>Simular</button>
  </div>


</form>
<br/>
<mat-divider></mat-divider>

<div *ngIf="dados_calculados">
<h3 class="title">Dados informados:</h3>

<h4>Capital investido: R$ {{cicloVida.capitalInvestido | currency:"BRL"}}</h4>
<h4>Retirada anual prevista: R$ {{cicloVida.retiradaAnualPrevista | currency:"BRL"}}</h4>
<h4>Juros Reais Anual: {{cicloVida.jurosReaisAnual | currency:"BRL"}}%</h4>

<h3 class="title">Fluxo de Resgate:</h3>
<div fxLayout="row" fxLayout.lt-md="column">
  <table mat-table [dataSource]="fluxosResgate" >
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    <ng-container matColumnDef="ano">
      <th mat-header-cell *matHeaderCellDef> Ano </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Ano:</span>
        {{element.ano}}
      </td>
    </ng-container>
    <ng-container matColumnDef="saldoAtualizado">
      <th mat-header-cell *matHeaderCellDef> Saldo Atualizado </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Saldo Atualizado:</span>
        {{element.saldoAtualizado | currency:"BRL"}}
      </td>
    </ng-container>
  </table>

</div>

<h3 class="title">Tempo real de duração do investimento: {{cicloVida.tempoRealInvestimento}} anos</h3>

</div>


