<h1 class="title">Análise de Relatórios</h1>

<button
  *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
  mat-flat-button
  color="primary"
  (click)="abrirDialogNovo()"
>
  <i class="fas fa-plus-circle"></i> Adicionar Análise
</button>
<br />
<br />
<div *ngIf="loading" class="spinner">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<table mat-table [dataSource]="datasource" *ngIf="!loading">
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>

  <ng-container matColumnDef="desc">
    <th mat-header-cell *matHeaderCellDef>Descrição</th>
    <td
      mat-cell
      *matCellDef="let element"
      _hover
      style="cursor: pointer"
      (click)="OpenViewAnalisys(element)"
    >
      {{ element.descricao }}
    </td>
  </ng-container>

  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef>Data</th>
    <td
      mat-cell
      *matCellDef="let element"
      _hover
      style="cursor: pointer"
      (click)="OpenViewAnalisys(element)"
    >
      <span class="mobile-label">Data</span>
      {{ element.data | date : "MM/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="clientes">
    <th mat-header-cell *matHeaderCellDef>Disponível para Clientes</th>
    <td
      mat-cell
      *matCellDef="let element"
      _hover
      style="cursor: pointer"
      (click)="OpenViewAnalisys(element)"
    >
      {{
        element.todos === "N"
          ? "Apenas Clientes Selecionados"
          : element.todos === "S"
          ? "Todos os Clientes"
          : ""
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Ações</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>
      <button
        mat-icon-button
        matTooltip="Vincular Clientes"
        (click)="vincularClientes(element.id)"
        *ngIf="element.todos === 'N'"
        color="primary"
      >
        <i class="fas fa-users"></i>
      </button>
      <button
        mat-icon-button
        matTooltip="Excluir Análise"
        (click)="apagarDocumento(element.id)"
        color="warn"
      >
        <i class="fas fa-trash"></i>
      </button>
    </td>
  </ng-container>
</table>
<div class="spinner-container" *ngIf="loading | async">
  <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
</div>
