<h1>Meta {{meta ? meta.nome : formBenchmark.get('nome').value}}</h1>

<form  [formGroup]="formBenchmark" style="display: block;">
    <mat-form-field *ngIf="!idMeta">
        <input #nome matInput formControlName="nome" placeholder="Nome" required aria-label="Nome">
        <mat-error *ngIf="!nome.valid">Informe nome</mat-error>
    </mat-form-field>
    <span class="fill-space"></span>
    <button type="button" style="float: right; margin-bottom: 1em;" (click)="salvar()" mat-raised-button color="warn"><mat-icon>save</mat-icon> Salvar dados</button>
</form>

<form [formGroup]="form" style="display: block; clear: both; margin-top: 1em;" (ngSubmit)="adicionarQuota()">
    <div fxLayout="row" fxLayout.lt-md="column">
        <mat-form-field fxFlex="auto">
            <input matInput [matDatepicker]="picker" readonly required formControlName="data" placeholder="Selecione a data">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex="auto">
            <input #percentualMes matInput formControlName="percentualMes" required [dropSpecialCharacters]="false" placeholder="Percentual mês" aria-label="Percentual mês" mask="separator.4"   [allowNegativeNumbers]="true">
            <mat-error *ngIf="!percentualMes.valid">Informe o valor Percentual do mês</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto">
            <input #percentualAno matInput formControlName="percentualAno" required [dropSpecialCharacters]="false" placeholder="Percentual ano" aria-label="Percentual ano" mask="separator.4"   [allowNegativeNumbers]="true">
            <mat-error *ngIf="!percentualAno.valid">Informe o valor Percentual Ano</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto">
            <input #percentual3 matInput formControlName="percentual3" required placeholder="Percentual 3M" [allowNegativeNumbers]="true"  [dropSpecialCharacters]="false" aria-label="Percentual 3 meses" mask="separator.4"  >
            <mat-error *ngIf="!percentual3.valid">Informe o valor Percentual 3M</mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <mat-form-field fxFlex="auto">
            <input #percentual6 matInput formControlName="percentual6" required [dropSpecialCharacters]="false" [allowNegativeNumbers]="true"  placeholder="Percentual 6M" aria-label="Percentual 6M"  mask="separator.4"  >
            <mat-error *ngIf="!percentual6.valid">Informe o valor Percentual 6M</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto">
            <input #percentual12 matInput formControlName="percentual12" required [dropSpecialCharacters]="false" [allowNegativeNumbers]="true"  placeholder="Percentual 12M" aria-label="Percentual 12M" mask="separator.4" >
            <mat-error *ngIf="!percentual12.valid">Informe o valor Percentual 12M</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto">
            <input #percentual18 matInput formControlName="percentual18" required placeholder="Percentual 18M" [allowNegativeNumbers]="true"  [dropSpecialCharacters]="false" aria-label="Percentual 18M" mask="separator.4" >
            <mat-error *ngIf="!percentual18.valid">Informe o valor Percentual 18M</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto">
            <input #percentual24 matInput formControlName="percentual24" required [dropSpecialCharacters]="false" [allowNegativeNumbers]="true"  placeholder="Percentual 24M" aria-label="Percentual 24"  mask="separator.4" >
            <mat-error *ngIf="!percentual24.valid">Informe o valor Percentual 24M</mat-error>
        </mat-form-field>
        
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <button fxFlex="auto" type="submit" [disabled]="!form.valid" mat-raised-button color="accent"><mat-icon>playlist_add</mat-icon> Adicionar Quota </button>
    </div>

    <table #table *ngIf="quotes" mat-table [dataSource]="quotesDatasource">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay;sticky:true"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef> Data </th>
            <td mat-cell *matCellDef="let quote"> {{quote.data | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="percentualMes">
            <th mat-header-cell *matHeaderCellDef> Percentual mês </th>
            <td mat-cell *matCellDef="let quote"> {{quote.percentualMes}}% </td>
        </ng-container>

        <ng-container matColumnDef="percentualAno">
            <th mat-header-cell *matHeaderCellDef> Percentual ano </th>
            <td mat-cell *matCellDef="let quote"> {{quote.percentualAno}}% </td>
        </ng-container>

        <ng-container matColumnDef="percentual3">
            <th mat-header-cell *matHeaderCellDef> Percentual 3M </th>
            <td mat-cell *matCellDef="let quote"> {{(+quote.percentual3 / 100) |  percent:'1.4-4'}} </td>
        </ng-container>
        
        <ng-container matColumnDef="percentual6">
            <th mat-header-cell *matHeaderCellDef> Percentual 6M </th>
            <td mat-cell *matCellDef="let quote"> {{(+quote.percentual6 / 100) | percent:'1.4-4'}}</td>
        </ng-container>

        <ng-container matColumnDef="percentual12">
            <th mat-header-cell *matHeaderCellDef> Percentual 12M </th>
            <td mat-cell *matCellDef="let quote"> {{(+quote.percentual12 / 100) | percent:'1.4-4'}}</td>
        </ng-container>

        <ng-container matColumnDef="percentual18">
            <th mat-header-cell *matHeaderCellDef> Percentual 18M </th>
            <td mat-cell *matCellDef="let quote"> {{(+quote.percentual18 / 100) | percent:'1.4-4'}} </td>
        </ng-container>

        <ng-container matColumnDef="percentual24">
            <th mat-header-cell *matHeaderCellDef> Percentual 24M </th>
            <td mat-cell *matCellDef="let quote"> {{(+quote.percentual24 / 100) | percent:'1.4-4'}}</td>
        </ng-container>

        <ng-container matColumnDef="remover">
            <th mat-header-cell *matHeaderCellDef style="text-align: right"> Remover </th>
            <td mat-cell *matCellDef="let quote; let i = index" style="text-align: center">
                <button color="warn" mat-icon-button (click)="removerQuota(i)"><i class="fas fa-times"></i></button>
            </td>
        </ng-container>

    </table>
</form>