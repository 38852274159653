import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { delay } from "rxjs/operators";
import { Cliente } from "src/app/_models/cliente.model";
import { SortPipe } from "src/app/_pipes/sort.pipe";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { MetaService } from "src/app/_services/meta.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-relatorio-retornos-fundos-acumulado",
  templateUrl: "./relatorio-retornos-fundos-acumulado.component.html",
  styleUrls: ["./relatorio-retornos-fundos-acumulado.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RelatorioRetornosFundosAcumuladoComponent implements OnInit {
  atual = moment();
  date = new FormControl(moment().year(2024));

  columnsToDisplay = [
    "fundo",
    //"grupo",
    "benchmark",
    "mes",
    "ano",
    "3meses",
    "6meses",
    "12meses",
    "24meses",
  ];

  columnsToDisplayResumo = [
    "fundo",
    "mes",
    "ano",
    "3meses",
    "6meses",
    "12meses",
    "24meses",
  ];

  resumoDados = [];

  widthColuna = `${65 / (this.columnsToDisplay.length - 1)}%`;
  registros: any[] = [];
  idCliente: number;
  complementoTitulo = "";

  cliente: Cliente;
  zoom = new FormControl(1);

  minDate: Date;
  maxDate: Date;

  dataPrimeiroSaldo = moment();

  notLoading: boolean = true;

  constructor(
    private carteiraService: CarteiraService,
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private authService: AuthService,
    private metaService: MetaService
  ) {
    this.idCliente = +route.snapshot.paramMap.get("idCliente");
  }

  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2024, 11, 31, 23, 59, 59);
    this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
      if (willPrint) {
        window.print();
      }
    });

    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.cliente = c;
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.endOf("month"));
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.endOf("month"));
    datepicker.close();
    this.retrieveRetornos(this.date.value);
  }

  retrieveRetornos(date: Moment) {
    this.notLoading = false;
    this.carteiraService
      .getFundosCarteiraAcumulado(this.idCliente, date.format("YYYY-MM-DD"))
      .subscribe(async (v) => {
        this.notLoading = true;
        this.registros = v.lista;
        let dadosResumoChange = [];

        for (let i = 0; i < v.resumo.length; i++) {
          dadosResumoChange.push({
            ...v.resumo[i],
            anoValue: this.cortarDuasCasasDecimais(v.resumo[i].ano),
            mesValue: this.cortarDuasCasasDecimais(v.resumo[i].mes),
            meses3Value: this.cortarDuasCasasDecimais(v.resumo[i].meses3),
            meses6Value: this.cortarDuasCasasDecimais(v.resumo[i].meses6),
            meses12Value: this.cortarDuasCasasDecimais(v.resumo[i].meses12),
            meses24Value: this.cortarDuasCasasDecimais(v.resumo[i].meses24)
          });
        }

        this.resumoDados = dadosResumoChange;
        console.log("resumoDados", this.resumoDados);



        const reg = new SortPipe().transform(
          this.registros,
          "dataPrimeiroSaldo"
        )[0];
        this.dataPrimeiroSaldo = reg.dataPrimeiroSaldo;

        /*

        const rentabilidade = v;
        let rentabilidades = {
          label: "Rentabilidade Média",
          mes: rentabilidade.mes,
          ano: rentabilidade.ano,
          meses3: rentabilidade.meses3,
          meses6: rentabilidade.meses6,
          meses12: null,
          meses24: null,
        };
        resumo.push(rentabilidades);

        const meta = await this.metaService
          .getMeta(this.cliente.meta)
          .toPromise();

        const quote = meta.quotes.find((meta) => {
          return (
            moment(new Date(meta.data)).month() === this.date.value.month() &&
            moment(new Date(meta.data)).year() === this.date.value.year()
          );
        });

        let meta_ano = 1;

        const data_base = new Date(this.date.value.year(), 0, 1);

        if (moment(this.date.value).month() > moment(data_base).month()) {
          const curr_date = data_base;

            const diff = moment(this.date.value).diff(
            moment(data_base),
            "month",
            true
          );

        for (let i = 1; i < diff; i++) {
            const quote_ano = meta.quotes.find((meta) => {
              return (
                moment(new Date(meta.data)).month() ===
                  moment(curr_date).month() &&
                moment(new Date(meta.data)).year() === moment(curr_date).year()
              );
            });

            meta_ano *= (quote_ano?.percentualMes || 0) / 100 + 1;

            curr_date.setMonth(curr_date.getMonth() + 1);
        }
          // while (moment(curr_date).month() <= moment(this.date.value).month()) {
          //   const quote_ano = meta.quotes.find((meta) => {
          //     return (
          //       moment(new Date(meta.data)).month() ===
          //         moment(curr_date).month() &&
          //       moment(new Date(meta.data)).year() === moment(curr_date).year()
          //     );
          //   });

          //   meta_ano *= (quote_ano?.percentualMes || 0) / 100 + 1;

          //   curr_date.setMonth(curr_date.getMonth() + 1);
          // }

          meta_ano = (meta_ano - 1) * 100;
        } else {
          meta_ano = quote.percentualAno;
        }

        let meta_3m = 1;

        let dat_3m = moment(this.date.value).add(-3, "month");
        const data_base_3m = new Date(dat_3m.year(), dat_3m.month(), 1);

        if (moment(this.date.value).diff(moment(data_base_3m), "month") !== 0) {
          const curr_date = data_base_3m;

          const diff = moment(this.date.value).diff(
            moment(data_base_3m),
            "month",
            true
          );

          for (let i = 1; i < diff; i++) {
            const quote_mes3 = meta.quotes.find((meta) => {
              return (
                moment(new Date(meta.data)).month() ===
                  moment(curr_date).add(i, "month").month() &&
                moment(new Date(meta.data)).year() ===
                  moment(curr_date).add(i, "month").year()
              );
            });

            meta_3m *= (quote_mes3 ? quote_mes3.percentualMes : 0) / 100 + 1;
          }

          meta_3m = (meta_3m - 1) * 100;
        } else {
          meta_3m = quote.percentual3;
        }
        let meta_6m = 1;

        let dat_6m = moment(this.date.value).add(-6, "month");
        const data_base_6m = new Date(dat_6m.year(), dat_6m.month(), 1);

        if (moment(this.date.value).diff(moment(data_base_6m), "month") !== 0) {
          const curr_date = data_base_6m;

          const diff = moment(this.date.value).diff(
            moment(data_base_6m),
            "month",
            true
          );

          for (let i = 1; i < diff; i++) {
            const quote_mes6 = meta.quotes.find((meta) => {
              return (
                moment(new Date(meta.data)).month() ===
                  moment(curr_date).add(i, "month").month() &&
                moment(new Date(meta.data)).year() ===
                  moment(curr_date).add(i, "month").year()
              );
            });

            meta_6m *= (quote_mes6 ? quote_mes6.percentualMes : 0) / 100 + 1;
          }

          meta_6m = (meta_6m - 1) * 100;
        } else {
          meta_6m = quote.percentual6;
        }

        let meta_12m = 1;

        let dat_12m = moment(this.date.value).add(-12, "month");
        const data_base_12m = new Date(dat_12m.year(), dat_12m.month(), 1);

        if (moment(this.date.value).diff(moment(data_base_12m), "month") !== 0) {
          const curr_date = data_base_12m;

          const diff = moment(this.date.value).diff(
            moment(data_base_12m),
            "month",
            true
          );

          for (let i = 1; i < diff; i++) {
            const quote_mes12 = meta.quotes.find((meta) => {
              return (
                moment(new Date(meta.data)).month() ===
                  moment(curr_date).add(i, "month").month() &&
                moment(new Date(meta.data)).year() ===
                  moment(curr_date).add(i, "month").year()
              );
            });

            meta_12m *= (quote_mes12 ? quote_mes12.percentualMes : 0) / 100 + 1;
          }

          meta_12m = (meta_12m - 1) * 100;
        } else {
          meta_12m = quote.percentual6;
        }


        if (quote) {
          let metas = {
            label: meta.nome,
            mes: quote.percentualMes / 100,
            ano: meta_ano / 100,
            meses3: meta_3m / 100,
            meses6: meta_6m / 100,
            meses12: meta_12m / 100,
            meses24: quote.percentual24 / 100,
          };
          resumo.push(metas);

          let atingimento = {
            label: "Resultado em relação a meta",
            mes: rentabilidades.mes / metas.mes,
            ano: rentabilidades.ano / metas.ano,
            meses3: rentabilidades.meses3 / metas.meses3,
            meses6: rentabilidades.meses6 / metas.meses6,
            meses12: rentabilidades.meses12 / metas.meses12,
            meses24: rentabilidades.meses24 / metas.meses24,
          };
          resumo.push(atingimento);
        }
*/
        //  this.resumoDados = resumo;
      });
  }

  cortarDuasCasasDecimais(value) {
    var valor = value * 100;

    const valorStr = valor.toString();
    if (valorStr.includes('.')) {
      return valorStr.slice(0, valorStr.indexOf('.') + 3).replace('.', ',') + "%";
    }

    return valorStr + "%";
  }

  print() {
    this.authService.willPrint$.next(true);
  }

  isBefore(data, numero, unidade) {
    return moment(data)
      .startOf("month")
      .add(numero, unidade)
      .isSameOrBefore(this.date.value.endOf("month"));
    //return moment(data).startOf('month').add(numero, unidade).isBefore(this.date.value)
    // return this.date.value.clone().add(numero, unidade).isAfter(moment(data));
  }
}
