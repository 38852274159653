import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { UIService } from 'src/app/_helpers/ui.service';
import { Router } from '@angular/router';
import { PasswordStrengthValidator } from 'src/app/_validators/password-strength.validator';
import { Usuario } from 'src/app/_models/user.models';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  alterarSenhaForm: FormGroup;
  usuario: Usuario;

  constructor(
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private fb: FormBuilder) {
    this.alterarSenhaForm = fb.group({
      senhaAtual: ['', Validators.required],
      novaSenha: ['', [Validators.required, PasswordStrengthValidator]],
      novaSenhaConfirma: ['', [Validators.required, PasswordStrengthValidator]],
    });
    this.usuario = this.authService.getUsuarioLogado();
  }

  ngOnInit() {
  }

  alterarSenha() {
    const obj = this.alterarSenhaForm.value;
    const { senhaAtual, novaSenha, novaSenhaConfirma} = obj;
    if (novaSenha !== novaSenhaConfirma) {
      this.uiService.showSnackBar('Senhas não conferem, verifique e tente novamente.', 'OK', 4000);
      return;
    }

    this.uiService.abrirDialogLoading(
      this.authService.alterarSenha(obj),
      'Alterando senha...',
      'Senha alterada com sucesso',
      'Erro ao alterar a senha, verifique a senha atual',
    ).subscribe(value => {
      if (value) {
        this.router.navigate(['']);
      }
    });
  }

}
