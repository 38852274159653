import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder
} from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { UIService } from 'src/app/_helpers/ui.service';
import { Cliente } from 'src/app/_models/cliente.model';
import { Credenciamento, FundosAderencia } from 'src/app/_models/credenciamento.model';
import { Rating } from 'src/app/_models/rating.model';
import { Usuario } from 'src/app/_models/user.models';
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { ClienteService } from 'src/app/_services/cliente.service';
import { CredenciamentoService } from 'src/app/_services/credenciamento.service';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';

@Component({
  selector: "app-relatorio-credenciamento",
  templateUrl: "./relatorio-credenciamento.component.html",
  styleUrls: ["./relatorio-credenciamento.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RelatorioCredenciamentoComponent implements OnInit {
  TipoUsuario = TipoUsuario;

  credenciamento:Credenciamento;
  ratings:Rating[];

  rating:Rating;

  datasource : FundosAderencia[];

  columnsToDisplay = ['cnpjFundo', 'nomeFundo', 'benchmark', 'grauRisco','prazo','percentualBenchmark', 'percentualRentabilidade','aderencia'];

  idCredenciamento:number;

  cliente:Cliente;

  usuario:Usuario;

  constructor(
    private credenciamentoService:CredenciamentoService,
    private fundoInvestimentoService:FundoInvestimentoService,
    private fb: FormBuilder,
    private authService: AuthService,
    private clienteService: ClienteService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {

    this.idCredenciamento = +route.snapshot.paramMap.get("idCredenciamento");

  }

  ngOnInit() {
    this.authService.willPrint$.next(true);

    window.onafterprint = (e) => this.authService.willPrint$.next(false);

    this.authService.usuario$.subscribe(usuario => this.usuario = usuario);



    this.credenciamentoService.getRatings().subscribe(response => this.ratings = response);
    this.credenciamentoService.getCredenciamentoById(this.idCredenciamento).subscribe(response => {
      this.credenciamento = response;


      console.log(this.credenciamento);


      this.rating = this.ratings.find(r => r.id === this.credenciamento.id_rating);
      this.clienteService.buscarClientePorId(this.credenciamento.clienteId).subscribe(x=> this.cliente=x);
      });


    this.credenciamentoService.getFundosCredenciamentoById(this.idCredenciamento).subscribe(dados =>{
      let rets = new Array<FundosAderencia>();
      dados.map(d=>{
        rets.push(d);
      });
      this.datasource = rets;
    });


  }




  print() {

    window.print()
  }

}
