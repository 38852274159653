
<h1 class="title">Compliance ANBIMA</h1>
<div style="display: grid; grid-template-columns: repeat(3,1fr); gap: 10px">
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event, tipoArquivo.ACOES)"
  (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" style="flex: 1">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div style="display: block; text-align: center;">
          XML de fundos de Ações<br/>
          <mat-divider></mat-divider>
          <br/>
          <button mat-flat-button color="primary" style="display: block; width: 100%;" type="button" (click)="openFileSelector()">Selecione os arquivos</button>
        </div>
      </ng-template>
  </ngx-file-drop>
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event, tipoArquivo.MULTIMERCADO)"
  (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" style="flex: 1">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div style="display: block; text-align: center;">
          XML de fundos Multimercado<br/>
          <mat-divider></mat-divider>
          <br/>
          <button mat-flat-button color="primary" style="display: block; width: 100%;" type="button" (click)="openFileSelector()">Selecione os arquivos</button>
        </div>
      </ng-template>
  </ngx-file-drop>
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event, tipoArquivo.RENDA_FIXA)"
  (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" style="flex: 1">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div style="display: block; text-align: center;">
          XML de fundos de Renda Fixa<br/>
          <mat-divider></mat-divider>
          <br/>
          <button mat-flat-button color="primary" style="display: block; width: 100%;" type="button" (click)="openFileSelector()">Selecione os arquivos</button>
        </div>
      </ng-template>
  </ngx-file-drop>
</div>
  <br/>
  <div>
    <strong>Patrimônio Liquido: </strong>{{totalPatrimonioLiquido | currency:'BRL'}}
  </div>
  <br/>
  <mat-tab-group>
    <mat-tab label="Consolidado">
      <ng-template matTabContent>
        <br/>
        <app-FundoCompliance *ngIf="consolidada" [consolidado]="true" [fundoEntrada]="consolidada"></app-FundoCompliance>
        <div style="display: grid; grid-template-columns: 50% 50%; gap: 15px">
          <table>
            <thead>
              <tr>
                <th style="width: 50%; text-align: left;">Tipo</th>
                <th style="width: 25%; text-align: left;">Valor</th>
                <th style="width: 25%; text-align: right;">%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Fundo de Renda Fixa</td>
                <td>{{totalRendaFixa | currency:'BRL'}}</td>
                <td style="text-align: right;">{{(totalRendaFixa / totalPatrimonioLiquido) | percent:'1.4-4'}}</td>
              </tr>
              <tr>
                <td>Fundo de ações</td>
                <td>{{totalAcoes | currency:'BRL'}}</td>
                <td style="text-align: right;">{{(totalAcoes / totalPatrimonioLiquido) | percent:'1.4-4'}}</td>
              </tr>
              <tr>
                <td>Fundo Multimercado</td>
                <td>{{totalMultimercado | currency:'BRL'}}</td>
                <td style="text-align: right;">{{(totalMultimercado / totalPatrimonioLiquido) | percent:'1.4-4'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Fundo de ações">
      <ng-template matTabContent>
        <br/>
        <div *ngFor="let item of arquivos.acoes">
          <app-FundoCompliance [fundoEntrada]="item" ></app-FundoCompliance>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Fundo Multimercado">
      <ng-template matTabContent>
        <br/>
        <div *ngFor="let item of arquivos.multimercado">
          <app-FundoCompliance [fundoEntrada]="item"></app-FundoCompliance>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Fundo Renda Fixa">
      <ng-template matTabContent>
        <br/>
        <div *ngFor="let item of arquivos.rendaFixa">
          <app-FundoCompliance [fundoEntrada]="item"></app-FundoCompliance>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Compliance" *ngShow="false">
      <ng-template matTabContent>
        <br/>
        <div style="display: grid; grid-template-columns: 50% 50%; gap: 15px">
          <table>
            <thead>
              <tr>
                <th rowspan="2" style="width: 40%; text-align: left;"></th>
                <th colspan="2" style="width: 30%; text-align: center;">Alocaçao</th>
                <th colspan="3" style="width: 30%; text-align: center;">Alocação - LCT 173</th>
              </tr>
              <tr>
                  <th>R$</th>
                  <th>%</th>
                  <th>R$</th>
                  <th>%</th>
                  <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Fundo de Renda Fixa</td>
                <td>{{totalRendaFixa | currency:'BRL'}}</td>
                <td>{{(totalRendaFixa / totalPatrimonioLiquido) | percent:'1.4-4'}}</td>
                <td>{{(totalPatrimonioLiquido * percentualLimiteRendaFixa) | currency:'BRL'}}</td>
                <td>{{percentualLimiteRendaFixa | percent: '1.2-2'}}</td>
                <td
                  [class.ok]="totalRendaFixa / totalPatrimonioLiquido <= percentualLimiteRendaFixa"
                  [class.alerta]="totalRendaFixa / totalPatrimonioLiquido > percentualLimiteRendaFixa"
                >
                {{(totalRendaFixa / totalPatrimonioLiquido) > percentualLimiteRendaFixa ? 'ALERTA' : 'OK'}}
              </td>
              </tr>
              <tr>
                <td>Fundo de ações</td>
                <td>{{totalAcoes | currency:'BRL'}}</td>
                <td>{{(totalAcoes / totalPatrimonioLiquido) | percent:'1.4-4'}}</td>
                <td>{{(totalPatrimonioLiquido * percentualLimiteAcoes) | currency:'BRL'}}</td>
                <td>{{percentualLimiteAcoes | percent: '1.2-2'}}</td>
                <td
                  [class.ok]="totalAcoes / totalPatrimonioLiquido <= percentualLimiteAcoes"
                  [class.alerta]="totalAcoes / totalPatrimonioLiquido > percentualLimiteAcoes"
                >
                  {{(totalAcoes / totalPatrimonioLiquido) > percentualLimiteAcoes ? 'ALERTA' : 'OK'}}
                </td>
              </tr>
              <tr>
                <td>Fundo Multimercado</td>
                <td>{{totalMultimercado | currency:'BRL'}}</td>
                <td>{{(totalMultimercado / totalPatrimonioLiquido) | percent:'1.4-4'}}</td>
                <td>{{(totalPatrimonioLiquido * percentualLimiteMultimercado) | currency:'BRL'}}</td>
                <td>{{percentualLimiteMultimercado | percent: '1.2-2'}}</td>
                <td
                  [class.ok]="totalMultimercado / totalPatrimonioLiquido <= percentualLimiteMultimercado"
                  [class.alerta]="totalMultimercado / totalPatrimonioLiquido > percentualLimiteMultimercado"
                >
                  {{(totalMultimercado / totalPatrimonioLiquido) > percentualLimiteMultimercado ? 'ALERTA' : 'OK'}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Questionário CADPREV" >
      <ng-template matTabContent>
        <br/>
        <div style="display: grid; grid-template-columns: 50% 50%; gap: 15px">
          <table>
            <tbody>
              <tr>
                <td>1) Há ativos financeiros não emitidos por Instituição financeira?</td>
                <td
                [class.ok]="questao_1=='Não'"
                [class.alerta]="questao_1=='Sim'">{{questao_1}}</td>
              </tr>
              <tr>
                <td>2) Há ativos financeiros não emitidos por companhias abertas, operacionais e registradas na CVM?</td>
                <td
                [class.ok]="questao_2=='Não'"
                [class.alerta]="questao_2=='Sim'"
                >Não</td>
              </tr>
              <tr>
                <td>3) Há ativos financeiros emitidos por Securitizadoras, Certificados de recebíveis (CRI) ou Certificados de Recebíveis de Agronegócio (CRA)?</td>
                <td
                [class.ok]="questao_3=='Não'"
                [class.alerta]="questao_3=='Sim'">{{questao_3}}</td>
              </tr>
              <tr>
                <td>4) Há ativos financeiros que não são cotas de classe sênior de Fundo de Invetimento em Direitos Creditórios (FIDC)?</td>
                <td
                [class.ok]="questao_4=='Não'"
                [class.alerta]="questao_4=='Sim'">{{questao_4}}</td>
              </tr>
              <tr>
                <td>5) Há ativos financeiros ou que os respectivos emissores não são considerados de baixo Risco de Crédito?</td>
                <td
                [class.ok]="questao_5=='Não'"
                [class.alerta]="questao_5=='Sim'">{{questao_5}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

<div *ngIf="false">
  <mat-expansion-panel *ngFor="let item of contents" style="margin-bottom: 10px;">
    <mat-expansion-panel-header>
      <span style="flex: 1;">{{item.content.header.cnpj}} - {{item.content.header.nome}} </span>
      <span style="margin-right: 10px;">{{item.content.header.data | date:'dd/MM/yyyy'}}</span>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <app-FundoCompliance [fundo]="item.content"></app-FundoCompliance>
    </ng-template>
  </mat-expansion-panel>
</div>
