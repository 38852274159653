import { CurrencyPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartDataSets, ChartOptions } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import * as moment from "moment";
import { Color, Label } from "ng2-charts";
import { BehaviorSubject } from "rxjs";
import { delay } from "rxjs/operators";
import { Carteira } from "src/app/_models/carteira.model";
import { Cliente } from "src/app/_models/cliente.model";
import { GroupByPipe } from "src/app/_pipes/groupBy.pipe";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { DialogAjusteSaldoComponent } from "../../clientes/carteira/dialog-ajuste-saldo/dialog-ajuste-saldo.component";
import { MY_FORMATS } from "../../clientes/carteira/relatorio-retornos/relatorio-retornos.component";
import { VisualizarInvestimentoComponent } from "../../clientes/carteira/visualizar-investimento/visualizar-investimento.component";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-resumo-carteira",
  templateUrl: "./resumo-carteira.component.html",
  styleUrls: ["./resumo-carteira.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ResumoCarteiraComponent implements OnInit {
  @Input()
  relatorioDinamico: Boolean = false;

  @Input()
  columnsToAdd: string[] = [];

  @Input()
  graficoLiquidez: boolean = false;

  dataEntrada$ = new BehaviorSubject<string>(null);
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value);
  }

  @Output()
  getFundos = new EventEmitter();

  @Output()
  loadingStatus = new EventEmitter<boolean>();

  colorScheme = {
    domain: [
      "#1fd169",
      "#01579B",
      "#fbc02d",
      "#b7c9bc",
      "#a0d10f",
      "#c62828",
      "#e39b17",
    ],
  };

  columnsToDisplay = [
    "fundo",
    "resgate",
    "saldo",
    "participacao",
    "numero-cotistas",
    "percentual-patrimonio",
    "patrimonio",
    "resolucao",
    "grauRisco",
    "taxaAdm",
    "tipoFundo",
    "visualizar",
  ];

  collumnsEnquadramento = [
    "legislacao",
    "limite",
    "limitePolitica",
    "saldo",
    "percentualCarteira",
  ];

  investimentos: Carteira[];
  saldoTotal = 0;
  complementoTitulo: string = "";

  idCliente: number;

  cliente: Cliente;

  fromRoute = false;
  zoom = new FormControl(1);

  date = new FormControl(moment().year(2024));
  atual = moment();

  disponibilidadeAgrupada: any[] = [];
  seriesGrafico: any[] = [];
  agrupamentoPublicoPrivado: any[] = [];
  percentualUsolimitePublicoPrivado: number = 0;
  dadosGraficoPublicoPrivado: any[] = [];

  agrupadoPorEnquadramento: any[];
  minDate: Date;
  maxDate: Date;
  printing: boolean = false;

  loadingFundos: boolean = false;

  chartDataPublicoPrivado: ChartDataSets[];
  chartLabelsPublicoPrivado: Label[] = [];

  chartLabels: Label[] = [];
  chartData: ChartDataSets[];
  chartColors: Color[] = [
    { backgroundColor: "#FED46C", borderColor: "#FED46C" },
    { backgroundColor: "#FED46C", borderColor: "#FED46C" },
  ];

  public barChartPlugins = [pluginDataLabels];
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: (value) => new CurrencyPipe("pt-BR").transform(value, "BRL"),
      },
    },
  };

  agrupamentoPorPlanoContas: any[];
  agrupamentoPorSegmento: any[];

  checked: false;
  constructor(
    private carteiraService: CarteiraService,
    private authService: AuthService,
    private clienteService: ClienteService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2024, 11, 31, 23, 59, 59);
    if (!this.relatorioDinamico) {
      window.onafterprint = (e) => this.authService.willPrint$.next(false);
    }
    if (!this.relatorioDinamico) {
      this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
        if (willPrint) {
          window.print();
        }
      });
    }
    this.loadingStatus.emit(false);
    this.authService.usuario$.subscribe((usuario) => {
      if (!this.route.snapshot.paramMap.get("idCliente")) {
        this.idCliente = usuario.idCliente;
      } else {
        this.fromRoute = true;
        this.idCliente = +this.route.snapshot.paramMap.get("idCliente");
      }

      if (this.relatorioDinamico) {
        this.dataEntrada$.subscribe((value) => {
          const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

          const dt =
            tz !== "America/Sao_Paulo"
              ? moment(value, "DD/MM/YYYY").endOf("month").add(-1, "hours")
              : moment(value, "DD/MM/YYYY").endOf("month");
          this.date.setValue(dt);
          this.carteiraService
            .getCarteiraCliente(this.idCliente, null, dt.toDate())
            .subscribe(this.handleCarteira);
        });
      } else {
        this.carteiraService
          .getCarteiraCliente(this.idCliente)
          .subscribe(this.handleCarteira);
      }

      this.clienteService
        .buscarClientePorId(this.idCliente)
        .subscribe((cliente) => {
          this.cliente = cliente;
        });
    });

    if (this.relatorioDinamico) {
      const indexV = this.columnsToDisplay.findIndex((c) => c === "visualizar");
      this.columnsToDisplay.splice(indexV, 1);
      const indexR = this.columnsToDisplay.findIndex((c) => c === "resgate");
      this.columnsToDisplay.splice(indexR, 1);
      const indexT = this.columnsToDisplay.findIndex((c) => c === "taxaAdm");
      this.columnsToDisplay.splice(indexT, 1);
      // const indexG = this.columnsToDisplay.findIndex(c => c === 'grauRisco');
      // this.columnsToDisplay.splice(indexG, 1)
      this.columnsToDisplay = [...this.columnsToDisplay, ...this.columnsToAdd];
    }
  }

  handleCarteira = (investimentos) => {
    const total = investimentos.reduce((a, b) => a + b.saldo, 0);
    this.getFundos.emit(investimentos);

    this.investimentos = investimentos.map((investimento) => ({
      ...investimento,
      participacao: investimento.saldo / total,
      percentualPatrimonio: investimento.saldo / investimento.patrimonioLiquido,
    }));
    // .filter((item: any) => item.saldo !== 0);

    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }

    this.saldoTotal = this.investimentos.reduce(
      (acc, inv) => acc + inv.saldo,
      0
    );

    const bancos_group = new GroupByPipe().transform(
      this.investimentos,
      "conta"
    );

    this.agrupamentoPorPlanoContas = bancos_group.map(({ key, value }) => {
      const saldoGrupo = value.reduce((acc, item) => acc + item.saldo, 0);
      return {
        tipo: `Banco : ${value[0].banco} Agência: ${value[0].agencia} Conta:${value[0].conta}`,
        saldoGrupo,
        investimentos: value.filter((item: any) => item.saldo !== 0),
      };
    });

    this.agrupamentoPorSegmento = new GroupByPipe()
      .transform(this.investimentos, "tipo")
      .map(({ key, value }) => {
        const saldoGrupo = value.reduce((acc, item) => acc + item.saldo, 0);
        return {
          tipo: key,
          saldoGrupo,
          investimentos: value.filter((item: any) => item.saldo !== 0),
        };
      });

    if (this.graficoLiquidez) {
      const agrupadoEnquadramento = new GroupByPipe().transform(
        this.investimentos,
        "enquadramentoLegislacao"
      );

      this.agrupadoPorEnquadramento = agrupadoEnquadramento.map(
        ({ key, value }) => {
          const saldoGrupo = value.reduce((acc, item) => acc + item.saldo, 0);
          return {
            legislacao: key,
            limite: value[0].enquadramentoLimite,
            saldoGrupo,
            percentualGrupo: saldoGrupo / this.saldoTotal,
          };
        }
      );

      this.disponibilidadeAgrupada = this.investimentos.reduce<any[]>(
        (acc, item: Carteira) => {
          let find = acc.find(
            (c) => c.disponibilidade === (item.prazoResgate ?? "indefinido")
          );
          if (!find) {
            let obj = {
              disponibilidade: item.prazoResgate ?? "indefinido",
              totalFundos: 1,
              valorGrupo: item.saldo,
              percentual: item.saldo / this.saldoTotal,
            };
            return [...acc, obj];
          } else {
            find.totalFundos += 1;
            find.valorGrupo += item.saldo;
            find.percentual = find.valorGrupo / this.saldoTotal;
          }
          return [...acc];
        },
        []
      );

      this.disponibilidadeAgrupada.forEach((item) => {
        if (!Number.isInteger(+item.disponibilidade)) {
          return;
        }
        let dias = +item.disponibilidade;
        if (dias === 0) {
          item.aceitavel = 0.9;
        } else if (dias === 1) {
          item.aceitavel = 0.5;
        } else if (dias > 1 && dias < 30) {
          item.aceitavel = 0.5;
        } else {
          item.aceitavel = 0;
        }
      });

      //Ordenando pela disponibilidade
      const sorted = this.disponibilidadeAgrupada.sort((x, y) => {
        if (x.disponibilidade < +y.disponibilidade) {
          return 1;
        }

        if (x.disponibilidade > y.disponibilidade) {
          return -1;
        }

        return 0;
      });

      this.disponibilidadeAgrupada = sorted;
      //Fim da ordenação

      const reduceFnValor = (acc, item) => {
        return acc + item.valorGrupo;
      };

      const reduceFnPercent = (acc, item) => {
        return acc + item.percentual;
      };

      const filterFnMinMax = (item, min, max) => {
        // console.log(item.disponibilidade, !Number.isNaN(item.disponibilidade));
        if (!Number.isNaN(item.disponibilidade)) {
          return +item.disponibilidade >= min && +item.disponibilidade < max;
        }
        return false;
      };

      this.chartLabels = [
        "D + 0",
        "D + (1-2)",
        "D + 3",
        "D + (4-30)",
        "D + 31",
        "Indefinido",
      ];
      const data = [
        this.disponibilidadeAgrupada.find((c) => c.disponibilidade == "0")
          ?.valorGrupo || 0,
        this.disponibilidadeAgrupada
          .filter((c) => filterFnMinMax(c, 1, 3))
          .reduce(reduceFnValor, 0) || 0,
        this.disponibilidadeAgrupada
          .filter((c) => filterFnMinMax(c, 3, 5))
          .reduce(reduceFnValor, 0) || 0,
        this.disponibilidadeAgrupada
          .filter((c) => filterFnMinMax(c, 5, 31))
          .reduce(reduceFnValor, 0) || 0,
        this.disponibilidadeAgrupada
          .filter((c) => filterFnMinMax(c, 31, 1000))
          .reduce(reduceFnValor, 0) || 0,
        this.disponibilidadeAgrupada.find(
          (c) => c.disponibilidade == "indefinido"
        )?.valorGrupo || 0,
      ];

      this.chartData = [{ label: "", data }];

      this.seriesGrafico = [
        // { name: 'Saldo disponibilidade', series: [
        //   { name: "D + 0", value: this.disponibilidadeAgrupada.find(c => c.disponibilidade == '0')?.valorGrupo || 0 },
        //   { name: "D + 1", value: this.disponibilidadeAgrupada.filter(c => filterFnMinMax(c, 1, 3)).reduce(reduceFnValor, 0) || 0 },
        //   { name: "D + 3", value: this.disponibilidadeAgrupada.filter(c => filterFnMinMax(c, 3, 5)).reduce(reduceFnValor, 0) || 0 },
        //   { name: "D + 30", value: this.disponibilidadeAgrupada.filter(c => filterFnMinMax(c, 5, 31)).reduce(reduceFnValor, 0) || 0 },
        //   { name: "D + 31", value: this.disponibilidadeAgrupada.filter(c => filterFnMinMax(c, 31, 1000)).reduce(reduceFnValor, 0) || 0 },
        //   { name: "Indefinido", value: this.disponibilidadeAgrupada.find(c => c.disponibilidade == 'indefinido')?.valorGrupo || 0 },
        // ]},
        {
          name: "% carteira",
          series: [
            {
              name: "D + 0",
              value:
                this.disponibilidadeAgrupada.find(
                  (c) => c.disponibilidade == "0"
                )?.percentual || 0,
            },
            {
              name: "D + 1",
              value:
                this.disponibilidadeAgrupada
                  .filter((c) => filterFnMinMax(c, 1, 3))
                  .reduce(reduceFnPercent, 0) || 0,
            },
            {
              name: "D + 3",
              value:
                this.disponibilidadeAgrupada
                  .filter((c) => filterFnMinMax(c, 3, 5))
                  .reduce(reduceFnPercent, 0) || 0,
            },
            {
              name: "D + 30",
              value:
                this.disponibilidadeAgrupada
                  .filter((c) => filterFnMinMax(c, 5, 31))
                  .reduce(reduceFnPercent, 0) || 0,
            },
            {
              name: "D + 31",
              value:
                this.disponibilidadeAgrupada
                  .filter((c) => filterFnMinMax(c, 31, 1000))
                  .reduce(reduceFnPercent, 0) || 0,
            },
            {
              name: "Indefinido",
              value:
                this.disponibilidadeAgrupada.find(
                  (c) => c.disponibilidade == "indefinido"
                )?.percentual || 0,
            },
          ],
        },
      ];

      const arrInicialAgrupamento = [
        {
          tipo: "100% Títulos publicos",
          valorGrupo: 0,
          totalFundos: 0,
          percentual: 0,
        },
        {
          tipo: "50% Títulos publicos e 50% Títulos privados",
          valorGrupo: 0,
          totalFundos: 0,
          percentual: 0,
        },
        {
          tipo: "100% Títulos privados",
          valorGrupo: 0,
          totalFundos: 0,
          percentual: 0,
        },
      ];

      this.agrupamentoPublicoPrivado = this.investimentos.reduce<any[]>(
        (acc, item: Carteira) => {
          let tipo = "100% Títulos publicos";
          // console.log(item.legislacao);
          if (!item.legislacao?.startsWith("Art 7º")) {
            tipo = "100% Títulos privados";
          } else {
            if (
              item.legislacao?.startsWith("Art 7º, III") ||
              item.legislacao?.startsWith("Art 7º , IV")
            ) {
              tipo = "50% Títulos publicos e 50% Títulos privados";
            } else if (
              item.legislacao?.startsWith("Art 7º, V") ||
              item.legislacao?.startsWith("Art 7º, VI") ||
              item.legislacao?.startsWith("Art 7º, VII")
            ) {
              tipo = "100% Títulos privados";
            }
          }

          const find = acc.find((f) => f.tipo === tipo);
          if (!find) {
            let tipoAdd = {
              tipo,
              valorGrupo: item.saldo,
              totalFundos: 1,
              percentual: item.saldo / this.saldoTotal,
            };
            return [...acc, tipoAdd];
          } else {
            find.totalFundos += 1;
            find.valorGrupo += item.saldo;
            find.percentual = find.valorGrupo / this.saldoTotal;
          }
          return [...acc];
        },
        [...arrInicialAgrupamento]
      );

      const metadePercentualPublicoPrivado =
        this.agrupamentoPublicoPrivado[1].percentual / 2;
      this.agrupamentoPublicoPrivado[1].percentual =
        metadePercentualPublicoPrivado;

      this.agrupamentoPublicoPrivado[0].percentual +=
        metadePercentualPublicoPrivado;
      //this.agrupamentoPublicoPrivado[2].percentual += metadePercentualPublicoPrivado;

      this.dadosGraficoPublicoPrivado = [
        {
          name: "100% Títulos publicos",
          value: this.agrupamentoPublicoPrivado[0].percentual,
        },
        {
          name: "50% Títulos publicos e 50% Títulos privados",
          value: this.agrupamentoPublicoPrivado[1].percentual,
        },
        {
          name: "100% Títulos privados",
          value: this.agrupamentoPublicoPrivado[2].percentual,
        },
      ];

      this.chartLabelsPublicoPrivado = [
        "100% Títulos publicos",
        "50% Títulos publicos e 50% Títulos privados",
        "100% Títulos privados",
      ];
      const dtChart = [
        this.agrupamentoPublicoPrivado[0].percentual * this.saldoTotal,
        this.agrupamentoPublicoPrivado[1].percentual * this.saldoTotal,
        this.agrupamentoPublicoPrivado[2].percentual * this.saldoTotal,
      ];

      this.chartDataPublicoPrivado = [{ label: "", data: dtChart }];

      this.percentualUsolimitePublicoPrivado =
        (this.agrupamentoPublicoPrivado[1].percentual +
          this.agrupamentoPublicoPrivado[2].percentual) /
        0.3;
    }

    this.loadingStatus.emit(true);
    if (this.agrupamentoPublicoPrivado.length) {
      console.log("agrupamentoPublicoPrivado", this.agrupamentoPublicoPrivado);
      // for (
      //   let i: number = 0;
      //   i === this.agrupamentoPublicoPrivado.length;
      //   i++
      // ) {
      //   console.log("objeto.tipo", this.agrupamentoPublicoPrivado[i].tipo);
      //   console.log(
      //     "objeto.percentual",
      //     this.agrupamentoPublicoPrivado[i].percentual
      //   );
      // }
    }
  };

  abrirInvestimento = (investimento: Carteira) => {
    const dialog = this.dialog.open(VisualizarInvestimentoComponent, {
      data: {
        idCliente: investimento.idCliente,
        idInvestimento: investimento.id,
        investimento: investimento,
      },
      width: "90vw",
      height: "90vh",
    });
  };

  openNew(path) {
    const url = this.router.serializeUrl(this.router.createUrlTree(path));

    window.open(url, "_blank");
  }

  openNewExport(path, id) {
    const hash = CryptoJS.MD5(
      `${id}-${moment().format("DD/MM/YYYY")}-dinamico`
    );
    const url = `${path}/${id}/${hash}`;
    window.open(url, "_blank");
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.endOf("month"));
    //this.retrieveEnquadramentos(this.date.value);
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    this.loadingFundos = true;
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(
      tz !== "America/Sao_Paulo"
        ? ctrlValue.endOf("month").add(-1, "hours").add(-15, "day")
        : ctrlValue.endOf("month").add(-15, "day")
    );
    datepicker.close();
    this.carteiraService
      .getCarteiraCliente(
        this.idCliente,
        null,
        tz !== "America/Sao_Paulo"
          ? ctrlValue.add(-1, "hours").toDate()
          : ctrlValue.toDate()
      )
      .subscribe(this.handleCarteira);
    this.loadingFundos = false;
  }

  formatLabelX = (label) => label;

  lancamentoAjusteSaldo = (investimento: Carteira, dt: Date) => {
    const data = {
      investimento,
      data: dt,
      saldo: investimento.saldo,
    };

    const dialog = this.dialog.open(DialogAjusteSaldoComponent, {
      data,
      width: "500px",
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingFundos = true;
        this.carteiraService
          .getCarteiraCliente(this.idCliente, null, this.date.value.toDate())
          .subscribe(this.handleCarteira);
        this.loadingFundos = false;
      }
    });
  };

  print() {
    this.printing = true;

    this.authService.willPrint$.next(true);
  }
}
