import { Component, OnInit } from '@angular/core';
import { Meta } from 'src/app/_models/benchmark.models';
import { MetaService } from 'src/app/_services/meta.service';

@Component({
  selector: 'app-tela-graficos-meta',
  templateUrl: './tela-graficos-meta.component.html',
  styleUrls: ['./tela-graficos-meta.component.scss']
})
export class TelaGraficosMetaComponent implements OnInit {

  metas: Meta[]
  constructor(private metaService: MetaService) { }

  ngOnInit() {
    this.metaService.getMetasGraficos().subscribe(metas => this.metas = metas);
  }
  

}
