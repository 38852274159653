<h1 class="title">Otimizador</h1>
<div class="wrapper">
  <div class="container investimentos">
    
    <form [formGroup]="form" fxLayout="column" style="margin: 0 0 1em 0; border: 1px solid #ccc; border-radius: 10px;padding: 1em; background: #FFF;">
      <div fxLayout="column">
        <mat-form-field>
          <mat-select placeholder="Conjunto" (selectionChange)="changeConjunto($event.value)">
            <mat-option value>Todos</mat-option>
            <mat-option *ngFor="let conjunto of (conjuntos$ | async)" [value]="conjunto.idConjunto">
              {{conjunto.nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <input matInput type="number" max="100" min="0" formControlName="limiteInferior" placeholder="Limite inferior" required>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <input matInput type="number" max="100" min="0" formControlName="limiteSuperior" placeholder="Limite superior" required>
        </mat-form-field>
      </div>
      <button fxFlex="10" style="white-space: pre-wrap !important;" [disabled]="transferindo" mat-raised-button color="primary" (click)="adicionarTodos(investimentosList?.data)">
        <i class="fas fa-plus"></i>Adicionar os {{investimentosList?.data?.length}} fundos listados
      </button>
    </form>
    <mat-form-field style="width: 100%;">
      <input matInput placeholder="Buscar fundos por nome ou CNPJ" (keyup)="handleFiltroChange($event.target.value)">
    </mat-form-field>
    <div
    style="height: 100%;"
    cdkDropList
    #investimentosList="cdkDropList"
    [cdkDropListData]="investimentos | async"
    [cdkDropListConnectedTo]="[carteiraList]"
    class="list"
    (cdkDropListDropped)="dropInvestimentos($event)">
    <div class="box" *ngFor="let item of investimentosList.data" cdkDrag>
      <div class="custom-placeholder" *cdkDragPlaceholder></div>
      <strong>{{item.cnpj | mask: '00.000.000/0000-00'}}</strong>
      <br>
      {{item.nome}}
    </div>
  </div>
</div>

<div class="container carteira">
  <form [formGroup]="form" fxLayout="column" style="margin: 0 0 1em 0; border: 1px solid #ccc; border-radius: 10px;padding: 1em; background: #FFF;">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px">
      <mat-form-field fxFlex="50">
        <input matInput type="text" mask="00/0000" [dropSpecialCharacters]="false"  formControlName="database" placeholder="Mês base" required>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input matInput type="number" min="10" formControlName="numMeses" placeholder="Meses à observar" required>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px">
      <mat-form-field fxFlex="34">
        <input matInput formControlName="passo" type="number" step="0.01" style="text-align: right" placeholder="Passo" required><strong matSuffix>%</strong>
      </mat-form-field>
      <mat-form-field fxFlex="33">
        <input matInput formControlName="inicial" type="number" step="0.01" style="text-align: right" placeholder="Rentabilidade inicial" required><strong matSuffix>%</strong>
      </mat-form-field>
      <mat-form-field fxFlex="33">
        <input matInput formControlName="final" type="number" step="0.1" style="text-align: right" placeholder="Rentabilidade final" required><strong matSuffix>%</strong>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p *ngIf="this.carteira.length && !(podeOtimizar | async)" style="color: red" >A soma dos limites inferiores não pode ser ultrapassar 100%</p>
      <button fxFlex="10" type="button" mat-raised-button color="accent" (click)="otimizar('ranking')" [disabled]="!this.carteira.length"><i class="fas fa-list-ol"></i>Ranking estático</button>
      <button fxFlex="10" type="button" mat-raised-button color="primary" (click)="otimizar('resultado')" [disabled]="!this.carteira.length || !(podeOtimizar | async)"><i class="fas fa-chart-line"></i>Otimizador</button>
    </div>
  </form>
  
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" style="margin-bottom: 1em;">
    <h3 fxFlex="auto">Fundos selecionados: {{carteira.length}}</h3>
    <button fxFlexAlign="center" fxFlexAlign.lt-md="stretch" fxFlex="30" fxFlex.gt-lg="20" (click)="limparInvestimentos()" mat-stroked-button color="warn"><i class="fas fa-times"></i>Limpar</button>
  </div>
  
  <div
  style="height: 100%"  
  cdkDropList
  #carteiraList="cdkDropList"
  [cdkDropListData]="carteira"
  [cdkDropListConnectedTo]="[investimentosList]"
  class="list"
  (cdkDropListDropped)="dropCarteira($event)">
  <div class="box" *ngFor="let item of carteira" cdkDrag>
    <div class="custom-placeholder" *cdkDragPlaceholder></div>
    <div style="position: relative">
      {{item.fundo.nome}} - <strong>{{item.fundo.cnpj | mask: '00.000.000/0000-00'}}</strong>
      <br>
      <strong>Inferior: {{item.limiteInferior}}% / Superior: {{item.limiteSuperior}}%</strong>
    </div>
  </div>
</div>
</div>
<ng-template #template let-teste="teste" >
  <div style="bottom: 0; left: 0; margin-top: 5px; background: #FFF; border: 2px solid #01579b; border-radius: 5px; padding: 3px;">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
      <div fxFlex="50"><strong>Última rentabilidade:</strong> 0,1%</div>
    </div>
  </div>
</ng-template>

</div>
