<div id="dialog-delete">
  <div class="title-dialog">
      <mat-icon color="primary">check</mat-icon>
      <h2 mat-dialog-title>Confirmar</h2>
  </div>
  <mat-dialog-content>{{data.text}}</mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="true"><i class="fas fa-check"></i>Sim</button>
    <button mat-flat-button color="warn" mat-dialog-close><i class="fas fa-times"></i>Não</button>
  </mat-dialog-actions>
</div>