import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import * as d3 from 'd3';
import { scaleLinear } from 'd3-scale';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { delay, switchMap, withLatestFrom } from 'rxjs/operators';
import { Carteira } from 'src/app/_models/carteira.model';
import { Cliente } from 'src/app/_models/cliente.model';
import { Ranking } from 'src/app/_models/otimizador.models';
import { AuthService } from 'src/app/_services/auth.service';
import { CarteiraService } from 'src/app/_services/carteira.service';
import { ClienteService } from 'src/app/_services/cliente.service';
import { IndiceService } from 'src/app/_services/indices.service';
import { OtimizadorService } from 'src/app/_services/otimizador.service';
import { MY_FORMATS } from '../../clientes/carteira/relatorio-retornos/relatorio-retornos.component';

@Component({
  selector: 'app-indices-fundos-carteira',
  templateUrl: './indices-fundos-carteira.component.html',
  styleUrls: ['./indices-fundos-carteira.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class IndicesFundosCarteiraComponent implements OnInit, OnDestroy {

  _investimentos = new Subject<Carteira[]>();
  cnpjFundos$ = new Subject<string[]>();
  data = new Subject<moment.Moment>();

  colorSchema: any = { domain: [] };

  atual = moment()
  date = new FormControl(moment().year(2024));
  minDate: Date;
  maxDate: Date;

  columns = ['nome', 'benchmark', 'sharpe', 'var', 'vol12', 'desvpad'];

  indices: any[] = [];
  provisao: any[] = [];

  chartDataDesvPad: any[] = [];
  chartData: any[] = [];

  saldoTotal: number;
  provisaoPerda: number;
  percentualPerda: number;

  sharpeGeral: number = 0;
  varGeral: number = 0;

  idCliente: number;
  fromRoute: boolean = false;
  cliente: Cliente;

  loading: boolean = false;

  printObs: Subscription;

  constructor(
    private otimizadorService: OtimizadorService,
    private indiceService: IndiceService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private carteiraService: CarteiraService,
    private clienteService: ClienteService) { }

  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2024, 11, 31, 23, 59, 59);
    this.authService.usuario$.subscribe((usuario) => {
      if (!this.route.snapshot.paramMap.get("idCliente")) {
        this.idCliente = usuario.idCliente;
      } else {
        this.fromRoute = true;
        this.idCliente = +this.route.snapshot.paramMap.get("idCliente");
      }


      this.clienteService.buscarClientePorId(this.idCliente).subscribe(cliente => {
        this.cliente = cliente;
      });
    });


    this.authService.willPrint$.next(false);
    this.printObs = this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
      if (willPrint) {
        window.print();
      }
    });
    window.onafterprint = (e) => this.authService.willPrint$.next(false);

    this.data.subscribe(data => {
      this.carteiraService
        .getCarteiraCliente(this.idCliente, '', data.toDate())
        .subscribe(carteira => this._investimentos.next(carteira));
    })


    this._investimentos.subscribe(carteira => {
      const cnpjs = carteira.map(c => c.cnpjFundo);
      this.cnpjFundos$.next(cnpjs)
      this.loading = true
    })

    this.cnpjFundos$.pipe(
      withLatestFrom(this.data),
      switchMap(([cnpjs, data]) => {
        const req = { database: data.format('MM/YYYY'), obs: 12, fundos: cnpjs.map(cnpj => ({ cnpj, min: 0, max: 22 })) }
        return this.otimizadorService.ranking(req.database, req.obs, req.fundos)
      }),
      withLatestFrom(this._investimentos)).subscribe(([ret, carteira]) => {
        this.indices = ret.map((rank: Ranking) => {
          return {
            ...rank,
            vol12: rank.desvpad * Math.sqrt(rank.diasUteis),
            vol6: rank.desvpad6m * Math.sqrt(rank.diasUteis6m),
            vol3: rank.desvpad3m * Math.sqrt(rank.diasUteis3m)
          }
        });

        this.sharpeGeral = this.indices.reduce((acc, item) => acc + item.sharpe, 0) / this.indices.length
        this.varGeral = this.indices.reduce((acc, item) => acc + item.var, 0) / this.indices.length


        this.provisao = carteira.map(cart => {
          const indice = this.indices.find(ind => ind.cnpj == cart.cnpjFundo)
          if (!indice) {
            return null
          }
          return { ...indice, saldo: cart.saldo, saldoDU: cart.saldo * indice.var, legislacao: cart.enquadramentoLegislacao }
        }).filter(c => c);

        this.saldoTotal = carteira.reduce((acc, c) => acc + c.saldo, 0);
        this.provisaoPerda = this.provisao.reduce((acc, c) => acc + c.saldoDU, 0);
        this.percentualPerda = this.provisaoPerda / this.saldoTotal;

        const colorScale = scaleLinear<string, string>()
          .domain([1, Math.ceil(carteira.length / 2), carteira.length])
          .range(['#80deea', '#e6ee9c', '#ffab91']);

        const series = this.provisao.reduce((acc, item, i) => {
          return [...acc, {
            name: item.nome, series: [{
              name: '',
              x: item.retorno * 100,
              y: item.sharpe * 100,
              r: Math.ceil(item.saldo / this.saldoTotal) * 300
            }]
          }]
        }, [])

        const seriesDesvpad = this.provisao.reduce((acc, item, i) => {
          return [...acc, {
            name: item.nome, series: [{
              name: '',
              x: item.retorno * 100,
              y: item.desvpad * 100,
              r: Math.ceil(item.saldo / this.saldoTotal) * 300

            }]

          }
          ]
        }, [])

        const domain = series.map((s, i) => d3.color(colorScale(i + 1)).formatHex());

        this.colorSchema = { domain }
        this.chartData = series
        this.chartDataDesvPad = seriesDesvpad

        this.loading = false
      })

  }

  ngOnDestroy(): void {
    this.printObs.unsubscribe();
    this.authService.willPrint$.next(false);
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.endOf("month"));
    this.data.next(ctrlValue.endOf("month"))
    datepicker.close();
  }


  print() {
    this.authService.willPrint$.next(true);
  }
}
