import { Pipe, PipeTransform } from '@angular/core';

const tipos = {
  'A': 'Aplicação',
  'R': 'Resgate',
  'M': 'Amortização',
  'E': 'Estorno de Resgate'
};

@Pipe({
  name: 'movimentoCarteira'
})
export class MovimentoCarteiraPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return tipos[value];
  }

}
