import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { MatTableDataSource } from '@angular/material/table';
import { Usuario } from 'src/app/_models/user.models';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  datasource: MatTableDataSource<Usuario>;
  columns = ['nome', 'email', 'tipo'];

  constructor(private usuarioService: UsuarioService) { }

  ngOnInit() {
    this.usuarioService.listaUsuarios().subscribe(
      usuarios => this.datasource = new MatTableDataSource(usuarios)
    );
  }

}
