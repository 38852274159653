import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { DialogLoadingComponent } from "src/app/_helpers/dialog-loading/dialog-loading.component";
import { UIService } from "src/app/_helpers/ui.service";
import { APR, APRSigner, FuncoesAPR } from "src/app/_models/arp.model";
import { Carteira, MovimentoCarteira } from "src/app/_models/carteira.model";
import { Cliente } from "src/app/_models/cliente.model";
import {
  FIList,
  InformeFundo,
} from "src/app/_models/fundo-investimento.models";
import { APRService } from "src/app/_services/apr.service";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { FundoInvestimentoService } from "src/app/_services/fundo-investimento.service";
import { ValidateCNPJ } from "src/app/_validators/cpf_cnpj.validator";
import { DialogSelecaoConta } from "./dialog-selecao-conta/dialog-selecao-conta.component";

@Component({
  selector: "app-CadastroAPR",
  templateUrl: "./CadastroAPR.component.html",
  styleUrls: ["./CadastroAPR.component.scss"],
})
export class CadastroAPRComponent implements OnInit, AfterViewInit {
  @Input("idCliente") idCliente: number;
  @Input("movimento") movimento: MovimentoCarteira;
  @Input("investimento") investimento: Carteira;
  @Output() close: EventEmitter<any> = new EventEmitter();
  funcoesAPR = FuncoesAPR;
  tipoUsuario = TipoUsuario;
  existeCota: Boolean = false;
  form: FormGroup;
  fundo: FormControl;
  cliente: FormControl;
  numeroCotas: number = 0;

  idApr: number;

  gestor: FormControl;
  proponente: FormControl;
  responsavel: FormControl;

  fromMovement: boolean = false;

  isCliente: boolean = false;

  fundosCarteira: Subject<Carteira[]> = new Subject();

  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private carteiraService: CarteiraService,
    private fundoInvestimentoService: FundoInvestimentoService,
    private clienteService: ClienteService,
    private aprService: APRService,
    private uiService: UIService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.idApr = +this.route.snapshot.paramMap.get("id");

    this.form = _fb.group({
      nomeFundo: [null, Validators.required],
      cnpjFundo: [null, [Validators.required, ValidateCNPJ]],
      banco: [null],
      agencia: [null],
      conta: [null],
      dataOperacao: [new Date(), [Validators.required]],
      operacao: [null, Validators.required],
      valor: [null, Validators.required],
      valorCota: [null, Validators.required],
      descricao: [null],
      motivo: [null],
      clienteId: [null, Validators.required],
      proponenteId: [null, Validators.required],
      gestorId: [null, Validators.required],
      responsavelId: [null, Validators.required],
      fromMovement: [false],
      movimentoId: [null],
    });

    this.fundo = _fb.control(null);
    this.cliente = _fb.control(null);
    this.gestor = _fb.control(null);
    this.proponente = _fb.control(null);
    this.responsavel = _fb.control(null);
  }

  ngOnInit() {
    //Apenas se usuario for do tipo cliente
    this.authService.usuario$.subscribe((usuario) => {
      if (
        [TipoUsuario.USUARIO_CLIENTE, TipoUsuario.ADMIN_CLIENTE].includes(
          usuario.tipoUser
        )
      ) {
        this.isCliente = true;
        this.form.patchValue({ clienteId: usuario.idCliente });
        this.clienteService
          .buscarClientePorId(usuario.idCliente)
          .subscribe((cliente) => {
            this.cliente.setValue(cliente);
          });
      }
    });
    if (this.idCliente) {
      this.form.patchValue({ clienteId: this.idCliente });
      this.clienteService
        .buscarClientePorId(this.idCliente)
        .subscribe((cliente) => {
          this.cliente.setValue(cliente);
        });
    }
    if (this.movimento) {
      this.form.patchValue({
        dataOperacao: this.movimento.dataMovimento,
        operacao: this.movimento.operacao,
        valor: this.movimento.valor,
        valorCota: this.movimento.valor / this.movimento.numCotas,
        fromMovement: true,
        movimentoId: this.movimento.idMovimento,
      });
      this.fromMovement = true;
    }
    if (this.investimento) {
      this.form.patchValue({
        nomeFundo: this.investimento.nomeFundo,
        cnpjFundo: this.investimento.cnpjFundo,
        conta: this.investimento.conta,
        agencia: this.investimento.agencia,
        banco: this.investimento.banco,
      });
      this.fundoInvestimentoService
        .lista("cnpj", this.investimento.cnpjFundo)
        .subscribe((fundo) => {
          this.fundo.setValue(fundo[0]);
        });
    }
  }

  ngAfterViewInit() {
    this.fundo.valueChanges.subscribe((fundo: FIList) => {
      if (fundo) {
        this.form.patchValue({
          nomeFundo: fundo.nome,
          cnpjFundo: fundo.cnpj,
        });
        this.atualizaValorCota();
        if (this.form.value.clienteId) {
          this.carteiraService
            .getCarteiraCliente(this.form.value.clienteId, fundo.cnpj)
            .subscribe((fundos) => {
              this.fundosCarteira.next(fundos);
            });
        }
      }
    });

    this.cliente.valueChanges.subscribe((cliente: Cliente) => {
      this.form.patchValue({ clienteId: cliente && cliente.idCliente });
      if (this.form.value.cnpjFundo && cliente) {
        this.carteiraService
          .getCarteiraCliente(cliente.idCliente, this.form.value.cnpjFundo)
          .subscribe((fundos) => {
            this.fundosCarteira.next(fundos);
          });
      }
    });

    this.gestor.valueChanges.subscribe((signer: APRSigner) => {
      this.form.patchValue({ gestorId: signer && signer.id });
    });

    this.proponente.valueChanges.subscribe((signer: APRSigner) => {
      this.form.patchValue({ proponenteId: signer && signer.id });
    });
    this.responsavel.valueChanges.subscribe((signer: APRSigner) => {
      this.form.patchValue({ responsavelId: signer && signer.id });
    });

    this.fundosCarteira.subscribe((fundos) => {
      if (fundos.length > 0 && !this.fromMovement) {
        let dialogSelecao = this.dialog.open(DialogSelecaoConta, {
          data: {
            fundos,
          },
          width: "500px",
        });

        dialogSelecao.afterClosed().subscribe((resultado: Carteira) => {
          if (resultado) {
            this.form.patchValue({
              agencia: resultado.agencia,
              banco: resultado.banco,
              conta: resultado.conta,
            });
          }
          this.fundosCarteira.next([]);
        });
      }
    });
  }

  salvar(form) {
    const data: APR = form;
    let salvo: Observable<any>;
    if (this.idApr) {
      salvo = this.aprService.editarApr(this.idApr, data);
    } else {
      salvo = this.aprService.criarApr(data);
    }
    const dialogRef = this.dialog.open(DialogLoadingComponent, {
      data: {
        text: "Salvando APR...",
        target: salvo,
        closeOnComplete: true,
        successMessage: "APR salva com sucesso",
        errorMessage: "Erro ao salvar, tente novamente",
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && !this.fromMovement) {
        this.router.navigateByUrl("/apr");
      } else if (response) {
        this.close.emit(response);
      }
    });
  }

  atualizarNumeroCotas = () => {
    const form = this.form.value;
    if (form.valor && form.valorCota) {
      this.numeroCotas = form.valor / form.valorCota;
    } else {
      this.numeroCotas = 0;
    }
  };

  atualizaValorCota = () => {
    const data: APR = this.form.value;
    const cnpj = data.cnpjFundo.replace(/\D/g, "");
    this.carteiraService.getValorCotaDia(cnpj, data.dataOperacao).subscribe(
      (informe: InformeFundo) => {
        this.existeCota = true;
        this.form.patchValue({ valorCota: informe.valorQuota });
        this.atualizarNumeroCotas();
      },
      (error) => {
        this.existeCota = false;
        this.form.patchValue({ valorCota: null });
      }
    );
  };
}
