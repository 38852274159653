<div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(255,255,255,0.8); z-index: 999;" *ngIf="loading">
  <mat-spinner diameter="40" style="margin: 0 auto;"></mat-spinner>
  <h4>Carregando...</h4>
</div>
<h1 class="title">Simulador de enquadramento</h1>
<div>
  <div class="sticky">
    <span style="flex:1;">
      Valor total investido: {{totalCarteira | currency: 'BRL'}} em {{investimentos.length}} fundos {{cliente?.tipoEnquadramento}}
      <br/>
      VAR: {{(+investimentos.reduce(fnSumVar, 0) / totalCarteira) | percent:'1.4-4'}}
      <br/>
      Sharpe: {{(+investimentos.reduce(fnSumSharpe, 0) / totalCarteira) | percent:'1.4-4'}}
      <br/>
      <!-- Rentabilidade média: {{(+investimentos.reduce(fnRentabilidadeMedia, 0) / totalCarteira) | percent:'1.4-4'}}
      <br/> -->
      <div style="cursor: pointer; color: #01579b; display: inline-block; font-weight: 300;" (click)="handleCarteira([])">
        [ <i class="fas fa-eraser"></i> Limpar carteira]
      </div>
    </span>
    <span style="font-weight: bold;" [class.enquadrado]="enquadrado" [class.desenquadrado]="!enquadrado">
      Situação: {{(enquadrado) ? 'Enquadrado' : 'Desenquadrado'}}
    </span>
  </div>

  <div *ngFor="let item of investimentos; let i = index" class="box">
    <div style="display: flex;">
      <div style="flex: 1;">
        <div style="display: flex; align-items: center;">
          <span style="flex: 1; font-weight: 500;">
            {{item.cnpjFundo}} - {{item.nomeFundo}}
            <div style="cursor: pointer; color: #01579b; display: inline-block;" (click)="removerFundo(i)">
              [Remover esse fundo]
            </div>
          </span>
          <div style="display: flex; flex-direction: column;">
            <strong>Saldo: {{item.saldo | currency:'BRL'}}</strong>
            <strong>Participação: {{item.participacao | percent: '1.4-4'}}</strong>
            <strong>Limite Resolução: {{item.resolucao.limite /100 | percent: '1.2'}}</strong>
            <strong>Limite política: {{item.resolucao.limite / 100 | percent: '1.2'}}</strong>
            <strong>Limite PL: {{item.resolucao.limiteParticipacao / 100 | percent: '1.2'}}</strong>
          </div>
        </div>
        <mat-divider style="margin: 10px 0;"></mat-divider>
        <span>
          <strong>Artigo: </strong> {{item.resolucao?.legislacao}}
        </span><br/>
        <span>
          <strong>Situação: </strong>
          <i *ngIf="item.enquadramento?.enquadrado" class="far fa-check-circle enquadrado"></i>
          <i *ngIf="!item.enquadramento?.enquadrado" class="far fa-times-circle desenquadrado"></i>
          <span [class.enquadrado]="item.enquadramento?.enquadrado" [class.desenquadrado]="!item.enquadramento?.enquadrado">{{item.enquadramento?.enquadrado ? "Enquadrado" : "Desenquadrado"}}</span>
          <span *ngIf="item.enquadramento && !item.enquadramento.enquadrado" style="margin-left: 10px;">Motivo: {{item.enquadramento?.motivo}}</span>
        </span><br/>
        <mat-expansion-panel *ngIf="item.indices">
          <mat-expansion-panel-header>
            <mat-panel-title><strong>Índices</strong></mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <strong>Sharpe: </strong>{{item.indices.sharpe | percent:'1.4-4'}}<br/>
            <strong>VAR: </strong>{{item.indices.var | percent:'1.4-4'}}<br/>
            <!-- <strong>Rentabilidade média: </strong> {{item.indices.media | percent:'1.4-4'}}<br/> -->
            <strong>Desvio padrão: </strong> {{item.indices.desvioPadrao | percent:'1.4-4'}}<br/>
            <br/>
            <strong>Rentabilidade ultimos 12 meses</strong>
            <mat-divider></mat-divider>
            <div style="width: 100% !important; height: 400px; flex: 1;">
              <ngx-charts-line-chart 
                *ngIf="item.indicesChart"
                [results]="item.indicesChart"
                [gradient]="false"
                [curve]="lineCurve"
                [autoScale]="true"
                [xAxis]="true"
                [yAxis]="true"
                [showXAxisLabel]="true"
                [showYAxisLabel]="true"
                [yAxisTickFormatting]="formatarY"
                xAxisLabel="Mes"
                yAxisLabel="Rentabilidade"
                [showRefLines]="true">
              </ngx-charts-line-chart>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </div>
      <div style="display: flex; flex-direction: column; margin-left: 15px; padding-left: 15px; border-left: 1px solid #F0F0F0;">
        <button mat-stroked-button color="primary" style="margin-bottom: 2px;" (click)="abrirDialogMovimento(i, item, 1)">
          <i class="fas fa-chevron-up"></i>
          Aplicar
        </button>
        <button mat-stroked-button color="warn"  (click)="abrirDialogMovimento(i, item, -1)">
          <i class="fas fa-chevron-down"></i>
          Resgatar
        </button>
        <div style="flex: 1"></div>
        <button *ngIf="item.movimentosSimulador ? true : false" mat-stroked-button  (click)="abrirDialogHistoricoMovimentos(item)">
          <i class="fas fa-history"></i>
          Movimentos
        </button>
      </div>
    </div>
  </div>
</div>
<button mat-raised-button color="primary" (click)="abrirDialogAdicionarFundo()" style="width: 100%;">
  <i class="far fa-plus-square"></i>
  Adicionar fundo
</button>