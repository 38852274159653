import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './../_services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const usuario = this.authService.getUsuarioLogado();
    if (this.authService.isAuthenticated()) {
      if (!route.data.roles || (route.data.roles && route.data.roles.indexOf(usuario.tipoUser) > -1)) {
        return true;
      } else {
        this.authService.logout('Usuário não possui permissão para acessar esta área');
        return false;
      }
    } else {
      this.authService.logout('Sessão expirada');
      return false;
    }
  }
}
