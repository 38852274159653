import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Indice } from '../_models/indices.models';
import { Usuario } from '../_models/user.models';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private indiceUrl = `${environment.PATH_API}/user`;

  constructor(private http: HttpClient) { }

  listaUsuarios () {
    return this.http.get<Usuario[]>(this.indiceUrl);
  }

  getUsuario(idUsuario: number) {
    const url = `${this.indiceUrl}/${idUsuario}`;
    return this.http.get<Usuario>(url);
  }

  getUsuarioPorCliente(idCliente: number) {
    const url = `${this.indiceUrl}/cliente/${idCliente}`;
    return this.http.get<Usuario[]>(url);
  }


  buscarIndices(fundo: string, periodicidade: string, database: string, datafinal?: string) {
    let params = new HttpParams();
    params = params.append('periodicidade', periodicidade);
    params = params.append('database', database);
    if (datafinal) {
        params = params.append('datafinal', datafinal);
    }
    const url = `${this.indiceUrl}/${fundo}`;
    return this.http.get<Indice>(url, {params: params});
  }

  usuarioExiste = (email: string) => {
    const url = `${this.indiceUrl}/verificar-email`;
    return this.http.post(url, {email: email});
  }


  resetSenha = (idUser:number)=>{
    const url = `${this.indiceUrl}/reset_pwd/${idUser}`;
    return this.http.post(url,{});
  }

}
