import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize, map } from 'rxjs/operators';
import { PageRequest, Page } from '../_models/PaginatedResult';
import { BenchmarkList, Benchmark } from '../_models/benchmark.models';

export interface BenchmarkQuery{
  search: string
}


@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {

  private benchmarkUrl = `${environment.PATH_API}/bench`;

  constructor(private http: HttpClient) { }

  listaBenchmark(filter?: any, page?, limit?) {
    const url = `${this.benchmarkUrl}`;
    let params = new HttpParams();
    if (filter) {
      params = params.append('filtro', filter);
    }
    params = params.append('page', '0');
    params = params.append('limit', '50');
    return this.http.get<Page<BenchmarkList>>(url, {params: params}).pipe(
      map(c => c.data)
    );
  }

  listaBenchmarkPaginado(request: PageRequest<BenchmarkList>, query: BenchmarkQuery):Observable<Page<BenchmarkList>> {
    const url = `${this.benchmarkUrl}`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append('filtro', query.search);
    }
    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<BenchmarkList>>(url, {params: params});
  }

  getBenchmark(id: number) {
    const url = `${this.benchmarkUrl}/${id}`;
    return this.http.get<Benchmark>(url);
  }

  criarBenchmark(benchmark: Benchmark) {
    const url = `${this.benchmarkUrl}/`;
    return this.http.post(url, benchmark);
  }

  atualizarBenchmark(benchmark: Benchmark) {
    const url = `${this.benchmarkUrl}/${benchmark.idBench}`;
    return this.http.put(url, benchmark);
  }

  removerBenchmark(id: Number) {
    const url = `${this.benchmarkUrl}/${id}`;
    return this.http.delete(url);
  }

}


export class BenchmarkListDataSource implements DataSource<BenchmarkList> {

  private benchmarkSubject = new BehaviorSubject<BenchmarkList[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private benchmarkService: BenchmarkService) {}

  connect(collectionViewer: CollectionViewer): Observable<BenchmarkList[]> {
      return this.benchmarkSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.benchmarkSubject.complete();
      this.loadingSubject.complete();
  }

  loadBenchmarks(filter = '', sortDirection = 'asc', pageIndex = 0, pageSize = 5) {
      this.loadingSubject.next(true);

      this.benchmarkService.listaBenchmark(filter, pageIndex, pageSize).pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(benchmarks => this.benchmarkSubject.next(benchmarks));
  }
}
