<h1 class="title">Cadastro de clientes</h1>
<mat-spinner *ngIf="idCliente && loading" [diameter]="50"></mat-spinner>
<form  [formGroup]="form" (ngSubmit)="salvar()" *ngIf="!loading">
  <h3><i class="fas fa-user-tie"></i>Dados cadastrais</h3>
  <mat-form-field>
    <input #nome matInput formControlName="nome" placeholder="Nome do cliente" required aria-label="Nome">
    <mat-error *ngIf="!nome.valid">Informe o nome do cliente</mat-error>
  </mat-form-field>
  <div class="col-2">
    <mat-form-field>
      <input #cnpj matInput formControlName="cnpj" placeholder="CPF/CNPJ" required aria-label="CPF/CNPJ">
      <mat-error *ngIf="!cnpj.valid">Informe o CPF/CNPJ</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input #email matInput formControlName="emailAdmin" type="email" placeholder="Email do administrador" required aria-label="email">
      <mat-error *ngIf="form.controls['emailAdmin'].invalid">Email já cadastrado</mat-error>
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field>
      <input #telefone matInput formControlName="telefone1" [mask]="masks['telefone1']" (keyup)="mascaraTelefone('telefone1')" placeholder="Telefone 1" required aria-label="Telefone 1">
      <mat-error *ngIf="!telefone.valid">Informe o telefone 1</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input #telefone2 matInput formControlName="telefone2" [mask]="masks['telefone2']" (keyup)="mascaraTelefone('telefone2')" placeholder="Telefone 2" aria-label="Telefone 2">
      <mat-error *ngIf="!telefone2.valid">Informe o telefone 2</mat-error>
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field>
        <mat-select #meta placeholder="Meta" formControlName="meta">
            <mat-option>Selecione</mat-option>
            <mat-option *ngFor="let meta of metas" [value]="meta.idMeta"> {{meta.nome}} </mat-option>
          </mat-select>
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field>
        <mat-select placeholder="Tipo Enquadramento" formControlName="tipoEnquadramento">
            <mat-option>Selecione</mat-option>
            <mat-option value="RPPS">RPPS</mat-option>
            <mat-option value="FP">FP</mat-option>
            <mat-option value="PRO-GESTAO">PRÓ-GESTÃO</mat-option>
            <mat-option value="Outros">Outros</mat-option>
          </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="limitePolitica" [dropSpecialCharacters]="false" placeholder="Limite Politica" aria-label="Limite Superior" [patterns]="customPatterns" mask="0*.0000" sufix="%">
    </mat-form-field>
  </div>
  <app-acoes-cliente [acoes]="form.controls['acoes']"></app-acoes-cliente>
  <app-upload-arquivo color="accent" label="Logo do cliente: " type="image/*" [targetControl]="form.get('foto')" bg="#01579b"></app-upload-arquivo>
  <div>
    <h3><i class="fas fa-map-marker-alt"></i>Endereço</h3>

    <mat-form-field style="width: 30%; display: inline-block; margin-right: 1em;">
      <input #cep matInput formControlName="cep" mask="00000-000" placeholder="CEP" aria-label="CEP" (keyup.enter)="consultarEnderecoViaCep(cep.value)">
    </mat-form-field>
    <button mat-raised-button color="accent" (click)="consultarEnderecoViaCep(cep.value)">
      <mat-icon>search</mat-icon> Buscar dados do CEP
    </button>

    <div class="col-numero-complemento">
      <mat-form-field>
        <input #logradouro matInput formControlName="logradouro" placeholder="Logradouro" aria-label="Logradouro">
      </mat-form-field>
      <mat-form-field>
        <input #numero matInput formControlName="numero" placeholder="Número" aria-label="Número">
      </mat-form-field>
      <mat-form-field>
        <input #complemento matInput formControlName="complemento" placeholder="Complemento" aria-label="Complemento">
      </mat-form-field>
    </div>
    <div class="col-numero-complemento">
      <mat-form-field>
        <input #bairro matInput formControlName="bairro" placeholder="Bairro" aria-label="Bairro">
      </mat-form-field>
      <mat-form-field>
        <input #cidade matInput formControlName="municipio" placeholder="Município" aria-label="Cidade">
      </mat-form-field>
      <mat-form-field>
        <input #ibge matInput formControlName="ibge" placeholder="IBGE" aria-label="IBGE">
      </mat-form-field>
      <br/>
      <mat-form-field>
        <input #uf matInput formControlName="uf" placeholder="UF" aria-label="UF">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" style="margin-bottom: 2em;">
    <div fxFlex="70">
      <h4><i class="fas fa-users"></i>Conjuntos</h4>
      <div fxLayout="column">
        <mat-form-field style="width: 100%">
          <input matInput type="text" #conjunto placeholder="Conjunto" [value]="(filtroConjunto | async)"
          (keypress)="handleFiltroConjunto($event.target.value)" (keydown.enter)="adicionarConjunto()"
          [matAutocomplete]="autocliente">
        </mat-form-field>
        <mat-autocomplete #autocliente="matAutocomplete" [displayWith]="displayConjunto"
        (optionSelected)="selectConjunto($event.option.value)">
        <mat-option *ngFor="let option of (conjuntos$ | async)" [value]="option">{{option.nome}}</mat-option>
      </mat-autocomplete>
      <button type="button" fxFlex="10" matTooltip="Adicionar conjunto ao cliente" mat-flat-button
      [disabled]="!conjuntoSelecionado" (click)="adicionarConjunto(); conjunto.value = ''" color="primary">
      <i class="fas fa-plus-circle"></i>Adicionar
    </button>
  </div>
  <mat-list>
    <mat-list-item *ngFor="let conjunto of conjuntos; let i = index" class="mat-elevation-z1">
      <p matLine>{{conjunto.nome}}</p>
      <button type="button" mat-icon-button color="primary" (click)="removerConjunto(i)"
      matTooltip="Remover conjunto do cliente"><i class="fas fa-times"></i></button>
    </mat-list-item>
  </mat-list>
</div>
</div>
<app-assinadores *ngIf="idCliente" (onAddAssinador)="addAssinador($event)" ></app-assinadores>
<div class="buttons">
  <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid"><mat-icon>save</mat-icon>Salvar cliente</button>
  <button mat-raised-button color="warn" type="button" routerLink="/clientes"><mat-icon>close</mat-icon> Cancelar</button>
</div>
</form>
