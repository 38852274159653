import { Component, OnInit } from '@angular/core';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { MatDatepicker } from '@angular/material/datepicker';
import { Log } from 'src/app/_models/fundo-investimento.models';
import moment from 'moment';
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})

export class LogsComponent implements OnInit {
  atual = moment();

  logs: Log[] = [];
  formReimportarCotas: FormGroup;
  
  constructor(
    private fiService: FundoInvestimentoService,
    private fb: FormBuilder,
  ) {
    this.formReimportarCotas = fb.group({
      dataReimportar: [Date()],
    });
  }

  ngOnInit() {
    this.fiService.getLogsCvm().subscribe(logs => this.logs = logs);
  }

  reimportarCota = () => {
    const date = new DatePipe("pt-BR").transform(this.formReimportarCotas.value.dataReimportar, "yyyy-MM-dd");

    // const obj: InformeReimportarCota = {
    //   data: date
    // };

    // this.carteiraService.postReimportarCota(obj)
    //   .subscribe(
    //     (corrigido) => {
    //       this.showMessageNoQuote = true;
    //       this.uiService.showSnackBar("Cotas Reimportadas com Sucesso!", "", 3000);
    //     }, (error) => {
    //       this.showMessageNoQuote = true;
    //       console.log("error", error);
    //       this.uiService.showSnackBar("Erro ao Reimportar Cotas", "", 3000);
    //     }
    //   );
  };

}
