<h4>Movimentar fundo da simulação</h4>

<form id="form" [formGroup]="form" (ngSubmit)="submit()">
  <div
    fxLayout="column"
    fxLayout.lt-md="column"
    fxLayoutGap="5px"
    fxLayoutGap.lt-md="0px"
    style="margin-bottom: 1em;"
  >
    <div fxFlex="50">
      <strong>Fundo:</strong>
        {{data.fundo.cnpjFundo}} - {{data.fundo.nomeFundo}}
      <br/>
      <strong>Saldo atual:</strong> {{data.fundo.saldo | currency:'BRL'}}
      <br/>
      <strong>
        Operação:
      </strong>
        {{data.operacao == 1 ? 'Aplicação' : 'Resgate'}}
    </div>
    <mat-form-field fxFlex="40">
      <input
      #valor
      matInput
      currencyMask
      formControlName="valor"
      [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
      placeholder="Valor do movimento"
      required
      aria-label="Valor do movimento"
      />
      <mat-error *ngIf="!form.controls['valor'].valid"
      >Informe o valor do investimento</mat-error
      >
    </mat-form-field>
  </div>
  <div style="display: flex;">
    <button mat-flat-button color="primary" style="flex: 1" [disabled]="form.invalid">
      <i class="far fa-check-square"></i>
      Confirmar movimento
    </button>
    <button mat-flat-button color="warn" style="width: 35%; margin-left: 5px;" [mat-dialog-close]="false">
      <i class="far fa-window-close"></i>
      Cancelar
    </button>
  </div>
</form>

