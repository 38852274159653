import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/_helpers/dialog-confirm/dialog-confirm.component';
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-upload-arquivo',
  templateUrl: './upload-arquivo.component.html',
  styleUrls: ['./upload-arquivo.component.scss']
})
export class UploadArquivoComponent implements OnInit {

  @Input() label: string;
  @Input() color: string;
  @Input() targetControl: FormControl;
  @Input() type: string;
  @Input() bg: string;
  nomeArquivo: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.color = this.color || `accent`;
  }


  readFile(event) {
    const files = event.target.files;
    if (files.length) {
      if (files[0].size >= 3145728) {

        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          width: '350px',
          data: { text: 'Tamanho da imagem inválido. Máximo de 3MB.' }
        });

      } else {
        this.nomeArquivo = files[0].name;
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        of(files[0]).subscribe(v => reader.readAsBinaryString(files[0]));
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    const base64data = btoa(binaryString);
    if (base64data) {
      this.targetControl.setValue(base64data);
    }
  }
}
