import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogLoadingComponent } from "src/app/_helpers/dialog-loading/dialog-loading.component";
import { UIService } from "src/app/_helpers/ui.service";
import { Cliente } from "src/app/_models/cliente.model";
import { ComentarioService } from "src/app/_services/comentario.service";
import { DocumentoService } from "src/app/_services/documento.service";

@Component({
  selector: "app-criar-documento-novo",
  templateUrl: "./criar-comentario-novo.component.html",
  styleUrls: ["./criar-comentario-novo.component.scss"],
})
export class CriarComentarioNovoComponent implements OnInit {
  file: any;
  form: FormGroup;
  date: Date = new Date();
  // cliente = new FormControl("");

  constructor(
    public dialogRef: MatDialogRef<CriarComentarioNovoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private documentoService: DocumentoService,
    private comentarioService: ComentarioService,
    private uiService: UIService,
    private _fb: FormBuilder
  ) {
    // this.cliente = _fb.control("");
    this.form = _fb.group({
      titulo: _fb.control("", Validators.required),
      tipo: _fb.control("Comentário", Validators.required),
      data: _fb.control(this.date, Validators.required),
      clienteId: _fb.control("", Validators.required),
    });
  }

  ngOnInit() {
    // this.cliente.valueChanges.subscribe((cliente: Cliente) => {
    //   this.form.patchValue({ clienteId: cliente.idCliente });
    // });
  }

  changeArquivo(e) {
    this.file = e.target.files[0];
  }

  onChangeData(e) {
    this.date = e.target.value;
    this.form.patchValue({ data: this.date });
    console.log("data selecionada", this.form.value.data);
  }

  submitArquivo() {
    if (this.file) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        const value = {
          ...this.form.value,
          arquivo: e.target.result,
        };
        const loadingDialog = this.dialog.open(DialogLoadingComponent, {
          data: {
            text: "Realizando upload do arquivo",
            target: this.comentarioService.criarDocumentoNovo(value),
            closeOnComplete: true,
            successMessage: "Documento enviado com sucesso",
            errorMessage: ({ error }) => `Erro: ${error.error}`,
          },
        });
        loadingDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.dialogRef.close();
          }
        });
      };
      fileReader.readAsDataURL(this.file);
    }
  }
}
