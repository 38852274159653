<form
      *appHasRole="[1, 2, 3, 4]"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="5px">
        <mat-form-field fxFlex="40">
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="dataMovimento"
            readonly
            placeholder="Data do ajuste"
            (dateChange)="onDateSelect($event)"
          />

          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker  #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="40">
          <input
            #valor
            matInput
            currencyMask
            formControlName="saldo"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            placeholder="Saldo na data"
            required
            aria-label="Saldo na data"
          />
        </mat-form-field>

        <mat-form-field fxFlex="40">
          <mat-label>Sinal do ajuste</mat-label>
          <mat-select formControlName="operacao" required>
            <mat-option value="A">Positivo</mat-option>
            <mat-option value="R">Negativo</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="40">
          <input
            #valor
            matInput
            currencyMask
            formControlName="valor"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 5 }"
            placeholder="Diferença"
            required
            aria-label="Diferença"
          />
          <mat-error *ngIf="!valor.valid"
            >Informe o valor corrigido</mat-error
          >
        </mat-form-field>
      </div>
  
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px">          
        <button
          type="submit"
          matTooltip="Confirmar lançamento"
          mat-flat-button
          [disabled]="form.invalid"
          color="primary"
        >
          <i class="fas fa-check"></i>Confirmar lançamento
        </button>
      </div>
      
    </form>