  <h1 class="title">Atribuição de desempenho: {{ complementoTitulo }}</h1>
<form>
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="dp"
      [max]="atual"
      placeholder="Mês/Ano"
      [formControl]="date"
      [min]="minDate"
      [max]="maxDate"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)"
      panelClass="example-month-picker"

    >
    </mat-datepicker>
  </mat-form-field>
  <label class="hidePrint" style="margin-left: 0.5em;">Zoom:</label>
  <mat-slider
    class="hidePrint"
    min="0.5"
    max="1"
    step="0.01"
    value="1"
    [formControl]="zoom"
    color="primary"
  ></mat-slider>
  <button
    class="hidePrint"
    (click)="print()"
    mat-flat-button
    color="primary"
    style="float: right;"
  >
    <i class="fas fa-print"></i>Imprimir
  </button>
</form>

<table [style.zoom]="zoom.value" mat-table [dataSource]="registros" border="1">
  <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 'header-row-second-group']"></tr>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <tr class="footer" mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>

  <ng-container matColumnDef="header-row-first-group">
    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style="text-align: center;">ATRIBUIÇÃO DE DESEMPENHO POR FUNDO - EFETIVO</th>
  </ng-container>

  <ng-container matColumnDef="header-row-second-group">
    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style="text-align: center;">ATRIBUIÇÃO DE DESEMPENHO DA CARTEIRA</th>
  </ng-container>


  <ng-container matColumnDef="fundo">
    <th mat-header-cell *matHeaderCellDef style="width: 25%;">Fundo</th>
    <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
      <span class="mobile-label">Fundo:</span>
      <span>{{ retorno.cnpj }} - </span>{{ retorno.nomeFundo | resumoNomeFundo }}
    </td>
    <td mat-footer-cell *matFooterCellDef>Totais</td>
  </ng-container>
  <ng-container matColumnDef="benchmark">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Benchmark</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Benchmark: </span>
      {{ retorno.benchmark }}
    </td>
    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
  </ng-container>
  <ng-container matColumnDef="saldoAnterior">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Saldo anterior</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Saldo anterior:</span>
      {{ retorno.saldoAnterior | currency: "BRL":"symbol":"1.2" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalSaldoAnterior | currency: "BRL":"symbol":"1.2" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="aplicacoes">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Aplicações</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Aplicações:</span>
      {{ retorno.aplicacoes | currency: "BRL":"symbol":"1.2" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalAplicacoes | currency: "BRL":"symbol":"1.2" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="resgates">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Resgates</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Resgates:</span>
      {{ retorno.resgates | currency: "BRL":"symbol":"1.2" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalResgates | currency: "BRL":"symbol":"1.2" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="desempenho">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Desempenho %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Desempenho %:</span>
      {{ (totalEfetivo * ((retorno.retorno/totalRetorno))) | percent: "1.4-4" }}
    </td>
    <td mat-footer-cell *matFooterCellDef >
      {{ totalDesempenho| percent: "1.4-4" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="saldoAtual">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Saldo Atual</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Saldo atual:</span>
      {{ retorno.saldoAtual | currency: "BRL":"symbol":"1.2" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalSaldoAtual | currency: "BRL":"symbol":"1.2" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="retorno">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Retorno R$</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Retorno R$:</span>
      {{ retorno.retorno | currency: "BRL":"symbol":"1.2" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalRetorno | currency: "BRL":"symbol":"1.2" }}
    </td>
  </ng-container>



  <ng-container matColumnDef="participacao">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Participação</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Participação: </span>
      <!-- {{ retorno.participacao | percent: "1.4-4" }} -->
      {{(retorno.retorno/totalRetorno)|percent:"1.4-4"}}
    </td>
    <td mat-footer-cell *matFooterCellDef>{{1 | percent:'1.4-4'}}</td>
  </ng-container>



  <ng-container matColumnDef="percentualRetorno">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Retorno %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Retorno %:</span>
      {{ retorno.retornoPercentual | percent: "1.4-4" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalRetornoPercentual | percent: "1.4-4" }}
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="efetivo">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Efetivo %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Efetivo %:</span>
      {{ retorno.efetivo | percent: "1.4-4" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalEfetivo | percent: "1.4-4" }}
    </td>
  </ng-container> -->
  <ng-container matColumnDef="efetivo">
    <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">Rendimento Mês %</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Rendimento Mês %:</span>
      {{ retorno.efetivo | percent: "1.4-4" }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ totalEfetivo | percent: "1.4-4" }}
    </td>
  </ng-container>
</table>
